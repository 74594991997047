import React from 'react';
import { URL_DB } from './../../constants';
import axios from 'axios';
import { Table, Row, Col, Container, Button, FormControl, Form, Modal, Alert } from 'react-bootstrap';
//import ExpGrillaExcelPDF from '../../components/ExpGrillaExcelPDF';
import Notifications from '../../components/Notifications';
import ordenarGrilla from './../../utils/functions/ordenar-grilla';
import '../stylePages.css';

class PanelRangosDefPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      registros: [],
      perfiles  : [],
      opciones_id : 'R',
      buscarGrillaValue: '',
      showModalEdit: false,
      showModalBorr: false,

      login_admi_id: sessionStorage.getItem('USU_ADMI_ID'),
      tpre_id :'',
      tpre_tper_id  : '', 
      tpre_ttip_id  : '', 
      tpre_ttip_deno: '', 
      tpre_raeu_id  : '',
      tpre_raeu_deno: '',

      showhelp : false,
      filterGrilla: '',
      fetchRegistros: false,
      respuestaSp: [],
      respError: '',
      mensajeAlerta: '',
    };

    this.ordenarGrilla        = this.ordenarGrilla.bind(this);
    this.handleModalEditEdit  = this.handleModalEditEdit.bind(this);
    this.handleModalEditExit  = this.handleModalEditExit.bind(this);
    this.handleEditRegis      = this.handleEditRegis.bind(this);
    this.poblarGrilla         = this.poblarGrilla.bind(this);
  }

  componentDidMount() {

    const sql = `${URL_DB}SEL_TRABAJO_PERFIL('N')`
    axios.get(sql)
      .then((response) => {
        this.setState({
          perfiles: response.data[0],
        })
      })
      .catch((error) => console.log(error))

    this.poblarGrilla()
  }

   /*==========================================================================
   Completo datos de grilla principal 
  *==========================================================================*/
   poblarGrilla = () => {
      this.setState({ fetchingregistros: true });
      const sql = `${URL_DB}SEL_PANEL_PERFIL_TTIP_RANGO('${this.state.opciones_id}')`
      axios.get(sql)
        .then((response) => {
          this.setState({
            registros: response.data[0],
          })
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.setState({ fetchRegistros: false })
        })
  }

  /*==========================================================================
   Utilidades de filtros y sort para la grilla ppal 
  *==========================================================================*/
  filtrarDatos() {
    const escapedValue = this.state.filterGrilla.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedValue, 'i');
    return this.state.registros.filter(filtro => 
      regex.test(filtro.apenom) || 
      regex.test(filtro.pers_docum) ||
      regex.test(filtro.tall_deno) || 
      regex.test(filtro.ttip_deno) || 
      regex.test(filtro.ests_deno) ||
      regex.test(filtro.empr_deno) )
  }

  ordenarGrilla = (key) => {
    const registros = ordenarGrilla(key, this.state.registros);

    this.setState({ registros });
  }
  
    /*==========================================================================
   Abro modal para modificar : Cargo valores y combos
  *==========================================================================*/
   handleModalEditEdit(regis) {

    this.setState({
      showModalEdit: true,

      tpre_id       : regis.tpre_id,
      tpre_tper_id  : regis.tpre_id===0?Number(0):regis.tper_id, 
      tpre_ttip_id  : regis.ttip_id, 
      tpre_ttip_deno: regis.ttip_deno, 
      tpre_raeu_id  : regis.raeu_id,
      tpre_raeu_deno: regis.raeu_deno,  
     
      respError: '',
      mensajeAlerta: '',
      mensajeColor: 'N'
    })
    
  }

  handleModalEditExit() {
    this.setState({ showModalEdit: false })
    this.setState({
      mensajeAlerta : 'No se aplicaron cambios',
      mensajeColor  : 'red',
    })
  }

    /*==========================================================================
   Actualizacion : Modificacion perfil
  *==========================================================================*/
   handleEditRegis(event) {
    event.preventDefault();
    
  
    if (this.state.tpre_tper_id===Number(0)) {
      this.setState({ respError:'Seleccione un perfil y reintente' });
    } else {
    const sql =  `${URL_DB}AM_PANEL_PERFIL_TTIP_RANGO(${this.state.login_admi_id},${this.state.tpre_id},
${this.state.tpre_ttip_id},${this.state.tpre_raeu_id},${this.state.tpre_tper_id})`  
    axios.get(sql)
    .then((response) => {
          this.setState({
            respuestaSp: response.data[0]
          })
          var obj = this.state.respuestaSp[0];
          this.setState({
            respError : obj.respuesta
          })

          if (this.state.respError==='OK') {

              this.setState({
                  mensajeAlerta : 'Registrado correctamente',
                  mensajeColor  : 'green',
              })
              this.setState({ showModalEdit: false })
              this.poblarGrilla()
          }    
      })
      .catch((error) => {
        alert('ERROR interno API al actualizar BD:'+error)
      })   
    }
  }
  /*==========================================================================
   RENDER
  *==========================================================================*/

  render() {  

    const respError = this.state.respError;
    const mensajeAlerta = this.state.mensajeAlerta;
    const mensajeColor = this.state.mensajeColor;
    const registrosFiltrados = this.filtrarDatos();

    return(
      <div>        
        {mensajeAlerta.length >1 ? <Notifications mensaje={mensajeAlerta}
                                                  mensajeColor={mensajeColor}
                                    /> : '' }

        <Container  fluid="true">
          <Row>
            <Col xs={7} style={{textAlign: "left" }}>
                <h2>Asignar Perfiles a Tipos_trabajo/Rango unidades</h2>
            </Col>
            <Col xs={2}>
                <Form.Group>
                  <select className="form-control" name="opciones_id" style={{fontWeight: "bold" }}
                      onChange={(e) => { this.setState({ opciones_id : e.target.value }, () => this.poblarGrilla() );
                                          }} >
                      <option value={'R'}>Por Rango</option>
                      <option value={'U'}>Por Unidad</option>
                  </select>
                </Form.Group>
            </Col>
            <Col xs={2}>
            <Form.Group>
                <FormControl type="text" name="buscar" placeholder="Texto a filtrar" className="mr-sm-2" 
                        onChange={(e) => { this.setState({ filterGrilla: e.target.value })} } /> 
              </Form.Group>
            </Col>
            <Col xs={1}>
     {/**        <ExpGrillaExcelPDF showExportar =  {true}
                      tituloExporta = {'Listado de temas'}
                      sqlExporta= {`${URL_DB}SEL_MATERIA_TEMAS('D',null,null,${this.state.login_prof_id})`}
                
            />
            */}
      
           </Col>
          </Row>

          <Row>
            <Col>
              <Table striped bordered hover size="sm" id="data_table">
                <thead className="Grilla-header">
                <tr>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ttip_id')}>
                  #TipTrab</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ttip_deno')}>
                  TipTrab denom</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ttip_especifica')}>
                  TipTrab_especifica</Button>
                  </th> 
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('raeu_deno')}>
                  Rango</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('tper_deno')}>
                  Perfil denom</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ttip_especifica_unidad')}>
                  TipTrab_unidad</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('tteu_deno')}>
                  Unidad_deno</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('raeu_orden')}>
                  Rango_orden</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('raeu_id')}>
                  #Rango</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('tteu_orden')}>
                  #Unidad</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('tper_id')}>
                  #Perfil</Button>
                  </th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.fetchRegistros && 'Cargando...'
                }
                {
                  registrosFiltrados.map((regis) => {
                    return (
                      <tr key={regis.row_num}>
                        <td>{regis.ttip_id}</td>
                        <td><b>{regis.ttip_deno}</b></td>
                        <td>{regis.ttip_especifica}</td>
                        <td><b>{regis.raeu_deno}</b></td>
                        <td style={{textAlign: "left", backgroundColor: regis.tper_color }}>{regis.tper_deno}</td>
                        <td>{regis.ttip_especifica_unidad}</td>
                        <td>{regis.tteu_deno}</td>
                        <td>{regis.raeu_orden}</td>
                        <td>{regis.raeu_id}</td>
                        <td>{regis.tteu_id}</td>     
                        <td>{regis.tper_id}</td>
                        <td>
                        <Button variant="primary" size="sm" 
                                  onClick={() => this.handleModalEditEdit(regis)}><i className="fas fa-pen"></i></Button>
                        </td>
                      </tr>  
                    ) 
                  }) 
                }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>




        <Modal show={this.state.showModalEdit} onHide={this.handleModalEditExit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {`Asignar Perfil a:`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form onSubmit={this.handleEditRegis}>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label><b>{`${this.state.tpre_ttip_deno} ${this.state.tpre_raeu_id===0?'':' para rango '} ${this.state.tpre_raeu_deno}`}</b></Form.Label>
                  <select className="form-control" name="tpre_tper_id" 
                                                  value={this.state.tpre_tper_id} 
                                                  onChange={e => this.setState({ tpre_tper_id : (e.target.value)})} >
{ this.state.tpre_id===0 &&  // Cuando esta vacio ponemos un reg de 'Seleccion' para que haga el change
                                      <option key={0} value={0} disabled >( Seleccione Perfil )</option>
                        }
                  { this.state.perfiles.map((per) => { 
                      return (
                          <option key={per.tper_id} 
                                  value={per.tper_id}
                              > {per.tper_deno}
                          </option>
                              ) 
                    }) }
                  </select>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Button variant="success" size="sm" type="submit">
                  Grabar
                </Button>
                <Button variant="secondary" size="sm" onClick={this.handleModalEditExit}>
                  Cancelar
                </Button>
                <Alert key="1" variant="danger" show={respError.length >3 ? true : false} >
                      {respError}
                </Alert>
              </Col>
            </Row>
          </Form>
          </Modal.Body>
        </Modal>

      </div>
    );
  }
}

export default PanelRangosDefPage;
