import React  from "react";
import { NOMBRE_SIST } from './../../constants';
//import axios from 'axios';
import { Button, FormGroup, Form } from "react-bootstrap";
import "./EmulaPersona.css";


export default class SelectPersona extends React.Component {

  constructor(props){
    super(props);

    this.state = {
        login_pers_id: sessionStorage.getItem('USU_PERS_ID'),
        login_empr_id: sessionStorage.getItem('USU_EMPR_ID'),

        rowcount: false,
        cierro: false
    };

    this.handleSubmit          = this.handleSubmit.bind(this);
    //this.getAlumnosXCurso      = this.getAlumnosXCurso.bind(this)
  }
  
 
  componentDidMount() {
    //this.getCursosXProfe();
  }

  handleSubmit(event) {
    event.preventDefault();

    sessionStorage.setItem('USU_PERS_ID',Number(this.state.login_pers_id))
    sessionStorage.setItem('USU_EMPR_ID',Number(this.state.login_empr_id))

    //alert(sessionStorage.getItem('USU_PERS_ID'))
    this.setState({ cierro : true });
  }

  render(){
    return (
      <div className="Login" style={!this.state.cierro ? {} : { display: 'none' }}>
        <form onSubmit={this.handleSubmit}>
          <h3>{`Emular persona en ${NOMBRE_SIST}`}</h3>

          <FormGroup>
            <Form.Label>#ID Persona</Form.Label>
            <Form.Control type="number" name="login_pers_id" value={this.state.login_pers_id} 
                                                             onChange={(e) => this.setState({login_pers_id : e.target.value})} />
            <Form.Text className="text-muted"></Form.Text>
          </FormGroup>

          <FormGroup>
            <Form.Label>#ID Empresa</Form.Label>
            <Form.Control type="number" name="login_empr_id" value={this.state.login_empr_id} 
                                                             onChange={(e) => this.setState({login_empr_id : e.target.value})} />
            <Form.Text className="text-muted"></Form.Text>
          </FormGroup>

          <Button block bsSize="large"  type="submit">
            Set usuario
          </Button>
        </form>
      </div>
    );
  }
}
