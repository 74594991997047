import React from 'react';
import { URL_DB, NOMBRE_SIST } from './../../constants';
import axios from 'axios';
import { Table, Row, Col, Container, Button, Modal, Form, Alert, FormControl, DropdownButton ,ButtonGroup, Dropdown} from 'react-bootstrap';
import exportToCSV from '../../utils/functions/export-excel';
import { FaDownload } from 'react-icons/fa';
import swal from 'sweetalert';
import XLSX from 'xlsx';
import Notifications from '../../components/Notifications';
import ordenarGrilla from './../../utils/functions/ordenar-grilla'
import '../stylePages.css';
import AyudaAdmi from '../../components/Ayuda-admi';
import ejemplo_excel_subir from './../../assets/images/ejemplo_excel_subir.jpg';


class EmpresaPersonasPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opciones_id : 'V',
      empresa: [],
      registros: [],
      buscarGrillaValue: '',
      showModalEdit: false,
      showModalBorr: false,
      showModalImpor: false,
      login_empr_id: sessionStorage.getItem('USU_EMPR_ID'),
      empr_razon  :'',
      empr_estado :'',
      empr_ult_empe_f : '',
      ult_excel :'',
      empe_id : '', 
      empe_apenom : '', 
      empe_docum : '',
      input_sube_file:'',
      excelFile:[],
      opc_carga:'A',
      opc_dupli:'S',
      excelName:'',

      showhelp : false,
      filterGrilla: '',
      fetchingregistros: false,
      respuestaSp: [],
      respError: '',
      mensajeAlerta: '',
    };

    this.ordenarGrilla      = this.ordenarGrilla.bind(this);
    this.poblarGrilla       = this.poblarGrilla.bind(this);
    this.handleModalEditEdit = this.handleModalEditEdit.bind(this);
    this.handleModalEditExit = this.handleModalEditExit.bind(this);
    this.handleModalBorrEdit = this.handleModalBorrEdit.bind(this);
    this.handleEditRegis     = this.handleEditRegis.bind(this);
    this.handleBorrRegis     = this.handleBorrRegis.bind(this);
    this.handleExcelRegis    = this.handleExcelRegis.bind(this);
    this.handleModalAlta     = this.handleModalAlta.bind(this);
    this.handleModalImpor    = this.handleModalImpor.bind(this);
    this.handleChange        = this.handleChange.bind(this);
    this.handleChangeOpc     = this.handleChangeOpc.bind(this);
    this.readExcel           = this.readExcel.bind(this);
  }

  componentDidMount() {

    const sql = `${URL_DB}SEL_EMPRESA_DATOS(${this.state.login_empr_id})`
    axios.get(sql)
      .then((response) => {
        this.setState({
          empr_razon  : response.data[0][0].empr_razon,
          empr_estado : response.data[0][0].empr_estado,
          empr_ult_empe_f : response.data[0][0].empr_ult_empe_f,
          ult_excel   :  response.data[0][0].ult_excel
        })
        this.poblarGrilla(this.state.opciones_id);       
      })
      .catch((error) => console.log(error));
  
  }

  /*==========================================================================
   Utilidades de filtros y sort para la grilla ppal 
  *==========================================================================*/
  filtrarDatos() {
    const escapedValue = this.state.filterGrilla.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedValue, 'i');
    return this.state.registros.filter(filtro => 
      regex.test(filtro.empe_apenom) || 
      regex.test(filtro.empe_docum) )
  }

  ordenarGrilla = (key) => {
    const registros = ordenarGrilla(key, this.state.registros);

    this.setState({ registros });
  }
  
  /*==========================================================================
   Completo datos de grilla principal 
  *==========================================================================*/
  poblarGrilla = (paramOpc) => {

    this.setState({ fetchingregistros: true,
                    opciones_id: paramOpc,
     });
    const sql = `${URL_DB}SEL_EMPRESA_PERSONAS ('D',${this.state.login_empr_id},'${paramOpc}')`
    axios.get(sql)
      .then((response) => {
        this.setState({
          registros: response.data[0],
        })
      })  
      .catch((error) => console.log(error))
      .finally(() => {
        this.setState({ fetchingregistros: false })
      })
  }

  /*==========================================================================
   Abro MODAL para Alta ,  Modificar y subir excel 
  *==========================================================================*/
  handleModalEditEdit(regis) {  
    this.setState({ 
          showModalEdit: true,

          empe_id : regis.empe_id, 
          empe_apenom : regis.empe_apenom,
          empe_docum : regis.empe_docum,

          respError: '',
          mensajeAlerta: '',
          mensajeColor: 'N'
        })  
  }

  handleModalAlta() {

    this.setState({
          showModalEdit: true,

          empe_id : 0, 
          empe_apenom : '',
          empe_docum : '',

          respError: '',
          mensajeAlerta: ''
        })
  }
  
  handleChangeOpc(event) {
    let paramVal = event.target.value;
    this.poblarGrilla(paramVal)    
  };
  
    
  handleChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  
  handleModalEditExit() {

    this.setState({ showModalEdit: false,
                    showModalImpor: false})
    this.setState({
      mensajeAlerta : 'No se aplicaron cambios',
      mensajeColor  : 'red',
    })
  }

  handleModalImpor() {

    this.setState({
          showModalImpor: true,

          empe_id : 0, 
          empe_apenom : '',
          empe_docum : '',

          respError: '',
          mensajeAlerta: ''
        })
  }
 
  readExcel = (file) => {

    this.setState({ excelName : file.name})

    const promise = new Promise((resolve, reject) => {
    
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type:"buffer"});
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);
          resolve(data);
        };
        
        fileReader.onerror = (error) => {
          reject(error)
        }

    })
  
    promise.then((d) => {    

      let ok = true;

      if ( d.filter(function(item){
                      return typeof item.nombre ==='string';  
                            }).length !== d.length) {
          swal({ title : 'Error de formato de archivo excel',
          text  : 'No tiene la columna "nombre", arregle el nombre de la columna y reintente',
          icon : "error",  
          button : "Aceptar",
          timer : "4000" });
          ok = false;
      }

      if ( d.filter(function(item){
                      return typeof item.documento ==='number';  
                            }).length !== d.length) {
          swal({ title : 'Error de formato de archivo excel',
          text  : 'No tiene la columna "documento", arregle el nombre de la columna y reintente, verifique que el contenido sea numérico',
          icon : "error",  
          button : "Aceptar",
          timer : "4000" });
          ok = false;
      }

      if (ok) {
          this.setState({ excelFile: d.map(function(map) { 
                                    var obj = {}  
                                    obj.nombre       = map.nombre
                                    obj.documento  = map.documento
                                  return obj
                                })
                       });
      }

    })
  
  }
  

  /*==========================================================================
   Abro modal para BAJA : Cargo solo valores 
  *==========================================================================*/
  handleModalBorrEdit(regis) {

  swal({ 
      title : `Eliminar a ${regis.empe_apenom} de la nómina`,
      text  : ' confirma esta acción ?',
      icon : "warning",  
      buttons : ['No','Si'], 
      }).then( respuesta => {
        if ( respuesta===true ) {
            
          const sql =  `${URL_DB}B_EMPRESA_PERSONAS(${this.state.login_empr_id},${regis.empe_id},${this.state.login_empr_id})`  
              axios.get(sql)
              .then((response) => {
                    this.setState({
                      respuestaSp: response.data[0]
                    })
                    var obj = this.state.respuestaSp[0];
                    this.setState({
                      respError : obj.respuesta
                    })
          
                    if (this.state.respError==='OK') {
                        this.setState({
                            mensajeAlerta : 'Registrado correctamente',
                            mensajeColor  : 'green',
                        })          
                    }  
                    this.poblarGrilla(this.state.opciones_id);
                })
                .catch((error) => {
                  alert('ERROR interno API al actualizar BD:'+error)
                });
        
        } else {
                this.setState({
                  mensajeAlerta : 'No se aplico baja',
                  mensajeColor  : 'red',
                })
        }
      });

  }



  /*==========================================================================
   Actualizacion : Modificacion de empresas-nomina
  *==========================================================================*/
  handleEditRegis(event) {
  //  event.preventDefault();
      
    const sql =  `${URL_DB}AM_EMPRESA_PERSONAS(${this.state.login_empr_id},${this.state.empe_id},${this.state.login_empr_id},
'${encodeURIComponent(this.state.empe_apenom)}','${encodeURIComponent(this.state.empe_docum)}')`;
    axios.get(sql)
    .then((response) => {
          this.setState({
            respuestaSp: response.data[0]
          })
          var obj = this.state.respuestaSp[0];
          this.setState({
            respError : obj.respuesta
          })

          if (this.state.respError==='OK') {
              this.setState({
                  mensajeAlerta : 'Registrado correctamente',
                  mensajeColor  : 'green',
              })          
          }  
          this.poblarGrilla(this.state.opciones_id);
          this.setState({ showModalEdit : false });
      })
      .catch((error) => {
        alert('ERROR interno API al actualizar BD:'+error)
      });
      
  }

  /*==========================================================================
   Actualizacion : Baja de empresas
  *==========================================================================*/
  handleBorrRegis(event) {
    event.preventDefault();

    if (this.state.empr_baem_id===0) {
        swal({ title : 'No se efectuó baja de empresa',
                text  : 'Seleccione "Motivo de baja"',
                icon : "error",  
                button : "Aceptar",
                timer : "4000" });
   
    } else {
 
      const sql = `${URL_DB}B_PANEL_EMPRESA(${this.state.login_empr_id},${this.state.empr_id},${this.state.empr_baem_id})`
      console.log(sql)
      axios.get(sql)
      .then((response) => {
        this.setState({
          respuestaSp: response.data[0]
        })
        var obj = this.state.respuestaSp[0];
        this.setState({
          respError : obj.respuesta
        })
  
        if (this.state.respError==='OK') {
            this.setState({
                mensajeAlerta : 'Se aplicó baja correctamente',
                mensajeColor  : 'green',
            })
            this.setState({ showModalBorr: false })
            this.poblarGrilla(this.state.opciones_id)
        }    
          
        })
        .catch((error) => {
            alert('ERROR interno API al actualizar BD:'+error)
        })
  
    }

  }

  handleExcelRegis(event) {
      event.preventDefault();
        
      const sql =  `${URL_DB}AM_EMPRESA_PERSONAS_EXCEL(${this.state.login_empr_id},${this.state.login_empr_id},'${this.state.excelName}',
  '${this.state.opc_carga}','${this.state.opc_dupli}','${String(JSON.stringify(this.state.excelFile))}')`;
  console.log(sql)
      axios.get(sql)
      .then((response) => {
            this.setState({
              respuestaSp: response.data[0]
            })
            var obj = this.state.respuestaSp[0];
            this.setState({
              respError : obj.respuesta,
              respDetalle : obj.detalle,
            })

            if (this.state.respError==='OK') {

                swal({ title : 'Se subio correctamente la informacion del Excel',
                text  : `${this.state.respDetalle}`,
                icon : "information",  
                button : "Aceptar",
                timer : "6000" });
      
                this.setState({
                    mensajeAlerta : 'Registrado correctamente',
                    mensajeColor  : 'green',
                })          
            }  
            this.poblarGrilla(this.state.opciones_id);
            this.setState({ showModalImpor : false });
        })
        .catch((error) => {
          alert('ERROR interno API al actualizar BD:'+error)
        });
        
    }
  
  /*==========================================================================
   RENDER
  *==========================================================================*/

  render() {  

    const respError = this.state.respError;
    const mensajeAlerta = this.state.mensajeAlerta;
    const mensajeColor = this.state.mensajeColor;
    const registrosFiltrados = this.filtrarDatos();

    return(
      <div>        
        {mensajeAlerta.length >1 ? <Notifications mensaje={mensajeAlerta}
                                                  mensajeColor={mensajeColor}
                                    /> : '' }

        <Container  fluid="true">
          <Row>
            <Col xs={4} style={{ backgroundColor: "#ff6666" }}>
                <b>{this.state.empr_razon}</b>
            </Col>
            <Col xs={1} style={{ backgroundColor: "#ff6666" }}>
                Act:{this.state.empr_ult_empe_f}
            </Col>
            <Col xs={1} style={{ backgroundColor: "#ff6666" }}>
                {this.state.empr_estado}
            </Col>
            <Col xs={6} style={{textAlign: "left", fontSize: 10}}>
                <li>Solo debe registrar Nombre de referencia, y #documento del personal que desea monitorear </li>
                <li>Si el usuario ya usa la app, y colocó su #documento, saldrá como 'Registrado' y vinculará autom, de lo contario saldrá con fondo rojo</li>
            </Col>
          </Row>
          <Row>
            <Col xs={4} style={{textAlign: "left" }}>
                <h2><b>Registro de personal p/App</b></h2>
            </Col>
            <Col xs={2}>
                <Form.Group>
                  <select className="form-control" name="opciones_id" style={{fontWeight: "bold" }}
                      onChange={this.handleChangeOpc} >
                      <option value={'V'}>Personas vigentes</option>
                      <option value={'B'}>Personas De baja</option>
                  </select>
                </Form.Group>
            </Col>
            <Col xs={2}>
            <Form.Group>
                <FormControl type="text" name="buscar" placeholder="Texto a filtrar" className="mr-sm-2" 
                        onChange={(e) => { this.setState({ filterGrilla: e.target.value })} } /> 
              </Form.Group>
            </Col>
            <Col xs={1}>
                <DropdownButton as={ButtonGroup} title="+ Agregar" id="bg-vertical-dropdown-1" size='sm'>
                  <Dropdown.Item eventKey="1" onClick={this.handleModalAlta}>De a uno</Dropdown.Item>
                  <Dropdown.Item eventKey="2" onClick={this.handleModalImpor}>Desde un Excel</Dropdown.Item>
                </DropdownButton>
            </Col>
            <Col xs={1}>
                <Button  variant='outline-warning' size='sm' onClick={() => {this.setState({ showhelp : true}) } } > 
                Ayuda
                </Button>
            </Col>
            <Col xs={2}>
                <Button variant='outline-success' size='sm' 
                        onClick={(e) => exportToCSV(`Personal asignado app ${NOMBRE_SIST} ${this.state.opciones_id==='V'?' vigentes':' de baja'}`,
                                    `Personal_asignado_app_${NOMBRE_SIST} ${this.state.opciones_id==='V'?' vigentes':' de baja'}`,
                                    `${URL_DB}SEL_EMPRESA_PERSONAS ('D',${this.state.login_empr_id},'${this.state.opciones_id}')`
                                    )} > 
                        <FaDownload /> Excel
                </Button>
              { registrosFiltrados.length > 0 && ` ${registrosFiltrados.length} Regs`  }
            </Col>	      
          </Row>

          <Row>
            <Col>
              <Table striped bordered hover size="sm" id="data_table">
                <thead className="Grilla-header">
                <tr>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empe_id')}>
                      #ID</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empe_apenom')}>
                      Apellido/Nombre</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empe_docum')}>
                      #Documento</Button>
                  </th> 
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('pers_alta_f')}>
                      Registrado</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('pers_alta_f')}>
                      F.Regis</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('cant_ult_sema')}>
                      Visit ult sem</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('cant_ult_mes')}>
                      Visit ult mes</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('cant_total')}>
                      Visit Tot</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ulti_visita_f')}>
                      Ult visita</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('cant_msgs_mes')}>
                      Msj Ult mes</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('cant_msgs_total')}>
                      Msjs Tot</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empe_alta_f')}>
                      Alta Emp</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empe_baja_f')}>
                      Baja Emp</Button>
                  </th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.fetchingregistros && 'Cargando...'
                }
                {
                  registrosFiltrados.map((regis) => {
                    return (
                      <tr key={regis.empe_id}>
                        <td>{regis.empe_id}</td>
                        <td>{regis.empe_apenom}</td>
                        <td>{regis.empe_docum}</td>
                        <td style={{ backgroundColor: regis.color_regis }}>{regis.pers_apenom}</td>
                        <td style={{ backgroundColor: regis.color_regis }}>{regis.pers_alta_f}</td>
                        <td>{regis.cant_ult_sema}</td>
                        <td>{regis.cant_ult_mes}</td>
                        <td>{regis.cant_total}</td>
                        <td>{regis.ulti_visita_f}</td>
                        <td>{regis.cant_msgs_mes}</td>
                        <td>{regis.cant_msgs_total}</td>
                        <td>{regis.empe_alta_f}</td>
                        <td>{regis.empe_baja_f}</td>
                        <td>
  { this.state.opciones_id==='V' &&
                          <Button variant="primary" size="sm" onClick={() => this.handleModalEditEdit(regis)}>
                            <i className="fas fa-pen"></i></Button>
  }
  { this.state.opciones_id==='V' &&
                          <Button variant="danger" size="sm" onClick={() => this.handleModalBorrEdit(regis)}>
                            <i className="fas fa-trash-alt"></i></Button>
  }
                        </td>
                      </tr>  
                    ) 
                  }) 
                }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>



        <Modal show={this.state.showModalEdit} onHide={this.handleModalEditExit} >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.empe_id===0 ? 'Agregar persona a la nómina' : `Editar persona (#${this.state.empe_id})`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form onSubmit={this.handleEditRegis}>
          <Row>
              <Col xs={6}>
                <Form.Group>
                  <Form.Label>Apellido y nombre</Form.Label>
                  <Form.Control type="text" name="empe_apenom" value={this.state.empe_apenom} onChange={this.handleChange} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group>
                  <Form.Label>Nro de Documento</Form.Label>
                  <Form.Control type="text" name="empe_docum" value={this.state.empe_docum} onChange={this.handleChange} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>          
            </Row>
            <br />
            <Row>
                <Col xs={6} style={{textAlign: "right"}}>
                  <Button variant="success" size="sm" type="submit">
                    Registrar
                  </Button>
                  <Button variant="secondary" size="sm" onClick={this.handleModalEditExit}>
                    Cancelar
                  </Button>
                </Col>
                <Col xs={6}>
                  <Alert key="1" variant="danger" show={respError.length >3 ? true : false} >
                        {respError}
                  </Alert>
                </Col>
            </Row>
          </Form>
          </Modal.Body>
        </Modal>



        <Modal show={this.state.showModalImpor} onHide={this.handleModalEditExit} dialogClassName="modal-60" >
          <Modal.Header closeButton>
            <Modal.Title>Subir un archivo.xlsx con la nómina para actualizar el Registro</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form onSubmit={this.handleExcelRegis}>
          <Row>
              <Col xs={6}>
                <Row>
                  <Col xs={6} style={{textAlign: "left", fontSize: 9}}>
                    <li>Arme un Excel con la nómina de personas, no importa el nombre del archivo</li>
                    <li>Debe tener dos columnas con nombres 'nombre' y 'documento' en minúscula como el ejemplo:</li>
                    <li>Seleccione dicho archivo para subirlo y verifique el contenido en la grilla verde</li>
                    <li>Seleccione opciones de carga</li>
                  </Col>
                  <Col xs={6}>
                      <img  style={{ width: '100%' }} 
                            src={ejemplo_excel_subir} 
                            alt={'ejemplo-excel'} />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Form.Group controlId="formFileSm" className="mb-3">
                        <Form.Control type="file" size="sm" name="input_sube_file" 
                                      onChange={(e) => { 
                                        const file = e.target.files[0];
                                        this.readExcel(file) }}/>
                  </Form.Group>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                  <Form.Group>
                  <Form.Label><b>Opciones de carga</b></Form.Label>
                  <select className="form-control" name="opc_carga" size="2" 
                          style={{fontWeight: "bold" }} value={this.state.opc_carga}
                      onChange={this.handleChange} >
                      <option value={'A'}>Agregar a los existentes</option>
                      <option value={'S'}>Sobreescribir con esta lista nueva</option>
                  </select>
                  </Form.Group>
                  </Col> 
                </Row>    
                <Row>
                  <Col>
                  <Form.Group>
                  <Form.Label>Si el #Docum está duplicado:</Form.Label>
                  <select className="form-control" name="opc_dupli" size="2" 
                          style={{fontWeight: "bold" }} value={this.state.opc_dupli}
                      onChange={this.handleChange} >
                      <option value={'S'}>Lo reemplazo por el mas nuevo</option>
                      <option value={'N'}>Muestro error y no lo subo</option>
                  </select>
                  </Form.Group>
                  </Col> 
                </Row>    

              </Col>
              <Col xs={6}>
              
              
              <div className="barra_v_modal">
                <Table striped bordered hover size="sm" id="data_table">
                
                  <thead className="Grilla-header-success">
                  <tr>
                    <th></th>
                    <th>nombre</th>
                    <th>documento</th>
                  </tr>
                  </thead>
                  
                    <tbody>
                    {
                        this.state.excelFile.map((item) => {
                          return (
                            <tr key={item.__rowNum__}>
                              <td style={{ backgroundColor:'#e6e6e6', align:"center" }} >{item.__rowNum__}</td>
                              <td>{item.nombre}</td>
                              <td>{item.documento}</td>
                            </tr>  
                          ) 
                        }) 
                      }
                  </tbody>
                </Table>
              </div>
              <p>{`${this.state.excelFile.length} Reg`}</p>
              <p>{`Ult.proceso: ${this.state.ult_excel}`}</p>

              </Col>        
          </Row>
          <br />
          <Row>
                <Col xs={6} style={{textAlign: "right"}}>
                  <Button variant="success" size="sm" type="submit">
                    Registrar
                  </Button>
                  <Button variant="secondary" size="sm" onClick={this.handleModalEditExit}>
                    Cancelar
                  </Button>
                </Col>
                <Col xs={6}>
                  <Alert key="1" variant="danger" show={respError.length >3 ? true : false} >
                        {respError}
                  </Alert>
                </Col>
          </Row>
          </Form>
          </Modal.Body>
        </Modal>





        < Modal show={this.state.showhelp} onHide={() => { this.setState({ showhelp : false}) } } dialogClassName="modal-90">
            <AyudaAdmi clave={'empresa-cliente'}/>
        </Modal>

      </div>
    );
  }
}

export default EmpresaPersonasPage;
