import React  from 'react';
import { URL_DB, NOMBRE_SIST } from './../../constants';
import axios from 'axios';
import { Row, Col, Container, Button, Form, Alert, Table, Card } from 'react-bootstrap';
import Notifications from '../../components/Notifications';
import ShowAlerta from '../../components/Show-alerta';
import Ayuda from '../../components/Ayuda-pers';
import '../stylePages.css';

class DefPersonaPage extends React.Component {
  constructor() {
    super();

    this.state = {
      registros: [],
      pers_id : 0,
      pers_apellido : '',
      pers_nombre : '',
      pers_naci_f : '',
      edad : 0,
      pers_docum : '', 
      pers_sexo : '',
      pers_tall_id : '',
      pers_acti_id : '',
      pers_puesto : '',
      acti_show_depo : '',
      pers_ttip_id : '',
      ttip_especifica : '',
      pers_tcon_id : '',
      pers_tteu_id : '',
      pers_ingre : '',
      pers_egre : '',
      pers_dia_dom : '',
      pers_dia_lun : '',
      pers_dia_mar : '',
      pers_dia_mie : '',
      pers_dia_jue : '',
      pers_dia_vie : '',
      pers_dia_sab : '',
      pers_ergo_canti : 0,
      pers_ergo_color: '',
      pers_ergo_start : '',
      pers_ergo_segun : '',

      empr_razon  : '',
      empr_estado : '',
      empr_estado_color:'',

      horas: [],
      contratos: [],
      trabajoTipo: [],
      unidades: [],
      unidadesFull: [],
      deportes: [],
      actividades: [],
      contextura: [],
      sexo: [],
      veces: [],
      cartelHoras: '',
      login_pers_id: sessionStorage.getItem('USU_PERS_ID'),
      fetchingregistros: false,
      paramVisible: true,
      showToast: false,

      respuestaSp: [],
      respError: '',
      mensajeAlerta: '',
    };
    
    this.getInicio              = this.getInicio.bind(this);
    this.getDifeHoras           = this.getDifeHoras.bind(this);
    this.handleChange           = this.handleChange.bind(this);
    this.handleChangeDias       = this.handleChangeDias.bind(this);
    this.handleChangeDeporte    = this.handleChangeDeporte.bind(this);
    this.handleGrabar           = this.handleGrabar.bind(this);

  }

  async getPersona() {
    this.setState({ fetchingregistros: true });

    const sql =  `${URL_DB}SEL_TRABAJO_ESPEUNIDADES()`
    axios.get(sql)
      .then((response) => {
        this.setState({
          unidadesFull: response.data[0]
        })
        //========== una vez leido espeUnidades lei persona =========
          const sql2 = `${URL_DB}SEL_PERSONA_FULL('D',${this.state.login_pers_id})`;
          axios.get(sql2)
            .then((response) => {
              if(response.data[0].length > 0) {
                this.setState({
                    pers_id : response.data[0][0].pers_id,
                    showToast: false, // No muestro la tostada si existe ya la persona
                    pers_apellido : response.data[0][0].pers_apellido,
                    pers_nombre : response.data[0][0].pers_nombre,
                    pers_naci_f : response.data[0][0].pers_naci_f,
                    edad : response.data[0][0].edad,
                    pers_docum : response.data[0][0].pers_docum, 
                    pers_sexo : response.data[0][0].pers_sexo,
                    pers_tall_id : response.data[0][0].pers_tall_id,
                    pers_acti_id : response.data[0][0].pers_acti_id,
                    acti_show_depo : response.data[0][0].acti_show_depo,
                    pers_ttip_id : response.data[0][0].pers_ttip_id,
                    ttip_deno : response.data[0][0].ttip_deno,
                    ttip_especifica : response.data[0][0].ttip_especifica,
                    ttip_especifica_unidad : response.data[0][0].ttip_especifica_unidad,
                    pers_tteu_id : response.data[0][0].pers_tteu_id,
                    pers_tcon_id  : response.data[0][0].pers_tcon_id,
                    pers_ingre : response.data[0][0].pers_ingre,
                    pers_egre : response.data[0][0].pers_egre,
                    pers_dia_dom : response.data[0][0].pers_dia_dom,
                    pers_dia_lun : response.data[0][0].pers_dia_lun,
                    pers_dia_mar : response.data[0][0].pers_dia_mar,
                    pers_dia_mie : response.data[0][0].pers_dia_mie,
                    pers_dia_jue : response.data[0][0].pers_dia_jue,
                    pers_dia_vie : response.data[0][0].pers_dia_vie,
                    pers_dia_sab : response.data[0][0].pers_dia_sab,
                    pers_ergo_canti : response.data[0][0].pers_ergo_canti,
                    pers_ergo_color : response.data[0][0].alca_color,
                    pers_ergo_start : response.data[0][0].pers_ergo_start,      
                    pers_ergo_segun : response.data[0][0].pers_ergo_segun,
                    pers_puesto : response.data[0][0].pers_puesto, 
                    pers_ini_ciclo_f : response.data[0][0].pers_ini_ciclo_f, 
                    cartelHoras : this.getDifeHoras(response.data[0][0].pers_ingre,response.data[0][0].pers_egre),

                    unidades : this.state.unidadesFull.filter(uni => uni.tteu_unidad === response.data[0][0].ttip_especifica_unidad).sort(uni => uni.tteu_orden),
                    })
                
              } else {  // No existe, alta     
                
                  const sql = `${URL_DB}SEL_PERSONA_ALTA_DEFAULT()`;
                  axios.get(sql)
                    .then((response) => {
                      this.setState({
                          pers_id    : null,
                          showToast: true, // Muestro el help para nuevo
                          pers_sexo : '0',
                          pers_tall_id : response.data[0][0].pers_tall_id,
                          pers_acti_id : response.data[0][0].pers_acti_id,
                          acti_show_depo : response.data[0][0].acti_show_depo,
                          pers_ttip_id : 0,
                          ttip_especifica : '',
                          ttip_especifica_unidad : 'N',
                          pers_tteu_id : 0,
                          pers_tcon_id  : response.data[0][0].pers_tcon_id,
                          pers_ingre : response.data[0][0].pers_ingre,
                          pers_egre : response.data[0][0].pers_egre,
                          pers_dia_dom : response.data[0][0].pers_dia_dom,
                          pers_dia_lun : response.data[0][0].pers_dia_lun,
                          pers_dia_mar : response.data[0][0].pers_dia_mar,
                          pers_dia_mie : response.data[0][0].pers_dia_mie,
                          pers_dia_jue : response.data[0][0].pers_dia_jue,
                          pers_dia_vie : response.data[0][0].pers_dia_vie,
                          pers_dia_sab : response.data[0][0].pers_dia_sab,
                          pers_ergo_canti : response.data[0][0].pers_ergo_canti,
                          pers_ergo_color : response.data[0][0].pers_ergo_color,
                          pers_ergo_start : response.data[0][0].pers_ergo_start,      
                          pers_ergo_segun : response.data[0][0].pers_ergo_segun,
                          cartelHoras : this.getDifeHoras(response.data[0][0].pers_ingre,response.data[0][0].pers_egre),
                      })
                  
                    })  
                    .catch((error) => console.log(error));
              }
      
            })
            .catch((error) => console.log(error))
            .finally(() => {
              this.setState({ fetchingregistros: false })
            })
      
        //========== una vez leido espeUnidades lei persona =========
      })
      .catch((error) => console.log(error))

    }


  async getPersonaEmpresa() {

    const sql = `${URL_DB}SEL_PERSONA_EMPRESA(${this.state.login_pers_id})`
    axios.get(sql)
      .then((response) => {
        if (response.data[0].length>0) {
          this.setState({
            empr_razon  : response.data[0][0].empr_razon,
            empr_estado : response.data[0][0].empr_estado,
            empr_estado_color : response.data[0][0].estado_color,
          })       
        }
      })
      .catch((error) => console.log(error));
  }


  async getTrabajoContrato() {
    const sql =  `${URL_DB}SEL_TRABAJO_CONTRATO(null)`
    axios.get(sql)
      .then((response) => {
        this.setState({
          contratos: response.data[0]
        })
      })
      .catch((error) => console.log(error))
  }

  async getDeportes() {
    const sql =  `${URL_DB}SEL_PERSONA_DEPORTE_GRILLA(${this.state.login_pers_id})`
    axios.get(sql)
      .then((response) => {
        this.setState({
          deportes: response.data[0]
        })
      })
      .catch((error) => console.log(error))

  }

  async getActividades() {
    const sql =  `${URL_DB}SEL_PERSONA_ACTIVIDADES()`
    axios.get(sql)
      .then((response) => {
        this.setState({
          actividades: response.data[0]
        })
      })
      .catch((error) => console.log(error))

  }

  async getSexo() {
    const sql =  `${URL_DB}SEL_PERSONA_SEXO()`
    axios.get(sql)
      .then((response) => {
        this.setState({
          sexo: response.data[0]
        })
      })
      .catch((error) => console.log(error))

  }

  async getContextura() {
    const sql =  `${URL_DB}SEL_PERSONA_CONTEXTURA()`
    axios.get(sql)
      .then((response) => {
        this.setState({
          contextura: response.data[0]
        })
      })
      .catch((error) => console.log(error))
  }

  async getTrabajoTipo() {
    const sql =  `${URL_DB}SEL_TRABAJO_TIPO()`
    axios.get(sql)
      .then((response) => {
        this.setState({
          trabajoTipo: response.data[0]
        })
      })
      .catch((error) => console.log(error))
  }

  async getTrabEspeUnidades() {
 
  }

  async getAlertaVeces() {
    const sql =  `${URL_DB}SEL_ALERTA_VECES()`
    axios.get(sql)
      .then((response) => {
        this.setState({
          veces: response.data[0]
        })
      })
      .catch((error) => console.log(error))
  }

  async getInicio() {
    try{
      await Promise.all([ this.getPersonaEmpresa(), this.getTrabajoContrato(), this.getPersona(),
                          this.getDeportes(), this.getActividades(), this.getSexo(), this.getContextura(),
                          this.getTrabajoTipo(), this.getTrabEspeUnidades(), this.getAlertaVeces() ])
    } catch(e) {
      console.log(e);
    } finally {

    }
  }


  componentDidMount() {
    this.getInicio()
   
    var horas = [];
    for (let i = 0; i < 24 ; i++) {
      horas.push({id: i<10?`0${i}:00`:`${i}:00`});
      horas.push({id: i<10?`0${i}:30`:`${i}:30`});
    }
    this.setState({ horas });
  }

  handleShowToast = () => {
    this.setState({ showToast : false})
  }

  getDifeHoras = (p_ingre, p_egre) => {
  
    //Normalmente como por ejemplo entre a las 10 y sale a las 18
    var day1 = new Date(`2000-01-01T${p_ingre}:00`);
    var day2 = new Date(`2000-01-01T${p_egre}:00`);

    //Si  por ejemplo entre a las 22 y sale a las 6 del otro dia
    if (p_ingre > p_egre) {
        day2 = new Date(`2000-01-02T${p_egre}:00`);
    } 
    var difeMin = (day2.getTime()-day1.getTime()) / 60 / 1000;
    var fracMin = parseInt(difeMin%60)===0?'':`:${parseInt(difeMin%60)}`
   
    return  `${parseInt(difeMin/60)}${fracMin}`;
  }


  handleChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    })

    if (name==='pers_ttip_id') {
        const trab = this.state.trabajoTipo.filter(tip => tip.ttip_id === parseInt(value));
        this.setState({
          unidades: this.state.unidadesFull.filter(uni => uni.tteu_unidad === trab[0].ttip_especifica_unidad).sort(uni => uni.tteu_orden),
          ttip_especifica : trab[0].ttip_especifica,
          ttip_especifica_unidad : trab[0].ttip_especifica_unidad,
         }); 
    }

    if (name==='pers_acti_id') {
        const swDepo = this.state.actividades.filter(act => parseInt(act.acti_id) === parseInt(value))[0].acti_show_depo;
        this.setState({
          pers_acti_id : value,
          acti_show_depo : swDepo,
        })
    }

    if (name==='pers_ingre' || name==='pers_egre' ){
      this.setState({
        [name]: value
        },() => { 
          this.setState({cartelHoras : this.getDifeHoras(this.state.pers_ingre, this.state.pers_egre) })
        })
    }

    if (name==='pers_ergo_canti') {
      let ind = this.state.veces.findIndex( elem => elem.alca_veces_id === parseInt(value));
      this.setState({ 
        pers_ergo_color : this.state.veces[ind].alca_color
      });
    }

    if (name==='pers_ergo_canti' && this.state.pers_ergo_start==='00:00' && parseInt(value) === 1) {
      // busco el valor por cant de veces por dia
      let ind = this.state.veces.findIndex( elem => elem.alca_veces_id === parseInt(value));
      const lapso = this.state.veces[ind].alca_start_lapso_dsd_entra
      // convierto a dias para sumar ingreso + lapso
      var day1 = new Date(`2000-01-01T${this.state.pers_ingre}:00`);
      var day2 = new Date(`2000-01-01T${lapso}:00`);
      // convierto a minutos, sumo y reconvierto
      var min = ((day1.getHours() * 60) + day1.getMinutes()) + ((day2.getHours() * 60) + day2.getMinutes());
      var minu = parseInt(min % 60);
      // activo las alertas y no hay hora_inicial, pongo la default de alertas_veces = hora_ingreso + alca_start_lapso_dsd_entra
      this.setState({
          pers_ergo_start : `${parseInt(min/60)}:${minu<10?'0':''}${minu}`,
      });
    }

    if (name==='pers_ergo_canti' && this.state.pers_ergo_secon==='00:00' && parseInt(value) === 2) {
      // busco el valor por cant de veces por dia
      let ind = this.state.veces.findIndex( elem => elem.alca_veces_id === parseInt(value));
      const lapso = this.state.veces[ind].alca_start_lapso_dsd_entra
      // convierto a dias para sumar ingreso + lapso
      var day21 = new Date(`2000-01-01T${this.state.pers_ingre}:00`);
      var day22 = new Date(`2000-01-01T${lapso}:00`);
      // convierto a minutos, sumo y reconvierto
      var min2 = ((day21.getHours() * 60) + day21.getMinutes()) + ((day22.getHours() * 60) + day22.getMinutes());
      var minu2 = parseInt(min % 60);
      // activo las alertas y no hay hora_inicial, pongo la default de alertas_veces = hora_ingreso + alca_start_lapso_dsd_entra
      this.setState({
          pers_ergo_secon : `${parseInt(min2/60)}:${minu<10?'0':''}${minu2}`,
      });
    }

  }


  handleChangeDias(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value===true?'S':'N'
    })
  } 

  handleChangeDeporte(event,v_depo_id) {
    
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const ind = this.state.deportes.findIndex( depo => depo.depo_id === v_depo_id);
    const depo = this.state.deportes
    depo[ind].si_no = value===true?'S':'N';

    this.setState({
      deportes : depo,
    })
    
  }
 
  /*==========================================================================
   Actualizacion : Modificacion
  *==========================================================================*/
  handleGrabar(event) {
    event.preventDefault();

   var depo_json = this.state.deportes.filter(function(depo){
    return depo.si_no === 'S';
  }).map((item) => { 
    var obj = {}
    obj.depo_id = item.depo_id
    return ( obj )
  })  

const sql =  `${URL_DB}AM_PERSONA(${this.state.login_pers_id},${this.state.login_pers_id},'${encodeURIComponent(this.state.pers_apellido)}',
'${encodeURIComponent(this.state.pers_nombre)}','${this.state.pers_naci_f}','${encodeURIComponent(this.state.pers_docum)}',
'${this.state.pers_sexo}','${this.state.pers_tall_id}','${this.state.pers_acti_id}',
'${this.state.pers_ingre}','${this.state.pers_egre}','${this.state.pers_dia_dom}',
'${this.state.pers_dia_lun}','${this.state.pers_dia_mar}','${this.state.pers_dia_mie}',
'${this.state.pers_dia_jue}','${this.state.pers_dia_vie}','${this.state.pers_dia_sab}',
'${this.state.pers_ergo_canti}','${this.state.pers_ergo_start}','${this.state.pers_ergo_segun}',
'${this.state.pers_ttip_id}','${this.state.pers_tteu_id}','${this.state.pers_tcon_id}',
'${encodeURIComponent(this.state.pers_puesto)}','${String(JSON.stringify(depo_json))}')`;
    axios.get(sql)
    .then((response) => {
          this.setState({
            respuestaSp: response.data[0]
          })
          var obj = this.state.respuestaSp[0];
          this.setState({
            respError : obj.respuesta,
          })

          if (this.state.respError==='OK') {
              this.setState({
                  mensajeAlerta : 'Registrado correctamente',
                  mensajeColor  : 'green',
              })
              if(this.state.pers_id===0 || this.state.pers_id===null) {
                  this.setState({
                    pers_id      : obj.codigo,
                    login_pers_id: obj.codigo,
                  })
                  sessionStorage.setItem('USU_PERS_ID',obj.codigo)
              }
          }    
      })
      .catch((error) => {
        alert('ERROR interno API al actualizar BD:'+error)
      })

  }

  /*==========================================================================
   RENDER
  *==========================================================================*/

  render() {  

    const respError = this.state.respError;
    const mensajeAlerta = this.state.mensajeAlerta;
    const mensajeColor = this.state.mensajeColor;

    return(
      <div style={this.state.paramVisible ? {} : { display: 'none' }}>        
        {mensajeAlerta.length >1 ? <Notifications mensaje={mensajeAlerta}
                                                  mensajeColor={mensajeColor}
                                    /> : '' }

        <Container >
        <Form onSubmit={this.handleGrabar}>
          <Row>
            <Col md={3} style={{fontWeight: "bold", textAlign: "left"}}>
                <h2>Datos del usuario</h2>
            </Col>    
            <Col md={6} style={{ textAlign: "right"}}>
                <h2>
                {this.state.empr_razon!==''?`Asociado a ${this.state.empr_razon} - `:''}
                <span style={{color: this.state.empr_estado_color}}>{this.state.empr_estado }</span>
                </h2>
            </Col>
            <Col md={1}>
                <Button  variant='outline-warning' size='sm' 
                     onClick={() => {this.setState({ showToast : true}) } } > 
                Ayuda
                </Button>
            </Col>
            <Col md={2}>
                <ShowAlerta pers_id = {this.state.login_pers_id} />
            </Col>
          </Row>

          <Ayuda  clave='persona-datos' 
                  showtoast={this.state.showToast} 
                  handleShowToast={this.handleShowToast}/> 

          <Row>
            <Col md={10} sm={12}>
              <Row>

                <Card>
                <Card.Header>Datos personales</Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text" name="pers_nombre" value={this.state.pers_nombre}
                            onChange={this.handleChange} />
                        <Form.Text className="text-muted"></Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Apellido</Form.Label>
                        <Form.Control type="text" name="pers_apellido" value={this.state.pers_apellido} 
                                                                      onChange={this.handleChange} />
                        <Form.Text className="text-muted"></Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <Form.Group>
                        <Form.Label>Rutina para</Form.Label>
                        <select className="form-control" name="pers_sexo" value={ this.state.pers_sexo } 
                                                                          onChange={this.handleChange} >
                      { this.state.pers_id===null &&  // Cuando esta vacio ponemos un reg de 'Seleccion' para que haga el change
                                      <option key={'0'} value={'0'} disabled >( Selecc )</option>
                      }
                        { this.state.sexo.map((sex) => { 
                            return (
                                    <option key={sex.sexo} 
                                            value={sex.sexo}
                                    > {sex.sexo_deno}
                                    </option>
                                    ) 
                          }) }
                        </select>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>#Documento</Form.Label>
                        <Form.Control type="number" name="pers_docum" value={this.state.pers_docum} 
                                                                      onChange={this.handleChange} />
                        <Form.Text className="text-muted"></Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                    <Form.Group>
                      <Form.Label>F.Nacimiento</Form.Label>
                      <Form.Control type="date" name="pers_naci_f" value={this.state.pers_naci_f} 
                                                                   onChange={this.handleChange} />
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                    </Col>                   
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Contextura</Form.Label>
                        <select className="form-control" name="pers_tall_id"
                                                          value={ this.state.pers_tall_id }
                                                          onChange={this.handleChange} >
                        { this.state.contextura.map((cntx) => { 
                            return (
                                    <option key={cntx.tall_id} 
                                            value={cntx.tall_id}
                                    > {cntx.tall_deno}
                                    </option>
                                    ) 
                          }) }
                        </select>
                      </Form.Group>
                    </Col>
                    <Col md={1}>
                      <Form.Group>
                        <Form.Label>#Regis</Form.Label>
                        <Form.Control type="number"  value={this.state.pers_id} style={{ textAlign: "center"}} readOnly />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
    
            </Row>
            <Row>
              <Card>
              <Card.Header>Datos Trabajo</Card.Header>
              <Card.Body>
                <Row>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Tipo de trabajo</Form.Label>
                      <select className="form-control" name="pers_ttip_id" value={this.state.pers_ttip_id }
                                                                          onChange={this.handleChange} >

                      { this.state.pers_id===null &&  // Cuando esta vacio ponemos un reg de 'Seleccion' para que haga el change
                                      <option key={0} value={0} disabled >( Seleccione tipo Trab )</option>
                      }

                      { this.state.trabajoTipo.map((tip) => { 
                          return (
                                  <option key={tip.ttip_id} 
                                          value={tip.ttip_id}
                                  > {tip.ttip_deno}
                                  </option>
                                  ) 
                        }) }
                      </select>
                    </Form.Group>
                  </Col>
                  <Col md={1}>
                    <Form.Group>
                        <Form.Label>Tot Hs</Form.Label>
                        <Form.Control type="text" value={this.state.cartelHoras} readOnly
                                                  style={{fontWeight: "bold", textAlign: "center", backgroundColor: '#66ccff'}}  />
                    </Form.Group>
                  </Col>
                  <Col md={3}>  
            { this.state.ttip_especifica_unidad !== 'N' &&
                    <Form.Group>
                      <Form.Label>{this.state.ttip_especifica}</Form.Label>
                      <select className="form-control" name="pers_tteu_id" value={ this.state.pers_tteu_id }
                                                                          onChange={this.handleChange} >
                      { this.state.unidades.map((uni) => { 
                          return (
                                  <option key={uni.tteu_id} 
                                          value={uni.tteu_id}
                                  > {uni.tteu_deno}
                                  </option>
                                  ) 
                        }) }
                      </select>
                    </Form.Group>
              }
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Contrato</Form.Label>
                      <select className="form-control" name="pers_tcon_id" value={ this.state.pers_tcon_id } 
                                                                        onChange={this.handleChange} >
                      { this.state.contratos.map((contra) => { 
                          return (
                                  <option key={contra.tcon_id} 
                                          value={contra.tcon_id}
                                  > {contra.tcon_deno}
                                  </option>
                                  ) 
                        }) }
                      </select>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                      <Form.Group>
                        <Form.Label>Puesto</Form.Label>
                        <Form.Control type="text" name="pers_puesto" value={this.state.pers_puesto} 
                                                                      onChange={this.handleChange} />
                        <Form.Text className="text-muted"></Form.Text>
                      </Form.Group>
                    </Col>
                </Row>
                <br />
                <Row>
                  <Col md={1}>
                    <Form.Group>
                      <Form.Label>Ingreso</Form.Label>
                      <select className="form-control" name="pers_ingre" value={ this.state.pers_ingre } 
                                                                        onChange={this.handleChange} >
                      { this.state.horas.map((hh) => { 
                          return (
                                  <option key={hh.id} value={hh.id}> {hh.id} </option>
                                  ) 
                        }) }
                      </select>
                    </Form.Group>
                  </Col>
                  <Col md={1}>
                    <Form.Group>
                      <Form.Label>Egreso</Form.Label>
                      <select className="form-control" name="pers_egre" value={ this.state.pers_egre } 
                                                                        onChange={this.handleChange} >
                      { this.state.horas.map((hh) => { 
                          return (
                                  <option key={hh.id} value={hh.id}> {hh.id} </option>
                                  ) 
                        }) }
                      </select>
                    </Form.Group>
                  </Col>
                  <Col md={5}>  
                  <Table style={{fontWeight: "bold", backgroundColor: '#f7f7f7'}} border>  
                    <thead>
                      <tr>
                        <th>Dom</th>
                        <th>Lun</th>
                        <th>Mar</th>
                        <th>Mie</th>
                        <th>Jue</th>
                        <th>Vie</th>
                        <th>Sab</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={1}>
                        <td style={{textAlign: "center"}}>
                          <Form.Check type={'checkbox'} name="pers_dia_dom" 
                              checked={ this.state.pers_dia_dom==='N' ? false : true }  onChange={this.handleChangeDias} />
                        </td>
                        <td style={{textAlign: "center"}}>
                          <Form.Check type={'checkbox'} name="pers_dia_lun" 
                              checked={ this.state.pers_dia_lun==='N' ? false : true } onChange={this.handleChangeDias} />
                        </td>
                        <td style={{textAlign: "center"}}>
                          <Form.Check type={'checkbox'} name="pers_dia_mar" 
                              checked={ this.state.pers_dia_mar==='N' ? false : true }  onChange={this.handleChangeDias} />
                        </td>
                        <td style={{textAlign: "center"}}>
                          <Form.Check type={'checkbox'} name="pers_dia_mie" 
                              checked={ this.state.pers_dia_mie==='N' ? false : true }  onChange={this.handleChangeDias} />
                        </td>   
                        <td style={{textAlign: "center"}}>
                          <Form.Check type={'checkbox'} name="pers_dia_jue" 
                              checked={ this.state.pers_dia_jue==='N' ? false : true }  onChange={this.handleChangeDias} />
                        </td>
                        <td style={{textAlign: "center"}}>
                          <Form.Check type={'checkbox'} name="pers_dia_vie" 
                              checked={ this.state.pers_dia_vie==='N' ? false : true }  onChange={this.handleChangeDias} />
                        </td>
                        <td style={{textAlign: "center"}}>
                          <Form.Check type={'checkbox'} name="pers_dia_sab" 
                              checked={ this.state.pers_dia_sab==='N' ? false : true }  onChange={this.handleChangeDias} />
                        </td>                       
                      </tr>  
                    </tbody>
                  </Table>
     
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label><b>{NOMBRE_SIST}</b></Form.Label>
                        <select className="form-control" name="pers_ergo_canti" value={this.state.pers_ergo_canti}
                                          style={{fontWeight: "bold", backgroundColor: this.state.pers_ergo_color}}
                                onChange={this.handleChange} >
                        { this.state.veces.map((vez) => { 
                            return (   
                                    <option key={vez.alca_veces_id} 
                                            value={vez.alca_veces_id}
                                            style={{ backgroundColor: "#ffffff"}}
                                    > {vez.alca_deno}
                                    </option>
                                    ) 
                          }) }
                        </select>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Row>
                        <Col md={6}>
                { parseInt(this.state.pers_ergo_canti) > 0 &&
                        <Form.Group>
                          <Form.Label><b>1ra alerta</b></Form.Label>
                          <Form.Control type="time" name="pers_ergo_start" value={this.state.pers_ergo_start}   
                                                          onChange={this.handleChange} />
                        </Form.Group>
              }
                      </Col>
                      <Col md={6}>
                { parseInt(this.state.pers_ergo_canti) === 2 &&
                        <Form.Group>
                          <Form.Label><b>2da alerta</b></Form.Label>
                          <Form.Control type="time" name="pers_ergo_segun" value={this.state.pers_ergo_segun}   
                                                          onChange={this.handleChange} />
                        </Form.Group>
              }
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            </Row>
            </Col>

            <Col md={2} sm={12}>
              <Card>
              <Card.Header>Activ Personales</Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group>
                        <Form.Label>Actividad Fisica</Form.Label>
                        <select className="form-control" name="pers_acti_id" value={ this.state.pers_acti_id } 
                                                                          onChange={this.handleChange} >
                        { this.state.actividades.map((act) => { 
                            return (
                                    <option key={act.acti_id} 
                                            value={act.acti_id}
                                    > {act.acti_deno}
                                    </option>
                                    ) 
                          }) }
                        </select>
                      </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
    
                    {  this.state.acti_show_depo ==='S' && this.state.deportes.map((dep,ind) => {
                              return (
                              <React.Fragment key={ind}>
                                <Row>
                                  <Form.Group >
                                    <Form.Check 
                                            defaultChecked={dep.si_no === 'S' ? true : false} 
                                            type={'checkbox'}
                                            id={`default-${dep.depo_id}`}
                                            key={dep.depo_id}
                                            label={ dep.depo_deno } 
                                            style={{textAlign: "left"}}
                                            onChange={(e) => this.handleChangeDeporte(e, dep.depo_id)} /> 
                                  </Form.Group>                                
                              </Row>
                              </React.Fragment>
                              ) 
                            }) 
                    }  
                  </Col>
                </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={5} style={{textAlign: "left", fontSize: 9}}>
                 <li> El #documento solo para cuando la empresa es cliente de la App.</li>
                 <li> El tipo de trabajo, contextura y la edad determinarán las rutinas dentro del horario laboral</li>
                 <li> Si activa alertas mas de una vez por dia, la misma rutina se partirá en esa cantidad de veces</li>
            </Col>
            <Col xs={2}>
                <Button variant="success" size="sm" type="submit">
                  Grabar</Button>
                <Button variant="secondary" size="sm" onClick={() => { this.setState({ paramVisible : false }) } }>
                  Cancelar</Button>
            </Col>
            <Col xs={5}>
                  <Alert key="1" variant="danger" show={respError.length >3 ? true : false} >
                  {respError}</Alert>
            </Col>
          </Row>
        </Form>
         
        </Container>


      </div>
    );
  }
}

export default DefPersonaPage;
