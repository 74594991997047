import React  from 'react';
import { URL_DB } from './../../constants';
import axios from 'axios';
import { Table, Row, Col, Container, Button, Modal, Form, Alert, FormControl, Tabs, Tab, Card } from 'react-bootstrap';
//import ExpGrillaExcelPDF from '../../components/ExpGrillaExcelPDF';
import ReactPlayer from 'react-player';
import swal from 'sweetalert';
import AyudaAdmi from '../../components/Ayuda-admi';
import Notifications from '../../components/Notifications';
import ordenarGrilla from './../../utils/functions/ordenar-grilla'
import AutosuggestComponent from '../../components/Autosuggest/index.js';
import '../stylePages.css';


class PanelRutinasPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      registros : [],
      ruti_id : '',
      ruti_deno : '',
      ciclos : [],
      ejercicios1 : [],
      ejercicios2 : [],
      ejercicios3 : [],
      ejercicios4 : [],
      listaEjer :[],
      login_admi_id: sessionStorage.getItem('USU_ADMI_ID'),
      buscarGrillaValue: '',
      showModalEditCiclo: false,
      showModalEditEjer: false,
      showModalSearch: false,
      searchRow : 0,
      fetchModal: false,
      //search_ejer_id : '',
      search_ejer_deno : '',
      tabSelec : 1,
      showhelp : false,
      filterGrilla: '',
      fetchingregistros: false,
      respuestaSp: [],
      respError: '',
      mensajeAlerta: '',

      selectedOption: '',
    };

    this.ordenarGrilla        = this.ordenarGrilla.bind(this);
    this.getPanelRutinas      = this.getPanelRutinas.bind(this);
    this.getInicio            = this.getInicio.bind(this);

    this.handleModalCicloAlta = this.handleModalCicloAlta.bind(this);
    this.handleModalCicloModi = this.handleModalCicloModi.bind(this);
    this.handleModalCicloBaja = this.handleModalCicloBaja.bind(this);
    this.handleChangeCiclo    = this.handleChangeCiclo.bind(this);
    this.handleModalExitCiclo = this.handleModalExitCiclo.bind(this);
    this.handleGrabarCiclos   = this.handleGrabarCiclos.bind(this);
 
    this.handleModalEjerModi = this.handleModalEjerModi.bind(this);
    this.handleModalEjerBaja = this.handleModalEjerBaja.bind(this);
    this.handleChangeEjer    = this.handleChangeEjer.bind(this);
    this.handleModalExitEjer = this.handleModalExitEjer.bind(this);
    this.handleGrabarEjers   = this.handleGrabarEjers.bind(this);

    this.handleChangeSugg_ejer_id = this.handleChangeSugg_ejer_id.bind(this);
  }

  async getPanelRutinas() {
    const sql =  `${URL_DB}SEL_PANEL_RUTINAS('D',0)`
    const response = await axios.get(sql)
    try {
      this.setState({ registros: response.data[0] })
    } catch(error) {
      console.log(error)
    }
  }

  async getListaEjer() {
    const sql =  `${URL_DB}SEL_PANEL_EJERCICIOS('D')`
    const response = await axios.get(sql)
    try {
      this.setState({ listaEjer: response.data[0] })
    } catch(error) {
      console.log(error)
    } 
  }
 
 
  async getInicio() {
    try{
      await Promise.all([this.getPanelRutinas(),this.getListaEjer() ])
    } catch(e) {
      console.log(e);
    }
  }

  componentDidMount() {
    this.getInicio()
  }

  /*==========================================================================
   Utilidades de filtros y sort para la grilla ppal 
  *==========================================================================*/
  filtrarDatos() {
    const escapedValue = this.state.filterGrilla.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedValue, 'i');
    return this.state.registros.filter(filtro => 
      regex.test(filtro.ruti_deno) || 
      regex.test(filtro.ruci_deno) || 
      regex.test(filtro.ejer_deno) || 
      regex.test(filtro.impleme) || 
      regex.test(filtro.ejer_video) )
  }
      
  ordenarGrilla = (key) => {
    const registros = ordenarGrilla(key, this.state.registros);

    this.setState({ registros });
  }


  /*==========================================================================
      Modal de CICLOS
  *==========================================================================*/
  handleModalCicloModi(regis) {

    this.setState({ fetchModal: true });
    const sql = `${URL_DB}SEL_PANEL_CICLOS('D',${regis.ruti_id})`
    axios.get(sql)
      .then((response) => {
        this.setState({
          ciclos : response.data[0],
          showModalEditCiclo: true,
          ruti_deno : regis.ruti_deno,
          ruti_id : regis.ruti_id,
          respError: '',
          mensajeAlerta: '',
          mensajeColor: 'N'     
          })
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.setState({ fetchModal: false })
      });
  }

  handleModalCicloAlta() {

    this.setState({ 
      fetchModal: false,
      ciclos : [],
      showModalEditCiclo: true,
      ruti_deno : '',
      ruti_id : 0,
      respError: '',
      mensajeAlerta: '',
      mensajeColor: 'N' });   

    this.AgregarCiclo();
  }

  handleChangeCiclo(event,a_ruci_id) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    
  
    var ciclos = this.state.ciclos
    const ind = ciclos.findIndex( cic => cic.ruci_id === a_ruci_id);

    if (name==='ruci_nro_ciclo') {      
      ciclos[ind].ruci_nro_ciclo = value;
    }
    if (name==='ruci_deno') {      
      ciclos[ind].ruci_deno = value;
    }
    if (name==='ruci_semanas') {      
      ciclos[ind].ruci_semanas = value;
    }
    
    this.setState({ ciclos });

  };
  
  AgregarCiclo = () => {

    const max = parseInt(this.state.ciclos.length) + 1

    var ciclos = this.state.ciclos;
    var obj = {}
    obj.canti_ejer = Number(0);
    obj.ruci_deno = `ciclo ${max}`;
    obj.ruci_id = Number(0);
    obj.ruci_nro_ciclo = max;
    obj.ruci_ruti_id = this.state.ruti_id;
    obj.ruci_semanas = Number(1);

    ciclos.push(obj);
    this.setState({ ciclos });
    
  }
  
  handleModalCicloBaja(a_ruci_id, a_deno){
 
    swal({ 
        title : `Eliminar ciclo ${a_deno}`,
        text  : 'Al grabar se elimina definitivamente, confirma?',
        icon : "warning",  
        buttons : ['No','Si'], 
        }).then( respuesta => {
          if ( respuesta===true ) {
              this.setState({ ciclos : this.state.ciclos.filter(function(item)  {
                               return item.ruci_id !== a_ruci_id })
                            });
          }
        });

  }

  handleModalExitCiclo() {
    this.setState({ showModalEditCiclo: false })
    this.setState({
      mensajeAlerta : 'No se aplicaron cambios',
      mensajeColor  : 'red',
    })
  }


  /*==========================================================================
   Actualizacion : Ciclos
  *==========================================================================*/
  handleGrabarCiclos(event) {
    event.preventDefault();
  
    var ciclOrdenado = this.state.ciclos.sort((a,b) => (a.ruci_nro_ciclo > b.ruci_nro_ciclo) ? 1 : ((b.ruci_nro_ciclo > a.ruci_nro_ciclo) ? -1 : 0))

    const sql =  `${URL_DB}AM_PANEL_CICLOS(${this.state.login_admi_id},'${this.state.ruti_id}','${encodeURIComponent(this.state.ruti_deno)}','${String(JSON.stringify(ciclOrdenado))}')`  
    axios.get(sql)
    .then((response) => {
          this.setState({
            respuestaSp: response.data[0]
          })
          var obj = this.state.respuestaSp[0];
          this.setState({
            respError : obj.respuesta
          })

          if (this.state.respError==='OK') {
              this.setState({
                  mensajeAlerta : 'Registrado correctamente',
                  mensajeColor  : 'green',
              }) 
              this.getInicio();
              this.setState({ showModalEditCiclo : false });
          }  
          
      })
      .catch((error) => {
        alert('ERROR interno API al actualizar BD:'+error)
      })
  
  }

  /*==========================================================================
      Modal de EJERCICIOS
  *==========================================================================*/
  handleSelectTab = (e) => {

    this.setState({ tabSelec : e==='dia_1'?Number(1): e==='dia_2'?Number(2):e==='dia_3'?Number(3):Number(4) })

  }

  handleModalEjerModi(regis) {

    this.setState({ fetchModal: true });
    const sql = `${URL_DB}SEL_PANEL_CICLOS_EJERCICIOS('D',${regis.ruti_id},${regis.ruci_id})`
    axios.get(sql)
      .then((response) => { 
        this.setState({
          ejercicios1     : response.data[0].filter(ciej => ciej.ciej_dia === Number(1)),
          ejercicios2     : response.data[0].filter(ciej => ciej.ciej_dia === Number(2)),
          ejercicios3     : response.data[0].filter(ciej => ciej.ciej_dia === Number(3)),
          ejercicios4     : response.data[0].filter(ciej => ciej.ciej_dia === Number(4)),
          showModalEditEjer: true,
          ruti_deno      : regis.ruti_deno,
          ruti_id        : regis.ruti_id,
          ruci_nro_ciclo : regis.ruci_nro_ciclo,
          ruci_id        : regis.ruci_id,
          ruci_deno      : regis.ruci_deno,
          ruci_semanas   : regis.ruci_semanas,
          preview_deno : '',
          preview_file : '',
          respError: '',
          mensajeAlerta: '',
          mensajeColor: 'N'     
          })
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.setState({ fetchModal: false })

        if (this.state.ejercicios1.length ===0) {
            this.AgregarEjer(1)  // Si esta vacio agregamos una fila
        }
        if (this.state.ejercicios2.length ===0) {
            this.AgregarEjer(2)  // Si esta vacio agregamos una fila
        }
        if (this.state.ejercicios3.length ===0) {
            this.AgregarEjer(3)  // Si esta vacio agregamos una fila
        }
        if (this.state.ejercicios4.length ===0) {
          this.AgregarEjer(4)  // Si esta vacio agregamos una fila
        }
      });


      if (this.state.cargaListaEjer) {
        const sql = `${URL_DB}SEL_PANEL_EJERCICIOS('D')`
        axios.get(sql)
          .then((response) => {
            this.setState({
              listaEjer : response.data[0]
              })
          })
          .catch((error) => console.log(error))
          .finally(() => {
            this.setState({ cargaListaEjer: false })
          })
      }
  }

  handleChangeEjer(event,a_row_num) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    
    var ejercicios = [];
    if(this.state.tabSelec===1) { ejercicios = this.state.ejercicios1 }
    if(this.state.tabSelec===2) { ejercicios = this.state.ejercicios2 }
    if(this.state.tabSelec===3) { ejercicios = this.state.ejercicios3 }
    if(this.state.tabSelec===4) { ejercicios = this.state.ejercicios4 }

    const ind = ejercicios.findIndex( ejer => ejer.row_num === a_row_num);

    if (name==='ciej_orden') {      
      ejercicios[ind].ciej_orden = value;
    }
    if (name==='ejer_id') {      
      ejercicios[ind].ejer_id = Number(value); // cambio el ejercicio... cambio la URL
 
      const indLis = this.state.listaEjer.findIndex( item => item.ejer_id === Number(value));
      if (indLis >= 0) {
          ejercicios[ind].ejer_video = this.state.listaEjer[indLis].ejer_video;
          ejercicios[ind].ejer_deno  = this.state.listaEjer[indLis].ejer_deno;
      }
    }
    if (name==='ciej_series') {      
      ejercicios[ind].ciej_series = value;
    }
    if (name==='ciej_repeti') {      
      ejercicios[ind].ciej_repeti = value;
    }
    if (name==='ciej_minu_ejerci') {      
      ejercicios[ind].ciej_minu_ejerci = value;
    }
    if (name==='ciej_minu_descanso') {      
      ejercicios[ind].ciej_minu_descanso = value;
    }
    if (name==='ciej_obser') {      
      ejercicios[ind].ciej_obser = value;
    }

    if(this.state.tabSelec===1) { this.setState({ ejercicios1 : ejercicios }) }
    if(this.state.tabSelec===2) { this.setState({ ejercicios2 : ejercicios }) }
    if(this.state.tabSelec===3) { this.setState({ ejercicios3 : ejercicios }) }
    if(this.state.tabSelec===4) { this.setState({ ejercicios4 : ejercicios }) }

  };
  
  AgregarEjer = (a_nro_dia) =>{

    var max = 0, ejercicios = [];
    if (a_nro_dia === 1) { max = parseInt(this.state.ejercicios1.length) + 1 }
    if (a_nro_dia === 2) { max = parseInt(this.state.ejercicios2.length) + 1 }
    if (a_nro_dia === 3) { max = parseInt(this.state.ejercicios3.length) + 1 }
    if (a_nro_dia === 4) { max = parseInt(this.state.ejercicios4.length) + 1 }

    var obj = {}
    obj.ejer_id = Number(0);
    obj.ciej_dia = Number(a_nro_dia);
    obj.ejer_video = '';
    obj.cant_usua = null;
    obj.ciej_id = Number(0);
    obj.ciej_minu_descanso = Number(0);
    obj.ciej_minu_ejerci = Number(0);
    obj.ciej_obser = '';
    obj.ciej_orden = Number(max)
    obj.ciej_repeti = Number(0);
    obj.ciej_series = Number(1);
    obj.ejer_deno = '';
    obj.row_num = Number(max);

    if (a_nro_dia === 1) { 
        ejercicios = this.state.ejercicios1;
        ejercicios.push(obj);
        this.setState({ ejercicios1 : ejercicios });
    }
    if (a_nro_dia === 2) { 
      ejercicios = this.state.ejercicios2;
      ejercicios.push(obj);
      this.setState({ ejercicios2 : ejercicios });
    }
    if (a_nro_dia === 3) { 
      ejercicios = this.state.ejercicios3;
      ejercicios.push(obj);
      this.setState({ ejercicios3 : ejercicios });
    }
    if (a_nro_dia === 4) { 
      ejercicios = this.state.ejercicios4;
      ejercicios.push(obj);
      this.setState({ ejercicios4 : ejercicios });
    }
    
  }
  
  handleModalEjerBaja(a_row_num, a_deno){
 
    swal({ 
        title : `Eliminar ejercicio ${a_deno}`,
        text  : 'Al grabar se elimina definitivamente, confirma?',
        icon : "warning",  
        buttons : ['No','Si'], 
        }).then( respuesta => {
          if ( respuesta===true ) {

            if(this.state.tabSelec===1) { 
              this.setState({ ejercicios1 : this.state.ejercicios1.filter(function(item)  {
                                                  return item.row_num !== a_row_num })
                            });
            }

            if(this.state.tabSelec===2) { 
              this.setState({ ejercicios2 : this.state.ejercicios2.filter(function(item)  {
                                                  return item.row_num !== a_row_num })
                            });
            }

            if(this.state.tabSelec===3) { 
              this.setState({ ejercicios3 : this.state.ejercicios3.filter(function(item)  {
                                                  return item.row_num !== a_row_num })
                            });
            }

            if(this.state.tabSelec===4) { 
              this.setState({ ejercicios4 : this.state.ejercicios4.filter(function(item)  {
                                                  return item.row_num !== a_row_num })
                            });
            }

          }
        });

  }

  handleModalExitEjer() {
    this.setState({ showModalEditEjer: false })
    this.setState({
      mensajeAlerta : 'No se aplicaron cambios',
      mensajeColor  : 'red',
    })
  }


  /*==========================================================================
    Actualizacion : Ejercicios
  *==========================================================================*/
  handleGrabarEjers(event) {
    event.preventDefault();
  
    const ejercicios = this.state.ejercicios1.concat(this.state.ejercicios2).concat(this.state.ejercicios3).concat(this.state.ejercicios4).filter(item => item.ejer_id > 0)
    var ejerOrdenados = ejercicios.map(function(map) { 
                          var obj = {}  
                          obj.ciej_id       = map.ciej_id
                          obj.ciej_ejer_id  = map.ejer_id
                          obj.ciej_dia      = map.ciej_dia;
                          obj.ciej_orden    = Number(map.ciej_orden);
                          obj.ciej_series   = map.ciej_series;
                          obj.ciej_repeti   = map.ciej_repeti;
                          obj.ciej_minu_ejerci  = map.ciej_minu_ejerci;
                          obj.ciej_minu_descanso = map.ciej_minu_descanso;
                          obj.ciej_obser    = map.ciej_obser;
                        return obj  });
                     //.sort((a,b) => (a.ciej_orden > b.ciej_orden) ? 1 : ((b.ciej_orden > a.ciej_orden) ? -1 : 0))
    const sql =  `${URL_DB}AM_PANEL_CICLO_EJERCICIOS(${this.state.login_admi_id},'${this.state.ruci_id}','${String(JSON.stringify(ejerOrdenados))}')`;
    axios.get(sql)
    .then((response) => {
          this.setState({
            respuestaSp: response.data[0]
          })
          var obj = this.state.respuestaSp[0];
          this.setState({
            respError : obj.respuesta
          })

          if (this.state.respError==='OK') {
              this.setState({
                  mensajeAlerta : 'Registrado correctamente',
                  mensajeColor  : 'green',
              })    
              this.getInicio();
              this.setState({ showModalEditEjer : false });      
          }  
         
      })
      .catch((error) => {
        alert('ERROR interno API al actualizar BD:'+error)
      })

  }
    

  handleChangeSugg_ejer_id(suggestion) {

    // Al hacer click en la Modal toma el nro de fila
    var ejercicios = this.state.tabSelec===1?this.state.ejercicios1:this.state.tabSelec===2?this.state.ejercicios2:this.state.tabSelec===3?this.state.ejercicios3:this.state.ejercicios4
    const ind = ejercicios.findIndex( fil => fil.row_num === this.state.searchRow);
    ejercicios[ind].ejer_id = suggestion.codigo;

    if (this.state.tabSelec===1) { this.setState({ ejercicios1: ejercicios,}); }
    if (this.state.tabSelec===2) { this.setState({ ejercicios2: ejercicios,}); }
    if (this.state.tabSelec===3) { this.setState({ ejercicios3: ejercicios,}); }
    if (this.state.tabSelec===4) { this.setState({ ejercicios4: ejercicios,}); }
    
    this.setState({ showModalSearch: false });

  };

  /*==========================================================================
   RENDER
  *==========================================================================*/

  render() {  

    const respError = this.state.respError;
    const mensajeAlerta = this.state.mensajeAlerta;
    const mensajeColor = this.state.mensajeColor;
    const registrosFiltrados = this.filtrarDatos();

    return(
      <div>        
        {mensajeAlerta.length >1 ? <Notifications mensaje={mensajeAlerta}
                                                  mensajeColor={mensajeColor}
                                    /> : '' }

        <Container  fluid="true">
          <Row>
            <Col xs={6}>
                <h2><b>Armado de rutinas, ciclos con ejercicios</b></h2>
            </Col>
            <Col xs={2}>
            <Form.Group>
                <FormControl type="text" name="buscar" placeholder="Texto a filtrar" className="mr-sm-2" 
                        onChange={(e) => { this.setState({ filterGrilla: e.target.value })} } /> 
              </Form.Group>
            </Col>
            <Col xs={2}>
                <Button variant="primary" size="sm" type="submit" 
                        onClick={this.handleModalCicloAlta} ><i className="fas fa-plus"></i> Rutina
                </Button>
            </Col>
            <Col xs={2}>
            <Button  variant='outline-warning' size='sm' onClick={() => {this.setState({ showhelp : true}) } } > 
             Ayuda
            </Button>
           </Col>
          </Row>

          <Row>
            <Col>
              <Table bordered hover size="sm" id="data_table">
                <thead className="Grilla-header">
                <tr>
                <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ruti_id')}>
                      #Rut</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ruti_deno')}>
                      Rutina</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ciclo')}>
                      #ciclo</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ruci_deno')}>
                      Ciclo</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ciej_dia')}>
                      #Dia</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ruci_semanas')}>
                      Semanas</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ruci_semanas')}>
                      #Ejer</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ejer_deno')}>
                      Ejercicio</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('impleme')}>
                      Implement</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ejer_video')}>
                      Video</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('cant_usuarios')}>
                      #Usuarios</Button>
                  </th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.fetchingregistros && 'Cargando...'
                }
                {
                  registrosFiltrados.map((regis, ind) => {
                    return (
                      <tr key={ind}  style={{ backgroundColor: regis.color }}>
                        <td style={{ backgroundColor: regis.color_rut }} >{regis.ruti_id}</td>
                        <td style={{textAlign: "left", backgroundColor: regis.color_rut}}>{regis.ruti_deno}</td>
                        <td style={{textAlign: "center"}}>{regis.ruci_nro_ciclo}</td>
                        <td style={{textAlign: "left"}}>{regis.ruci_deno}</td>
                        <td style={{textAlign: "center"}}>{regis.ciej_dia}</td>
                        <td style={{textAlign: "center"}}>{regis.ruci_semanas}</td>
                        <td style={{textAlign: "center", backgroundColor: regis.color_rut}}>{regis.ejer_id}</td>
                        <td style={{textAlign: "left", backgroundColor:regis.ejer_color}}>{regis.ejer_deno}</td>
                        <td>{regis.impleme}</td>
                        <td style={{textAlign: "left"}}>{regis.ejer_video}</td>
                        <td style={{textAlign: "center"}}>{regis.cant_usuarios}</td>
                        <td>
                  {regis.es_pri==='S' &&
                    <React.Fragment>
                          <Button variant="primary" size="sm" 
                                  onClick={() => this.handleModalCicloModi(regis)}>
                                  <i className="fas fa-pen"></i> ciclo
                          </Button> 
                          <Button variant="success" size="sm" 
                                  onClick={() => this.handleModalEjerModi(regis)}>
                                  <i className="fas fa-plus"></i> Ejerc
                          </Button>
                    </React.Fragment>
                  }
                        </td>
                      </tr>  
                    ) 
                  }) 
                }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>






        <Modal show={this.state.showModalEditCiclo} onHide={this.handleModalExitCiclo} dialogClassName="modal-60" >
          <Modal.Header closeButton>
            <Modal.Title>
            {this.state.ruti_id ===0 ? 'Crear una Rutina y sus Ciclos' : `Editar Rutina ID# ${this.state.ruti_id}` }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
                <Row>
                  <Col xs={8}>
                    <Form.Group>
                      <Form.Label>Nombre de la rutina</Form.Label>
                      <FormControl type="text" name="ruti_deno" value={this.state.ruti_deno}
                                    placeholder="Defina le nombre de la rutina" 
                                    onChange={(e) => {this.handleChangeCiclo(e,0)}} /> 
                    </Form.Group>
                    </Col>
                </Row>
                <Row>
                  <br />
                </Row>
                <Row>
                  <Col>
                    <Table bordered hover size="sm" id="data_table">
                      <thead className="Grilla-header">
                      <tr>
                        <th>#ID</th>
                        <th>Orden</th>
                        <th>Denominación</th>
                        <th>#Semanas</th>
                        <th>#Ejer</th>
                        <th>Acciones</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        this.state.fetchModal && 'Cargando...'
                      }
                      {
                        this.state.ciclos.map((regis,ind) => {
                          return (
                            <tr key={ind}  style={{ backgroundColor: regis.color }}>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  {regis.ruci_id}</td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="number"  name="ruci_nro_ciclo" 
                                                      value={regis.ruci_nro_ciclo}
                                                      onChange={(e) => {this.handleChangeCiclo(e,regis.ruci_id)}} /> 
                              </td>
                              <td><FormControl type="text"  name="ruci_deno" 
                                                      value={regis.ruci_deno}
                                                      onChange={(e) => {this.handleChangeCiclo(e,regis.ruci_id)}} /> 
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="number"  name="ruci_semanas" 
                                                      value={regis.ruci_semanas}
                                                      onChange={(e) => {this.handleChangeCiclo(e,regis.ruci_id)}} /> 
                              </td>
                              <td style={{textAlign: "center"}}>{regis.canti_ejer}</td>
                              <td>
                                <Button variant="danger" size="sm" 
                                        onClick={() => this.handleModalCicloBaja(regis.ruci_id, regis.ruci_deno)}>
                                        <i className="fas fa-trash-alt"></i> Borrar
                                </Button>
                              </td>
                            </tr>  
                          ) 
                        }) 
                      }  
                      </tbody>
                    </Table>
                  </Col>  
                </Row>
                <Row>
                <Col xs={4}>
                    <Button variant="primary" size="sm" 
                                        onClick={this.AgregarCiclo}>
                                        <i className="fas fa-plus"></i> ciclo a esta rutina
                    </Button>
                    </Col>
                </Row>
                <Row>
                  <br />
                </Row>
                <Row>
                  <Col xs={4}>
                    <Button variant="success" size="sm" type="submit" onClick={this.handleGrabarCiclos}>
                        Grabar
                    </Button>
                    <Button variant="secondary" size="sm" onClick={this.handleModalExitCiclo}>
                        Cancelar
                    </Button>
                    </Col>
                    <Col xs={6}>
                    <Alert key="1" variant="danger" show={respError.length >3 ? true : false} >
                      {respError}
                    </Alert>
                  </Col>
                </Row>

            </Form>
          </Modal.Body>
        </Modal>







    
        <Modal show={this.state.showModalEditEjer} onHide={this.handleModalExitEjer} dialogClassName="modal-90" >
          <Modal.Header closeButton>
            <Modal.Title>
              {`Ejercicios en ciclo #${this.state.ruci_nro_ciclo} : ${this.state.ruci_deno}  de rutina #${this.state.ruti_id}  : ${this.state.ruti_deno}` }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              
                {/* ======================  Tabs de dias   ====================== */}
                <Row>
                  <Tabs defaultActiveKey="dia_1" id="uncontrolled-tab-example" className="mb-3" onSelect={this.handleSelectTab}>
                  {/* ********************* DIA 1 ********************* */}
                  <Tab eventKey="dia_1" title="Dia 1" >
                    
                  <Table bordered hover size="sm" id="data_table">
                      <thead className="Grilla-header">
                      <tr>
                        <th>#</th>
                        <th>Orden</th>
                        <th colSpan="2">Ejercicio</th>
                        <th>Cant Series</th>
                        <th>Cant Repet</th>
                        <th>Min Ejerc</th>
                        <th>Min Descanso</th>
                        <th>Obser public</th>
                        <th>Cant Usuar</th>
                        <th>Acciones</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        this.state.fetchModal && 'Cargando...'
                      }
                      {
                        this.state.ejercicios1.map((regis) => {
                          return (
                            <tr key={regis.row_num}>
                              <td style={{textAlign: "center", backgroundColor : "#343A40", color : "#ffffff"}}>
                                  {regis.row_num}</td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="number"  name="ciej_orden" 
                                                      value={regis.ciej_orden}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td style={{textAlign: "left"}}>
                                  <Form.Group>
                                    <select className="form-control" name="ejer_id" value={ regis.ejer_id } 
                                                          onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} >
                        { regis.ejer_id===0 &&  // Cuando esta vacio ponemos un reg de 'Seleccion' para que haga el change
                                      <option key={0} value={0} disabled >( Seleccione ejercicio )</option>
                        }
                                    { this.state.listaEjer.map((lis) => { 
                                        return (
                                                <option key={lis.ejer_id} value={lis.ejer_id}> 
                                                    {lis.ejer_deno}</option>
                                                ) 
                                      }) }
                                    </select>
                                  </Form.Group>
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <Button variant="secondary" size="sm" 
                                          onClick={() => { this.setState({ showModalSearch:true ,
                                                                           searchRow : regis.row_num
                                                          }) }}>
                                        ...
                                  </Button>
                                
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="text"  name="ciej_series" 
                                                      value={regis.ciej_series}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="number"  name="ciej_repeti" 
                                                      value={regis.ciej_repeti}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="number"  name="ciej_minu_ejerci" 
                                                      value={regis.ciej_minu_ejerci}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="number"  name="ciej_minu_descanso" 
                                                      value={regis.ciej_minu_descanso}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td><FormControl type="text"  name="ciej_obser" 
                                                      value={regis.ciej_obser}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td  style={{textAlign: "center",  width: '70px'}}>
                                  {regis.cant_usua}</td>
                              <td>
                                <Button variant="danger" size="sm" 
                                        onClick={() => this.handleModalEjerBaja(regis.row_num, regis.ejer_deno)}>
                                        <i className="fas fa-trash-alt"></i>
                                </Button>
                                <Button variant="warning" size="sm" 
                                        onClick={() => this.setState({  preview_file : regis.ejer_video,
                                                                        preview_deno : regis.ejer_deno })}>
                                        <i className="fas fa-play-circle"></i> Ver
                                </Button>
                              </td>
                            </tr>  
                          ) 
                        }) 
                      }  
                      </tbody>
                  </Table>

                  </Tab>


                  {/* ********************* DIA 2 ********************* */}
                  <Tab eventKey="dia_2" title="Dia 2" >
          
                  <Table bordered hover size="sm" id="data_table">
                      <thead className="Grilla-header-success">
                      <tr>
                        <th>#</th>
                        <th>Orden</th>
                        <th colSpan="2">Ejercicio</th>
                        <th>Cant Series</th>
                        <th>Cant Repet</th>
                        <th>Min Ejerc</th>
                        <th>Min Descanso</th>
                        <th>Obser public</th>
                        <th>Cant Usuar</th>
                        <th>Acciones</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        this.state.fetchModal && 'Cargando...'
                      }
                      {
                        this.state.ejercicios2.map((regis) => {
                          return (
                            <tr key={regis.row_num}>
                              <td style={{textAlign: "center", backgroundColor : "#28A745", color : "#ffffff"}}>
                                  {regis.row_num}</td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="number"  name="ciej_orden" 
                                                      value={regis.ciej_orden}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td style={{textAlign: "left"}}>
                                  <Form.Group>
                                    <select className="form-control" name="ejer_id" value={ regis.ejer_id } 
                                                          onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} >
                        { regis.ejer_id===0 &&  // Cuando esta vacio ponemos un reg de 'Seleccion' para que haga el change
                                      <option key={0} value={0} disabled >( Seleccione ejercicio )</option>
                        }
                                    { this.state.listaEjer.map((lis) => { 
                                        return (
                                                <option key={lis.ejer_id} value={lis.ejer_id}> 
                                                    {lis.ejer_deno}</option>
                                                ) 
                                      }) }
                                    </select>
                                  </Form.Group>
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <Button variant="secondary" size="sm" 
                                          onClick={() => { this.setState({ showModalSearch:true ,
                                                                           searchRow : regis.row_num
                                                          }) }}>
                                        ...
                                  </Button>
                                
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="text"  name="ciej_series" 
                                                      value={regis.ciej_series}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="number"  name="ciej_repeti" 
                                                      value={regis.ciej_repeti}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="number"  name="ciej_minu_ejerci" 
                                                      value={regis.ciej_minu_ejerci}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="number"  name="ciej_minu_descanso" 
                                                      value={regis.ciej_minu_descanso}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td><FormControl type="text"  name="ciej_obser" 
                                                      value={regis.ciej_obser}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td  style={{textAlign: "center",  width: '70px'}}>
                                  {regis.cant_usua}</td>
                              <td>
                                <Button variant="danger" size="sm" 
                                        onClick={() => this.handleModalEjerBaja(regis.row_num, regis.ejer_deno)}>
                                        <i className="fas fa-trash-alt"></i>
                                </Button>
                                <Button variant="warning" size="sm" 
                                        onClick={() => this.setState({  preview_file : regis.ejer_video,
                                                                        preview_deno : regis.ejer_deno })}>
                                        <i className="fas fa-play-circle"></i> Ver
                                </Button>
                              </td>
                            </tr>  
                          ) 
                        }) 
                      }  
                      </tbody>
                    </Table>
                  
                  </Tab>
                  {/* ********************* DIA 3 ********************* */}
                  <Tab eventKey="dia_3" title="Dia 3">                    
                  
                  <Table bordered hover size="sm" id="data_table">
                      <thead className="Grilla-header-danger">
                      <tr>
                        <th>#</th>
                        <th>Orden</th>
                        <th colSpan="2">Ejercicio</th>
                        <th>Cant Series</th>
                        <th>Cant Repet</th>
                        <th>Min Ejerc</th>
                        <th>Min Descanso</th>
                        <th>Obser public</th>
                        <th>Cant Usuar</th>
                        <th>Acciones</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        this.state.fetchModal && 'Cargando...'
                      }
                      {
                        this.state.ejercicios3.map((regis) => {
                          return (
                            <tr key={regis.row_num}>
                              <td style={{textAlign: "center", backgroundColor : "#DC3545", color : "#ffffff"}}>
                                  {regis.row_num}</td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="number"  name="ciej_orden" 
                                                      value={regis.ciej_orden}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td style={{textAlign: "left"}}>
                                  <Form.Group>
                                    <select className="form-control" name="ejer_id" value={ regis.ejer_id } 
                                                          onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} >
                        { regis.ejer_id===0 &&  // Cuando esta vacio ponemos un reg de 'Seleccion' para que haga el change
                                      <option key={0} value={0} disabled >( Seleccione ejercicio )</option>
                        }
                                    { this.state.listaEjer.map((lis) => { 
                                        return (
                                                <option key={lis.ejer_id} value={lis.ejer_id}> 
                                                    {lis.ejer_deno}</option>
                                                ) 
                                      }) }
                                    </select>
                                  </Form.Group>
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <Button variant="secondary" size="sm" 
                                          onClick={() => { this.setState({ showModalSearch:true ,
                                                                           searchRow : regis.row_num
                                                          }) }}>
                                        ...
                                  </Button>
                                
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="text"  name="ciej_series" 
                                                      value={regis.ciej_series}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="number"  name="ciej_repeti" 
                                                      value={regis.ciej_repeti}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="number"  name="ciej_minu_ejerci" 
                                                      value={regis.ciej_minu_ejerci}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="number"  name="ciej_minu_descanso" 
                                                      value={regis.ciej_minu_descanso}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td><FormControl type="text"  name="ciej_obser" 
                                                      value={regis.ciej_obser}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td  style={{textAlign: "center",  width: '70px'}}>
                                  {regis.cant_usua}</td>
                              <td>
                                <Button variant="danger" size="sm" 
                                        onClick={() => this.handleModalEjerBaja(regis.row_num, regis.ejer_deno)}>
                                        <i className="fas fa-trash-alt"></i>
                                </Button>
                                <Button variant="warning" size="sm" 
                                        onClick={() => this.setState({  preview_file : regis.ejer_video,
                                                                        preview_deno : regis.ejer_deno })}>
                                        <i className="fas fa-play-circle"></i> Ver
                                </Button>
                              </td>
                            </tr>  
                          ) 
                        }) 
                      }  
                      </tbody>
                    </Table>
                  </Tab>

                  {/* ********************* DIA 4 ********************* */}
                  <Tab eventKey="dia_4" title="Dia 4">                    
                  
                  <Table bordered hover size="sm" id="data_table">
                      <thead className="Grilla-header-warning">
                      <tr>
                        <th>#</th>
                        <th>Orden</th>
                        <th colSpan="2">Ejercicio</th>
                        <th>Cant Series</th>
                        <th>Cant Repet</th>
                        <th>Min Ejerc</th>
                        <th>Min Descanso</th>
                        <th>Obser public</th>
                        <th>Cant Usuar</th>
                        <th>Acciones</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        this.state.fetchModal && 'Cargando...'
                      }
                      {
                        this.state.ejercicios4.map((regis) => {
                          return (
                            <tr key={regis.row_num}>
                              <td style={{textAlign: "center", backgroundColor : "#ffc107", color : "#ffffff"}}>
                                  {regis.row_num}</td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="number"  name="ciej_orden" 
                                                      value={regis.ciej_orden}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td style={{textAlign: "left"}}>
                                  <Form.Group>
                                    <select className="form-control" name="ejer_id" value={ regis.ejer_id } 
                                                          onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} >
                        { regis.ejer_id===0 &&  // Cuando esta vacio ponemos un reg de 'Seleccion' para que haga el change
                                      <option key={0} value={0} disabled >( Seleccione ejercicio )</option>
                        }
                                    { this.state.listaEjer.map((lis) => { 
                                        return (
                                                <option key={lis.ejer_id} value={lis.ejer_id}> 
                                                    {lis.ejer_deno}</option>
                                                ) 
                                      }) }
                                    </select>
                                  </Form.Group>
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <Button variant="secondary" size="sm" 
                                          onClick={() => { this.setState({ showModalSearch:true ,
                                                                           searchRow : regis.row_num
                                                          }) }}>
                                        ...
                                  </Button>
                                
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="text"  name="ciej_series" 
                                                      value={regis.ciej_series}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="number"  name="ciej_repeti" 
                                                      value={regis.ciej_repeti}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="number"  name="ciej_minu_ejerci" 
                                                      value={regis.ciej_minu_ejerci}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td style={{textAlign: "center",  width: '70px'}}>
                                  <FormControl type="number"  name="ciej_minu_descanso" 
                                                      value={regis.ciej_minu_descanso}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td><FormControl type="text"  name="ciej_obser" 
                                                      value={regis.ciej_obser}
                                                      onChange={(e) => {this.handleChangeEjer(e,regis.row_num)}} /> 
                              </td>
                              <td  style={{textAlign: "center",  width: '70px'}}>
                                  {regis.cant_usua}</td>
                              <td>
                                <Button variant="danger" size="sm" 
                                        onClick={() => this.handleModalEjerBaja(regis.row_num, regis.ejer_deno)}>
                                        <i className="fas fa-trash-alt"></i>
                                </Button>
                                <Button variant="warning" size="sm" 
                                        onClick={() => this.setState({  preview_file : regis.ejer_video,
                                                                        preview_deno : regis.ejer_deno })}>
                                        <i className="fas fa-play-circle"></i> Ver
                                </Button>
                              </td>
                            </tr>  
                          ) 
                        }) 
                      }  
                      </tbody>
                    </Table>
                  </Tab>

                  </Tabs>
                </Row>

                {/* ============== Pie: Boton agregar/grabar y cancelar y preview de videos =============== */}
                <Row>
                    <Col xs={4}>

                      {/* ==============  Botones ============== */}
                      <Row>
                        <Col xs={6}>
                          <Button variant="primary" size="sm" 
                                  onClick={() => {this.AgregarEjer(this.state.tabSelec)}}>
                                    <i className="fas fa-plus"></i> Ejercicio a este día/ciclo
                          </Button>
                        </Col>
                        <Col xs={6}>
                          <h2>{` Dia ${this.state.tabSelec}`}</h2>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col>
                          <div style={{textAlign: "left", fontSize: 9}}>
                            <li> Si defino la serie de ejercios en un solo día, hará lo mismo todos los dias EN ESTE CICLO</li>
                            <li> Si defino mas dias, una vez que termine del ultimo, recomienza automáticamente por el primero, de la misma forma que lo hacen los ciclos</li>
                            <li> Puede cambiar el orden de la serie, variando el contenido de 'orden', el sistema luego renumerará </li>
                            <li> Puede uitlizar el boton gris '...' para buscar el video por palabra clave </li>
                            <li> Cant Min y Observ son opcionales, si no las completa no aparecerán </li>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={5}>
                          <Button variant="success" size="sm" type="submit" onClick={this.handleGrabarEjers}>
                              Grabar
                          </Button>
                          <Button variant="secondary" size="sm" onClick={this.handleModalExitEjer}>
                              Cancelar
                          </Button>
                          </Col>
                          <Col xs={7}>
                          <Alert key="1" variant="danger" show={respError.length >3 ? true : false} >
                            {respError}
                          </Alert>
                        </Col>
                      </Row>
                    </Col>

                     {/* ==============  Preview ============== */}

                    <Col xs={8}>
                      <Card>
                          <Card.Body>
                            <Row> 
                              <Col xs={5}>
                                <div className='player-wrapper'>
                                  <ReactPlayer 
                                    width ='240px'
                                    height ='120px'
                                    controls url={this.state.preview_file} />
                                </div>
                              </Col>
                              <Col xs={7}>
                                <Row>
                                  <Col style={{ textAlign: "left", fontWeight: "bold"  }}>
                                  Haga Click en : <i className="fas fa-play-circle"></i> para Ver preview del video
                                  </Col>
                                </Row>
                                <Row>
                                  <Col style={{ textAlign: "left" }}>
                                    Ejercicio: <b>{this.state.preview_deno}</b>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col style={{ textAlign: "left" }}>
                                    Archivo: <b>{this.state.preview_file}</b>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card.Body>
                      </Card>  
                    </Col>
                </Row>

            </Form>
          </Modal.Body>
        </Modal>




        <Modal show={this.state.showModalSearch}  onHide={ () => { this.setState({ showModalSearch:false }) } }  dialogClassName="modal-40">
        <div className="fondo-secondary" >
          <Modal.Header closeButton>
            <Modal.Title>Buscar por palabras clave</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          
            <Form>
                <Row>
                    <Form.Group>
                      <Form.Label>Complete para buscar, y seleccione item</Form.Label>
                      <AutosuggestComponent tabla={'ejer_id'}
                                            placeholder={'Complete nombre ejercicio o palabra clave'} 
                                            denoValue={this.state.search_ejer_deno}
                                            onSubmitFunction={this.handleChangeSugg_ejer_id}
                      />  
                    </Form.Group>
                </Row>
            </Form>
            
          </Modal.Body>
          </div>
        </Modal>

        < Modal show={this.state.showhelp} onHide={() => { this.setState({ showhelp : false}) } } dialogClassName="modal-90">
            <AyudaAdmi clave={'config-rutina'}/>
        </Modal>

      </div>
    );
  }
}

export default PanelRutinasPage;
