import React  from 'react';
import { URL_DB, NOMBRE_SIST } from './../../constants';
import axios from 'axios';
import { Row, Col, Container, Form, Table, Card, Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import Notifications from '../../components/Notifications';
import '../../pages/stylePages.css';

class FichaPersona extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      p_pers_id : props.pers_id,

      registros: [],
      ciclos: [],
      rutina:[],
      rutinaCiclo:[],
      pers_apellido : '',
      pers_nombre : '',
      pers_naci_f : '',
      edad : 0,
      pers_docum : '', 
      pers_sexo : '',
      pers_tall_id : '',
      pers_acti_id : '',
      acti_show_depo : '',
      pers_ttip_id : '',
      ttip_especifica : '',
      pers_tcon_id : '',
      pers_tteu_id : '',
      pers_ingre : '',
      pers_egre : '',
      pers_dia_dom : '',
      pers_dia_lun : '',
      pers_dia_mar : '',
      pers_dia_mie : '',
      pers_dia_jue : '',
      pers_dia_vie : '',
      pers_dia_sab : '',
      pers_ergo_canti : 0,
      pers_ergo_color: '',
      pers_ergo_start : '',
      deportes: '',
      rutinaNombre :'',
      preview_file : '',
      preview_deno : '',
      ruci_id_actual : '',
      dia_ciclo_actual: '',

      fetchRegistros: false,
      fetchCiclos:false,
      fetchRutina:false,
      paramVisible: true,
      respuestaSp: [],
      respError: '',
      mensajeAlerta: '',
    };
    
    this.getInicio       = this.getInicio.bind(this);
    this.getDifeHoras    = this.getDifeHoras.bind(this);

  }

  async getPersona() {
    this.setState({ fetchCiclos: true });
    const sql = `${URL_DB}SEL_PERSONA_FULL('D',${this.state.p_pers_id})`

    axios.get(sql)
      .then((response) => {
        this.setState({
          pers_apellido : response.data[0][0].pers_apellido,
          pers_nombre : response.data[0][0].pers_nombre,
          pers_naci_f : response.data[0][0].pers_naci_f,
          edad : response.data[0][0].edad,
          pers_docum : response.data[0][0].pers_docum, 
          pers_sexo : response.data[0][0].pers_sexo,
          pers_tall_id : response.data[0][0].pers_tall_id,
          pers_acti_id : response.data[0][0].pers_acti_id,
          acti_show_depo : response.data[0][0].acti_show_depo,
          pers_ttip_id : response.data[0][0].pers_ttip_id,
          ttip_deno : response.data[0][0].ttip_deno,
          ttip_especifica : response.data[0][0].ttip_especifica,
          ttip_especifica_unidad : response.data[0][0].ttip_especifica_unidad,
          pers_tteu_id : response.data[0][0].pers_tteu_id,
          pers_tcon_id  : response.data[0][0].pers_tcon_id,
          pers_ingre : response.data[0][0].pers_ingre,
          pers_egre : response.data[0][0].pers_egre,
          pers_dia_dom : response.data[0][0].pers_dia_dom,
          pers_dia_lun : response.data[0][0].pers_dia_lun,
          pers_dia_mar : response.data[0][0].pers_dia_mar,
          pers_dia_mie : response.data[0][0].pers_dia_mie,
          pers_dia_jue : response.data[0][0].pers_dia_jue,
          pers_dia_vie : response.data[0][0].pers_dia_vie,
          pers_dia_sab : response.data[0][0].pers_dia_sab,
          pers_ergo_canti : response.data[0][0].pers_ergo_canti,
          pers_ergo_color : '',
          pers_ergo_start : response.data[0][0].pers_ergo_start,      
          pers_puesto : response.data[0][0].pers_puesto, 
          pers_ini_ciclo_f : response.data[0][0].pers_ini_ciclo_f, 
          sexo_deno : response.data[0][0].sexo_deno,
          sexo_color : response.data[0][0].sexo_color,
          tall_deno : response.data[0][0].tall_deno,
          tcon_deno : response.data[0][0].tcon_deno,
          tteu_deno : response.data[0][0].tteu_deno,
          alca_deno : response.data[0][0].alca_deno,
          acti_deno : response.data[0][0].acti_deno,
          alca_color: response.data[0][0].alca_color,
          pevi_ult_sema: response.data[0][0].pevi_ult_sema,
          pevi_ult_mes : response.data[0][0].pevi_ult_mes,
          pevi_total   : response.data[0][0].pevi_total,
          pers_naci_dsp: response.data[0][0].pers_naci_dsp
          });
        this.setState({
          cartelHoras : this.getDifeHoras(this.state.pers_ingre, this.state.pers_egre),
          });
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.setState({ fetchRegistros: false })
      })
    }

  async getDeportes() {
    const sql =  `${URL_DB}SEL_PERSONA_DEPORTE_GRILLA(${this.state.p_pers_id})`
    axios.get(sql)
      .then((response) => {
        this.setState({
          deportes: response.data[0].filter(elem => elem.si_no ==='S').map(elem => elem.depo_deno).toString().replaceAll(',',', ')
        })
      })
      .catch((error) => console.log(error))
  }

  async getRutina() {
    const sql =  `${URL_DB}SEL_PERSONA_RUTINA('D',${this.state.p_pers_id})`
    axios.get(sql)
      .then((response) => {
          this.setState({
            rutina: response.data[0].filter( elem => elem.deta_ciclos === 'D'),
            ciclos: response.data[0].filter( elem => elem.deta_ciclos === 'C')
          }) 
          if (this.state.rutina.length > 0) {
                this.setState({ 
                              rutinaNombre : `${this.state.rutina[0].ruti_deno} (#${this.state.rutina[0].ruti_id})`,
                              inicio_rutina_f : this.state.rutina[0].inicio_rutina_f,
                              apenom : this.state.rutina[0].apenom,
                              ruci_id_actual : this.state.rutina[0].ruci_id_actual,
                              dia_ciclo_actual : this.state.rutina[0].dia_ciclo_actual,
                              rutinaCiclo : this.state.rutina.filter(elem => 
                                                                    elem.ruci_id === Number( this.state.rutina[0].ruci_id_actual)
                                                                && elem.ciej_dia === Number( this.state.rutina[0].dia_ciclo_actual) ),
                              })
          } else {
            this.setState({ rutinaNombre : '(Sin rutina asignada)',
                            inicio_rutina_f :'' })
          }
  
        })
      .catch((error) => console.log(error))
  }

  async getInicio() {
    try{
      await Promise.all([ this.getPersona(), this.getDeportes(), this.getRutina() ])
    } catch(e) {
      console.log(e);
    } finally {
      this.setState({ fetchRegistros: false })
    }
  }


  componentDidMount() {
    this.getInicio() 
  }

  getDifeHoras(p_ingre, p_egre) {
  
    //Normalmente como por ejemplo entre a las 10 y sale a las 18
    var day1 = new Date(`2000-01-01T${p_ingre}:00`);
    var day2 = new Date(`2000-01-01T${p_egre}:00`);

    //Si  por ejemplo entre a las 22 y sale a las 6 del otro dia
    if (p_ingre > p_egre) {
        day2 = new Date(`2000-01-02T${p_egre}:00`);
    } 
    var difeMin = (day2.getTime()-day1.getTime()) / 60 / 1000;
    var fracMin = parseInt(difeMin%60)===0?'':`:${parseInt(difeMin%60)}`
   
    return  `${parseInt(difeMin/60)}${fracMin}`;
  }

  /*==========================================================================
   RENDER
  *==========================================================================*/

  render() {  

    //const respError = this.state.respError;
    const mensajeAlerta = this.state.mensajeAlerta;
    const mensajeColor = this.state.mensajeColor;

    return(
      <div style={this.state.paramVisible ? {} : { display: 'none' }}>        
        {mensajeAlerta.length >1 ? <Notifications mensaje={mensajeAlerta}
                                                  mensajeColor={mensajeColor}
                                    /> : '' }

        <Container >
        <Form>

          <Row>
            <Col md={3} sm={12}>
              <Row>

                <Card>
                <Card.Header>
                  <Row>
                    <Col>
                        <b>{`${this.state.pers_nombre} ${this.state.pers_apellido}`}</b>
                    </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <b>{`${this.state.sexo_deno} ${this.state.edad} años `}</b>
                      {`(${this.state.pers_naci_dsp}) Talla:`}
                      <b>{this.state.tall_deno}</b>
                      <p>{`#Doc: ${this.state.pers_docum}`}</p>
                  </Col>
                  </Row>
                  <Row>
                    <Col>
                      {this.state.acti_deno}{` ${this.state.deportes}`}  
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Row>
            <Row>
              <Card>
            
              <Card.Body>
                <Row>
                  <Col>
                    {`Trab: `}
                    <b>{this.state.ttip_deno}</b>
                  </Col>
                </Row>
                <Row>
                  <Col>  
            { this.state.ttip_especifica_unidad !== 'N' &&
                    <b>{`${this.state.ttip_especifica} ${this.state.tteu_deno} `}</b> 
            }
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {`Contrato: ${this.state.tcon_deno} Puesto:${this.state.pers_puesto}`}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {`Horario: `}
                    <b>{this.state.pers_ingre}</b>
                    {` a `}
                    <b>{this.state.pers_egre}</b>
                    {` = ${this.state.cartelHoras}Hs`}
                  </Col>
                </Row>
                <Row>
                  <Col>
             
                    <table style={{backgroundColor: '#f7f7f7'}}  border>
                    <thead />
                    <tbody>
                      <tr key={1} style={{textAlign: "center", fontSize: 10}}>
                          <td>Dom</td>
                          <td>Lun</td>
                          <td>Mar</td>
                          <td>Mie</td>
                          <td>Jue</td>
                          <td>Vie</td>
                          <td>Sab</td>
                      </tr>
                      <tr key={2}>
                        <td style={{textAlign: "center"}}>
                          <Form.Check type={'checkbox'} name="pers_dia_dom" 
                              checked={ this.state.pers_dia_dom==='N' ? false : true }  readOnly />
                        </td>
                        <td style={{textAlign: "center"}}>
                          <Form.Check type={'checkbox'} name="pers_dia_lun" 
                              checked={ this.state.pers_dia_lun==='N' ? false : true } readOnly />
                        </td>
                        <td style={{textAlign: "center"}}>
                          <Form.Check type={'checkbox'} name="pers_dia_mar" 
                              checked={ this.state.pers_dia_mar==='N' ? false : true }  readOnly />
                        </td>
                        <td style={{textAlign: "center"}}>
                          <Form.Check type={'checkbox'} name="pers_dia_mie" 
                              checked={ this.state.pers_dia_mie==='N' ? false : true }  readOnly />
                        </td>   
                        <td style={{textAlign: "center"}}>
                          <Form.Check type={'checkbox'} name="pers_dia_jue" 
                              checked={ this.state.pers_dia_jue==='N' ? false : true }  readOnly />
                        </td>
                        <td style={{textAlign: "center"}}>
                          <Form.Check type={'checkbox'} name="pers_dia_vie" 
                              checked={ this.state.pers_dia_vie==='N' ? false : true }  readOnly />
                        </td>
                        <td style={{textAlign: "center"}}>
                          <Form.Check type={'checkbox'} name="pers_dia_sab" 
                              checked={ this.state.pers_dia_sab==='N' ? false : true }  readOnly />
                        </td>                       
                      </tr>  
                    </tbody>
                    </table>  
      
                  </Col>
                  </Row>
              </Card.Body>
            </Card>
            </Row>
            </Col>



            <Col md={7} sm={12}>
              <Card>
              <Card.Header><b>{this.state.rutinaNombre}</b>{` inicio:${this.state.inicio_rutina_f}`}</Card.Header>
                <Card.Body className="barra_v_modal">
                
       
                  <Row>
                  <Col>
                  <Table striped bordered hover size="sm" id="data_table">
                  <thead />
                  <tbody>
                  {
                  this.state.fetchRutina && 'Cargando...'
                  }
                  {
                    this.state.rutinaCiclo.map((elem) => {
                      return (
                        <tr key={elem.row_num}>
                          <td>
                            <div>
                            <Row>
                                <Col xs={10}>
                                    <Row>
                                      <Col><b>{elem.ejer_deno}</b></Col>
                                    </Row>
                                    <Row style={{ textAlign: "left", fontSize: 10 }}>
                                      <Col>{elem.ejer_obser}</Col>
                                    </Row>
                                </Col>
                                <Col xs={2}>
                                  <Button variant="warning" size="sm" 
                                          onClick={() => this.setState({  preview_file : elem.ejer_video,
                                                                          preview_deno : elem.ejer_deno })}>
                                          <i className="fas fa-play-circle"></i>{` Ver`}
                                  </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>{` ${elem.implementa} ${elem.ciej_obser}`}</Col>
                            </Row>
                            </div>
                          </td>  
                        </tr>  
                      ) 
                    }) 
                  }
                  </tbody>
                  </Table>
                </Col>
                </Row>

                <Row>
                <Col>
                  <Table>
                      <thead className="Grilla-header">
                      <tr>
                        <th>Actual</th>
                        <th>Ciclo</th>
                        <th>Lapso</th>
                        <th>Desde</th>
                        <th>Hasta</th>
                        <th colSpan={4}>Pulsar Dia p/ traer</th>
                      </tr>
                    </thead>  
                    <tbody>
                      { 
                        this.state.ciclos.map((cic) => {
                          return (
                          <tr  key={cic.tmp_ruci_id}>    
                            <td><b>{cic.tmp_ciclo_actual==='S' && 'X'}</b></td>
                            <td>{cic.tmp_ruci_deno}</td>      
                            <td>{cic.tmp_sema}</td>
                            <td>{cic.tmp_ini_f}</td>
                            <td>{cic.tmp_fin_f}</td>
                            <td>
                        { cic.tmp_dia1==='S' &&   
                                <Button variant={cic.tmp_dia4_color} size="sm"  
                                    onClick={(e) => {
                                      this.setState({ 
                                        preview_file : '',
                                        preview_deno : '',
                                        rutinaCiclo  : this.state.rutina.filter(elem => elem.ruci_id === Number(cic.tmp_ruci_id)),
                                      });
                                    }}>
                                  D1</Button>
                        }
                            </td>
                            <td>
                        { cic.tmp_dia2==='S' &&  
                                <Button variant={cic.tmp_dia4_color} size="sm"  
                                    onClick={(e) => {
                                      this.setState({ 
                                        preview_file : '',
                                        preview_deno : '',
                                        rutinaCiclo  : this.state.rutina.filter(elem => elem.ruci_id === Number(cic.tmp_ruci_id)),
                                      });
                                    }}>
                                  D2</Button>
                        }
                            </td>
                            <td>
                        { cic.tmp_dia3==='S' &&  
                                <Button variant={cic.tmp_dia4_color} size="sm"  
                                    onClick={(e) => {
                                      this.setState({ 
                                        preview_file : '',
                                        preview_deno : '',
                                        rutinaCiclo  : this.state.rutina.filter(elem => elem.ruci_id === Number(cic.tmp_ruci_id)),
                                      });
                                    }}>
                                  D3</Button>
                        }
                            </td>
                            <td>
                        { cic.tmp_dia4==='S' &&  
                                <Button variant={cic.tmp_dia4_color} size="sm"  
                                    onClick={(e) => {
                                      this.setState({ 
                                        preview_file : '',
                                        preview_deno : '',
                                        rutinaCiclo  : this.state.rutina.filter(elem => elem.ruci_id === Number(cic.tmp_ruci_id)),
                                      });
                                    }}>
                                  D4</Button>
                        }
                            </td>
              
                          </tr>        
                          ) 
                        }) 
                      }
                    </tbody>
                  </Table>
                  </Col>
                  </Row>
                  
                </Card.Body>
              </Card>
              </Col>     



              <Col md={2} sm={12}>
              <Row>
                <Card>
                <Card.Header><b>{NOMBRE_SIST}</b></Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                        <Form.Control type="text" name="alca_deno" value={this.state.alca_deno} 
                                        style={{backgroundColor: this.state.alca_color}} readOnly />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    {parseInt(this.state.pers_ergo_canti) > 0?`1° alerta: ${this.state.pers_ergo_start}`:''}
                    </Col>
                  </Row>
                  <Row>
                    <li>{`Ult 7 dias: ${this.state.pevi_ult_sema}`}</li>
                    <li>{`Ult mes: ${this.state.pevi_ult_mes}`}</li>
                    <li>{`En total: ${this.state.pevi_total}`}</li>
                  </Row>
                </Card.Body>
                </Card>
              </Row>
              <Row>
                <Card>
                <Card.Header>Preview Video</Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                    <div className='player-wrapper'>
                                  <ReactPlayer 
                                    width ='180px'
                                    height ='90px'
                                    controls url={this.state.preview_file} />
                                </div>
                    </Col>
                  </Row>
                  <Row>
                      <Col style={{ fontSize: 10}}>
                        {this.state.preview_deno}
                      </Col>  
                  </Row>  
                  <Row>
                      <Col style={{ fontSize: 10}}>
                        {this.state.preview_file}
                      </Col>  
                  </Row>  
                </Card.Body>
                </Card>
              </Row>
            </Col>



          </Row>
        </Form>
         
        </Container>


      </div>
    );
  }
}

export default FichaPersona;
