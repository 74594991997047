import React from 'react';
import { Toast  } from 'react-bootstrap';
import { URL_DB, NOMBRE_SIST } from './../../constants';
import axios from 'axios';

class Ayuda extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      clave : props.clave, 
      ayud_texto: '',
      ayud_titulo: '',
      renglones:[],
    }; 
  }

  componentDidMount() {

    const sql = `${URL_DB}SEL_AYUDA('${this.state.clave}')`
    axios.get(sql)
      .then((response) => {

        var ayud_texto = '', texto = [], lineas = [], i;
        // traduzco el nombre de la App
        ayud_texto =  response.data[0][0].ayud_texto.replaceAll('&NOMBRE_SIST&',`${ NOMBRE_SIST }`);
        // corto los new line del texto para convertior en parrafos
        texto = ayud_texto.split('<br>');
        for( i=0; i<texto.length; i++) {
          var obj = {};
          obj.deno = texto[i];
          lineas.push(obj);
        }
   
        this.setState({
          ayud_titulo: response.data[0][0].ayud_titulo,
          renglones: lineas,
        }) 
     })
      .catch((error) => console.log(error));
  }

  render() {    

      let locShowtoast = this.props.showtoast;
      const { handleShowToast } = this.props;

      return (
        <>
          <Toast show={locShowtoast} 
                  style={{ width: 700 }}
                  onClose={handleShowToast}>
            <Toast.Header>
              <strong >{this.state.ayud_titulo}</strong>
            </Toast.Header>
            <Toast.Body  style={{ backgroundColor: '#ffff99', textAlign:'left' }}>
            <thead />
            <tbody>
            {
                this.state.renglones.map((regis, ind) => {
                  return (
                    <tr key={ind}>
                    <td>{regis.deno}</td>
                  </tr>
                  ) 
                }) 
                
          }
          </tbody>
     {/*      <Button variant="secondary" size="sm" onClick={this.noVolverMostrar}>
          No volver a mostrar
        </Button> */}

            </Toast.Body>
          </Toast>
        </>
      )  
  }
}

export default Ayuda;

