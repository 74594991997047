import React  from 'react';
import { URL_DB } from './../../constants';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { Table, Row, Col, Container, Button, Modal, Form, Alert, FormControl, Card } from 'react-bootstrap';
//import ExpGrillaExcelPDF from '../../components/ExpGrillaExcelPDF';
import AyudaAdmi from '../../components/Ayuda-admi';
import Notifications from '../../components/Notifications';
import ordenarGrilla from './../../utils/functions/ordenar-grilla'
import '../stylePages.css';


class PanelEjerciciosPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      registros : [],
      ejercicios : [],
      ListaEjer: [],
      buscarGrillaValue: '',
      showModalEdit: false,
      cargaListaEjer: true,
      login_admi_id: sessionStorage.getItem('USU_ADMI_ID'),
      ejer_id : '',
      ejer_deno : '',
      ejer_obser : '',
      ejer_video : '',
      ejer_palabras_clave: '',
      archivo : '',
      input_sube_file: '',
      showhelp : false,
      filterGrilla: '',
      fetchingregistros: false,
      respuestaSp: [],
      respError: '',
      mensajeAlerta: '',

      selectedOption: '',
    };

    this.ordenarGrilla   = this.ordenarGrilla.bind(this);
  

    this.getPanelRutinas      = this.getPanelRutinas.bind(this);
    this.getInicio            = this.getInicio.bind(this);
    this.handleChange         = this.handleChange.bind(this);
    this.handleModalEdit      = this.handleModalEdit.bind(this);
    this.handleModalExit      = this.handleModalExit.bind(this);
    this.handleModalAlta      = this.handleModalAlta.bind(this);
    this.handleGrabarEjer     = this.handleGrabarEjer.bind(this);
  
  }

  async getPanelRutinas() {
    const sql =  `${URL_DB}SEL_PANEL_EJERCICIOS('D')`
    const response = await axios.get(sql)
    try {
      this.setState({ registros: response.data[0] })
    } catch(error) {
      console.log(error)
    }
  }
 
  async getInicio() {
    try{
      await Promise.all([this.getPanelRutinas() ])
    } catch(e) {
      console.log(e);
    }
  }

  componentDidMount() {
    this.getInicio()
  }

  /*==========================================================================
   Utilidades de filtros y sort para la grilla ppal 
  *==========================================================================*/
  filtrarDatos() {
    const escapedValue = this.state.filterGrilla.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedValue, 'i');
    return this.state.registros.filter(filtro => 
      regex.test(filtro.ejer_deno) || 
      regex.test(filtro.ejer_video) || 
      regex.test(filtro.ejer_palabras_clave) || 
      regex.test(filtro.ejer_obser) )
  }
      
  ordenarGrilla = (key) => {
    const registros = ordenarGrilla(key, this.state.registros);

    this.setState({ registros });
  }

  /*==========================================================================
   Abro modal para modificar : Cargo valores y combos
  *==========================================================================*/
  handleModalEdit(regis) {

    this.setState({
      showModalEdit: true,
      ejer_id     : regis.ejer_id,
      ejer_deno   : regis.ejer_deno,
      ejer_obser  : regis.ejer_obser,
      ejer_video  : regis.ejer_video,
      ejer_palabras_clave: regis.ejer_palabras_clave,
      respError: '',
      mensajeAlerta: '',
      mensajeColor: 'N' 
    });

  }
 
  handleChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    
  };
  
  
  handleModalExit() {
    this.setState({ showModalEdit: false })
    this.setState({
      mensajeAlerta : 'No se aplicaron cambios',
      mensajeColor  : 'red',
    })
  }


  handleModalAlta() {

    this.setState({
      showModalEdit: true,
      ejer_id     : 0,
      ejer_deno   : '',
      ejer_obser  : '',
      ejer_video  : '',
      ejer_palabras_clave: '',
      respError: '',
      mensajeAlerta: '',
      mensajeColor: 'N' 
    });

  }
  
  /*==========================================================================
   Actualizacion : Modificacion examen
  *==========================================================================*/
  handleGrabarEjer(event) {
    event.preventDefault();
  
    const sql =  `${URL_DB}AM_EJERCICIO(${this.state.login_admi_id},${this.state.ejer_id},'${encodeURIComponent(this.state.ejer_deno)}',
'${encodeURIComponent(this.state.ejer_obser)}','${encodeURIComponent(this.state.ejer_palabras_clave)}','${String(this.state.ejer_video).replaceAll('/','*').replaceAll('?','|')}')` 
    axios.get(sql)
    .then((response) => {
          this.setState({
            respuestaSp: response.data[0]
          })
          var obj = this.state.respuestaSp[0];
          this.setState({
            respError : obj.respuesta
          })

          if (this.state.respError==='OK') {
              this.setState({
                  mensajeAlerta : 'Registrado correctamente',
                  mensajeColor  : 'green',
                  showModalEdit: false
              })
              this.getInicio();
          }    
      })
      .catch((error) => {
        alert('ERROR interno API al actualizar BD:'+error+' SQL:'+sql)
      })

  }

  /*==========================================================================
   RENDER
  *==========================================================================*/
  render() {  

    const respError = this.state.respError;
    const mensajeAlerta = this.state.mensajeAlerta;
    const mensajeColor = this.state.mensajeColor;
    const registrosFiltrados = this.filtrarDatos();

    return(
      <div>        
        {mensajeAlerta.length >1 ? <Notifications mensaje={mensajeAlerta}
                                                  mensajeColor={mensajeColor}
                                    /> : '' }

        <Container  fluid="true">
          <Row>
            <Col xs={6}>
                <h2><b>Definir Ejercicios</b></h2>
            </Col>
            <Col xs={2}>
            <Form.Group>
                <FormControl type="text" name="buscar" placeholder="Texto a filtrar" className="mr-sm-2" 
                        onChange={(e) => { this.setState({ filterGrilla: e.target.value })} } /> 
              </Form.Group>
            </Col>
            <Col xs={2}>
                <Button variant="primary" size="sm" type="submit" 
                        onClick={this.handleModalAlta} ><i className="fas fa-plus"></i> Ejercicio
                </Button>
            </Col>
            <Col xs={2}>
            <Button  variant='outline-warning' size='sm' onClick={() => {this.setState({ showhelp : true}) } } > 
             Ayuda
            </Button>
           </Col>
          </Row>

          <Row>
            <Col>
              <Table striped bordered hover size="sm" id="data_table">
                <thead className="Grilla-header">
                <tr>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ejer_id')}>
                      #ID</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ejer_deno')}>
                      Denominación</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ejer_video')}>
                      Video</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ejer_palabras_clave')}>
                      Palabras clave</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ejer_obser')}>
                      Observaciones</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('canti_en_ciclos')}>
                      #en Ciclos</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('canti_en_usuarios')}>
                      #en Usu</Button>
                  </th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.fetchingregistros && 'Cargando...'
                }
                {
                  registrosFiltrados.map((regis) => {
                    return (
                      <tr key={regis.ejer_id}>
                        <td style={{textAlign: "center"}}>{regis.ejer_id}</td>
                        <td style={{textAlign: "left"}}>{regis.ejer_deno}</td>
                        <td style={{textAlign: "left"}}>{regis.ejer_video}</td>
                        <td style={{textAlign: "left"}}>{regis.ejer_palabras_clave}</td>
                        <td style={{textAlign: "left"}}>{regis.ejer_obser}</td>
                        <td style={{textAlign: "center"}}>{regis.canti_en_ciclos}</td>
                        <td style={{textAlign: "center"}}>{regis.canti_en_usuarios}</td>
                        <td>
                          <Button variant="primary" size="sm" 
                              onClick={() => this.handleModalEdit(regis)}>
                                <i className="fas fa-pen"></i> Edit</Button>
                        </td>
                      </tr>  
                    ) 
                  }) 
                }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>



        <Modal show={this.state.showModalEdit} onHide={this.handleModalExit} dialogClassName="modal-90" >
          <Modal.Header closeButton>
            <Modal.Title>
            {this.state.ejer_id ===0 ? 'Crear un ejercicio ' : `Editar Ejercicio ID# ${this.state.ejer_id}` }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
                <Row>
                  <Col xs={6}>
                      <Row>
                        <Col>
                        <Form.Group>
                          <Form.Label>Denominación</Form.Label>
                          <Form.Control type="text" name="ejer_deno" value={this.state.ejer_deno} onChange={this.handleChange} />
                          <Form.Text className="text-muted"></Form.Text>
                        </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Label>Archivo de video referenciado (URL ó Archivo)</Form.Label>
                          <Card>
                            <Card.Header>
                                <Form.Control type="text" name="ejer_video" value={this.state.ejer_video} onChange={this.handleChange} />
                                <Form.Text className="text-muted"></Form.Text>
                                </Card.Header>
                            <Card.Body>      
                              <div className='player-wrapper'>
                                <ReactPlayer 
                                  width ='480px'
                                  height ='240px'
                                  controls url={this.state.ejer_video}
                                  onReady = {() => console.log('Onready')} />
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
 
                      </Row>
                  </Col>

                  <Col xs={6}>
                    <Row>
                        <Col>
                            <Form.Group> 
                              <Form.Label>Observ para el usuario, se verá bajo el video</Form.Label>
                              <FormControl as="textarea"  name="ejer_obser"
                                        defaultValue={this.state.ejer_obser} 
                                        placeholder="Este texto salda abajo del video"
                                        rows={3}
                                        onChange={this.handleChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <br />
                            <Form.Group>
                              <Form.Label>Palabras clave (uso interno) para buscar el video</Form.Label>
                              <FormControl as="textarea"  name="ejer_palabras_clave"
                                        defaultValue={this.state.ejer_palabras_clave} 
                                        placeholder="Palabras sueltas para ubicar el video cuando quiera asignarlo"
                                        rows={5}
                                        onChange={this.handleChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <br />
                        <Button variant="success" size="sm" type="submit"  onClick={this.handleGrabarEjer}>
                            Grabar
                        </Button>
                        <Button variant="secondary" size="sm" onClick={this.handleModalExit}>
                            Cancelar
                        </Button>
                        </Col>
                        <Col xs={6}>
                        <Alert key="1" variant="danger" show={respError.length >3 ? true : false} >
                          {respError}
                        </Alert>
                      </Col>
                    </Row>

                  </Col>
                </Row>
            </Form>
          </Modal.Body>
        </Modal>

        < Modal show={this.state.showhelp} onHide={() => { this.setState({ showhelp : false}) } } dialogClassName="modal-90">
            <AyudaAdmi clave={'config-rutina'}/>
        </Modal>

      </div>
    );
  }
}

export default PanelEjerciciosPage;
