import React from 'react';
import { URL_DB, NOMBRE_SIST } from './../../constants';
import axios from 'axios';
import { Table, Row, Col, Container, Button, Modal, Form, Alert, FormControl } from 'react-bootstrap';
import exportToCSV from '../../utils/functions/export-excel';
import { FaDownload } from 'react-icons/fa';
import swal from 'sweetalert';
import Notifications from '../../components/Notifications';
import AyudaAdmi from '../../components/Ayuda-admi';
import ordenarGrilla from './../../utils/functions/ordenar-grilla'
import '../stylePages.css';

class PanelEmpresasPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opciones_id : 'EV',
      estados: [],
      rubros: [],
      motivosbaja: [],
      referenLista:[],
      paises: [],
      registros: [],
      buscarGrillaValue: '',
      showModalEdit: false,
      showModalBorr: false,
      login_admi_id: sessionStorage.getItem('USU_ADMI_ID'),
      empr_id   : '',
      empr_razon: '',
      empr_ests_id: '',
      empr_cant_emple: '',
      empr_cuit: '',
      empr_tele: '',
      empr_mail: '',
      empr_domi: '',
      empr_loca: '',
      empr_pais_id: '',
      empr_obser: '',
      empr_alta_f: '',
      empr_baja_f: '',
      empr_fanta: '',
      empr_erub_id: '',
		  empr_baem_id: '',

      showhelp : false,
      filterGrilla: '',
      fetchingregistros: false,
      respuestaSp: [],
      respError: '',
      mensajeAlerta: '',
    };

    this.ordenarGrilla      = this.ordenarGrilla.bind(this);
    this.poblarGrilla       = this.poblarGrilla.bind(this);
    this.borraRefente       = this.borraRefente.bind(this);
    this.agregaReferente     = this.agregaReferente.bind(this);
    this.handleModalEditEdit = this.handleModalEditEdit.bind(this);
    this.handleModalEditExit = this.handleModalEditExit.bind(this);
    this.handleModalBorrEdit = this.handleModalBorrEdit.bind(this);
    this.handleModalBorrExit = this.handleModalBorrExit.bind(this);
    this.handleEditRegis     = this.handleEditRegis.bind(this);
    this.handleBorrRegis     = this.handleBorrRegis.bind(this);
    this.handleModalAlta     = this.handleModalAlta.bind(this);
    this.handleChange        = this.handleChange.bind(this);
    this.handleChangeRefe    = this.handleChangeRefe.bind(this);
    this.handleChangeOpc     = this.handleChangeOpc.bind(this);
    this.handleModalOpenEdit = this.handleModalOpenEdit.bind(this);
  }

  async getEmpresaRubros() {
    const sql = `${URL_DB} SEL_EMPRESA_RUBROS()`
    axios.get(sql)
      .then((response) => {
        this.setState({
          rubros: response.data[0],
        })
      })
      .catch((error) => console.log(error))
  }

  async getEmpresaBajaMot() {
    const sql = `${URL_DB} SEL_EMPRESA_BAJAMOTIVOS()`
    axios.get(sql)
      .then((response) => {
        this.setState({
          motivosbaja: response.data[0],
        })
      })
      .catch((error) => console.log(error))
  }

  async getPaises() {
    const sql = `${URL_DB} SEL_LOCGEO_PAISES()`
    axios.get(sql)
      .then((response) => {
        this.setState({
          paises: response.data[0],
        })
      })
      .catch((error) => console.log(error))
  }

  async getEmpresaSts() {
    const sql = `${URL_DB} SEL_EMPRESA_STATUS()`
    axios.get(sql)
      .then((response) => {
        this.setState({
          estados: response.data[0],
        })
      })
      .catch((error) => console.log(error))
  }

  async getInicio() {
    try{
      await Promise.all([ this.getEmpresaRubros(), this.getEmpresaBajaMot(), this.getPaises(), this.getEmpresaSts() ])
    } catch(e) {
      console.log(e);
    } finally {
      this.poblarGrilla(this.state.opciones_id);
      this.setState({ fetchRegistros: false });
    }
  }
  
  
  componentDidMount() {
    this.getInicio();
  }

  /*==========================================================================
   Utilidades de filtros y sort para la grilla ppal 
  *==========================================================================*/
  filtrarDatos() {
    const escapedValue = this.state.filterGrilla.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedValue, 'i');
    return this.state.registros.filter(filtro => 
      regex.test(filtro.empr_deno) || 
      regex.test(filtro.ests_deno) ||
      regex.test(filtro.referente) || 
      regex.test(filtro.eref_puesto) || 
      regex.test(filtro.erub_deno) || 
      regex.test(filtro.empr_cuit) ||
      regex.test(filtro.empr_tele) || 
      regex.test(filtro.empr_mail) || 
      regex.test(filtro.empr_domi) || 
      regex.test(filtro.empr_loca) || 
      regex.test(filtro.empr_obser) || 
      regex.test(filtro.baem_deno) )
  }

  ordenarGrilla = (key) => {
    const registros = ordenarGrilla(key, this.state.registros);

    this.setState({ registros });
  }
  
  /*==========================================================================
   Completo datos de grilla principal 
  *==========================================================================*/
  poblarGrilla = (paramOpc) => {

    this.setState({ fetchingregistros: true,
                    opciones_id: paramOpc,
     });
    const sql = `${URL_DB}SEL_PANEL_EMPRESAS ('D','${paramOpc}')`
    axios.get(sql)
      .then((response) => {
        this.setState({
          registros: response.data[0],
        })
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.setState({ fetchingregistros: false })
      })
  }

  /*==========================================================================
   Abro MODAL para Alta y Modificar : Cargo valores y combos
  *==========================================================================*/
  handleModalEditEdit(regis) {
  
    this.setState({ showModalEdit: true })

    const sql =  `${URL_DB}SEL_PANEL_EMPRESA_REFERENTE(${regis.empr_id})`
      axios.get(sql)
      .then((response) => {
        this.setState({
          referenLista: response.data[0],

          empr_id   : regis.empr_id,
          empr_razon: regis.empr_razon,
          empr_cant_emple: regis.empr_cant_emple,
          empr_cuit: regis.empr_cuit,
          empr_tele: regis.empr_tele,
          empr_mail: regis.empr_mail,
          empr_domi: regis.empr_domi,
          empr_pais_id: regis.empr_pais_id,
          empr_obser: regis.empr_obser,
          empr_loca: regis.empr_loca,
          empr_alta_f: regis.empr_alta_f,
          empr_baja_f: regis.empr_baja_f,
          empr_fanta: regis.empr_fanta,
          empr_erub_id: regis.empr_erub_id,
    
          respError: '',
          mensajeAlerta: '',
          mensajeColor: 'N'
        })
      })
      .catch((error) => console.log(error))
  
  }

  handleModalAlta() {
 
    this.setState({
      showModalEdit: true,

      empr_id   : 0,
      empr_razon: '',
      empr_fanta: '',
      empr_ests_id: 1,
      empr_cant_emple: '',
      empr_cuit: '',
      empr_tele: '',
      empr_mail: '',
      empr_domi: '',
      empr_loca: '',
      empr_pais_id: 'AR',
      empr_obser: '',

      respError: '',
      mensajeAlerta: ''
    })
    this.agregaReferente()

  }
  
  handleChangeOpc(event) {
    let paramVal = event.target.value;
    this.poblarGrilla(paramVal)    
  };
  
    
  handleChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    
  };

 /*==========================================================================
   MODAL : filas de la grilla de referentes 
  *==========================================================================*/
  handleChangeRefe(event, a_fila) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    
    var referenLista = this.state.referenLista
    var ind = referenLista.findIndex( elem => parseInt(elem.row_num) === parseInt(a_fila));

    if (name==='eref_apellido') {      
      referenLista[ind].eref_apellido = value;
    }
    if (name==='eref_nombre') {      
      referenLista[ind].eref_nombre = value;
    }
    if (name==='eref_tele') {      
      referenLista[ind].eref_tele = value;
    }
    if (name==='eref_mail') {      
      referenLista[ind].eref_mail = value;
    }
    if (name==='eref_puesto') {      
      referenLista[ind].eref_puesto = value;
    }
    if (name==='eref_obser') {      
      referenLista[ind].eref_obser = value;
    }
    this.setState({ referenLista });
    
  };
  

  agregaReferente(){

    var referenLista = this.state.referenLista;
    var obj = {}
    obj.row_num = Number(referenLista.length);
    obj.eref_id = Number(0);
    obj.eref_apellido = '';
    obj.eref_nombre = '';
    obj.eref_tele = '';
    obj.eref_mail = '';
    obj.eref_puesto = '';
    obj.eref_obser = '';

    referenLista.push(obj);
    this.setState({ referenLista });
    
  }
  
  borraRefente(a_eref_id, a_apenom){
 
    swal({ 
        title : `Eliminar Referente ${a_apenom===''?'(sin nombre)':a_apenom}`,
        text  : 'Al grabar se elimina definitivamente, confirma?',
        icon : "warning",  
        buttons : ['No','Si'], 
        }).then( respuesta => {
          if ( respuesta===true ) {
              this.setState({ referenLista : this.state.referenLista.filter(function(item)  {
                               return item.eref_id !== a_eref_id })
                            });
          }
        });

  }

  
  handleModalEditExit() {
    this.setState({ showModalEdit: false })
    this.setState({
      mensajeAlerta : 'No se aplicaron cambios',
      mensajeColor  : 'red',
    })
  }

  /*==========================================================================
   Abro modal para BAJA : Cargo solo valores 
  *==========================================================================*/
  handleModalBorrEdit(regis) {

    this.setState({
      showModalBorr: true,

      empr_id   : regis.empr_id,
      empr_razon: regis.empr_razon,
      empr_cant_emple: regis.empr_cant_emple,
      empr_cuit: regis.empr_cuit,
      empr_tele: regis.empr_tele,
      empr_mail: regis.empr_mail,
      empr_domi: regis.empr_domi,
      empr_loca: regis.empr_loca,
      empr_pais_id: regis.empr_pais_id,
      empr_obser: regis.empr_obser,
      empr_alta_f: regis.empr_alta_f,
      empr_baja_f: regis.empr_baja_f,
      empr_fanta: regis.empr_fanta,
      erub_deno: regis.erub_deno,
      ests_deno: regis.ests_deno,
      ests_color: regis.ests_color,
      empr_baem_id: 0,

      respError: '',
      mensajeAlerta: ''
    })

  }

  handleModalBorrExit() {
    this.setState({ showModalBorr: false })
    this.setState({
      mensajeAlerta : 'No se aplico baja',
      mensajeColor  : 'red',
    })
  }
  /*==========================================================================
   Actualizacion : reabrir de empresas
  *==========================================================================*/
  handleModalOpenEdit(regis) {

    //empr_id   : regis.empr_id,
    
    swal({ 
      title : `Re-abrir empresa ${regis.empr_deno}`,
      text  : 'Pasa de Baja a Vigente, confirma?',
      icon : "warning",  
      buttons : ['No','Si'], 
      }).then( respuesta => {
        if ( respuesta===true ) {
            
          const sql =  `${URL_DB}B_PANEL_EMPRESA_REABRIR(${this.state.login_admi_id},${regis.empr_id})`  
              axios.get(sql)
              .then((response) => {
                    this.setState({
                      respuestaSp: response.data[0]
                    })
                    var obj = this.state.respuestaSp[0];
                    this.setState({
                      respError : obj.respuesta
                    })
          
                    if (this.state.respError==='OK') {
                        this.setState({
                            mensajeAlerta : 'Registrado correctamente',
                            mensajeColor  : 'green',
                        })          
                    }  
                    this.poblarGrilla(this.state.opciones_id);
                })
                .catch((error) => {
                  alert('ERROR interno API al actualizar BD:'+error)
                });
        }
      });

  }

  /*==========================================================================
   Actualizacion : Modificacion de empresas
  *==========================================================================*/
  handleEditRegis(event) {
    event.preventDefault();
      
    const sql =  `${URL_DB}AM_PANEL_EMPRESA(${this.state.login_admi_id},${this.state.empr_id},'${encodeURIComponent(this.state.empr_razon)}',
'${encodeURIComponent(this.state.empr_fanta)}',${this.state.empr_ests_id},${this.state.empr_erub_id},'${this.state.empr_cuit}',
'${this.state.empr_cant_emple}','${encodeURIComponent(this.state.empr_tele)}','${encodeURIComponent(this.state.empr_mail)}',
'${encodeURIComponent(this.state.empr_domi)}','${encodeURIComponent(this.state.empr_loca)}','${this.state.empr_pais_id}',
'${encodeURIComponent(this.state.empr_obser)}','${String(JSON.stringify(this.state.referenLista))}')` 
    axios.get(sql)
    .then((response) => {
          this.setState({
            respuestaSp: response.data[0]
          })
          var obj = this.state.respuestaSp[0];
          this.setState({
            respError : obj.respuesta
          })

          if (this.state.respError==='OK') {
              this.setState({
                  mensajeAlerta : 'Registrado correctamente',
                  mensajeColor  : 'green',
              })          
          }  
          this.poblarGrilla(this.state.opciones_id);
          this.setState({ showModalEdit : false });
      })
      .catch((error) => {
        alert('ERROR interno API al actualizar BD:'+error)
      });
      
  }

  /*==========================================================================
   Actualizacion : Baja de empresas
  *==========================================================================*/
  handleBorrRegis(event) {
    event.preventDefault();

    if (this.state.empr_baem_id===0) {
        swal({ title : 'No se efectuó baja de empresa',
                text  : 'Seleccione "Motivo de baja"',
                icon : "error",  
                button : "Aceptar",
                timer : "4000" });
   
    } else {
 
      const sql = `${URL_DB}B_PANEL_EMPRESA(${this.state.login_admi_id},${this.state.empr_id},${this.state.empr_baem_id})`
      axios.get(sql)
      .then((response) => {
        this.setState({
          respuestaSp: response.data[0]
        })
        var obj = this.state.respuestaSp[0];
        this.setState({
          respError : obj.respuesta
        })
  
        if (this.state.respError==='OK') {
            this.setState({
                mensajeAlerta : 'Se aplicó baja correctamente',
                mensajeColor  : 'green',
            })
            this.setState({ showModalBorr: false })
            this.poblarGrilla(this.state.opciones_id)
        }    
          
        })
        .catch((error) => {
            alert('ERROR interno API al actualizar BD:'+error)
        })
  
    }

  }

  /*==========================================================================
   RENDER
  *==========================================================================*/

  render() {  

    const respError = this.state.respError;
    const mensajeAlerta = this.state.mensajeAlerta;
    const mensajeColor = this.state.mensajeColor;
    const registrosFiltrados = this.filtrarDatos();

    return(
      <div>        
        {mensajeAlerta.length >1 ? <Notifications mensaje={mensajeAlerta}
                                                  mensajeColor={mensajeColor}
                                    /> : '' }

        <Container  fluid="true">
          <Row>
            <Col xs={3} style={{textAlign: "right" }}>
                <h2><b>Empresas cliente</b></h2>
            </Col>
            <Col xs={2}>
                <Form.Group>
                  <select className="form-control" name="opciones_id" style={{fontWeight: "bold" }}
                      onChange={this.handleChangeOpc} >
                    <optgroup label="Empresas">
                      <option value={'EV'}>Vigentes</option>
                      <option value={'EB'}>De baja</option>
                    </optgroup>
                    <optgroup label="Referentes">
                      <option value={'RV'}>Ref de empresas vig</option>
                      <option value={'RB'}>Ref de empresas de baja</option>
                    </optgroup>
                  </select>
                </Form.Group>
            </Col>
            <Col xs={2}>
            <Form.Group>
                <FormControl type="text" name="buscar" placeholder="Texto a filtrar" className="mr-sm-2" 
                        onChange={(e) => { this.setState({ filterGrilla: e.target.value })} } /> 
              </Form.Group>
            </Col>
            <Col xs={1}>
                <Button variant="primary" size="sm" type="submit" 
                        onClick={this.handleModalAlta} ><i className="fas fa-plus"></i> Agregar
                </Button>
            </Col>
            <Col xs={1}>
                <Button  variant='outline-warning' size='sm' onClick={() => {this.setState({ showhelp : true}) } } > 
                Ayuda
                </Button>
           </Col>
           <Col xs={2}>
                <Button variant='outline-success' size='sm' 
                        onClick={(e) => exportToCSV(`Empresas Cliente ${this.state.opciones_id.substring(0,1)==='E'?' ':' Referentes'} ${this.state.opciones_id.slice(-1)==='V'?' vigentes':' de baja'}`,
                                    `Empresas_Cliente_${NOMBRE_SIST} ${this.state.opciones_id.substring(0,1)==='E'?'_':'_Referentes'} ${this.state.opciones_id.slice(-1)==='V'?'_vigentes':'_de_baja'}`,
                                    `${URL_DB}SEL_PANEL_EMPRESAS ('D','${this.state.opciones_id}')`
                                    )} > 
                        <FaDownload /> Excel
                </Button>
              { registrosFiltrados.length > 0 && ` ${registrosFiltrados.length} Regs`  }
            </Col>	      
          </Row>

          <Row>
            <Col>
              <Table striped bordered hover size="sm" id="data_table">
                <thead className="Grilla-header">
                <tr>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_id')}>
                      #ID</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_deno')}>
                      Razon Social</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ests_deno')}>
                      Status</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('referente')}>
                      Referente</Button>
                  </th> 
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('eref_puesto')}>
                      Puesto</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('erub_deno')}>
                      Rubro</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_cant_emple')}>
                      #Empl</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_cuit')}>
                      CUIT</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_tele')}>
                      Telefonos</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_mail')}>
                      Mail</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_domi')}>
                      Domicilio</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_loca')}>
                      Localidad</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_pais_id')}>
                      Pais</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_obser')}>
                      Observ</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_alta_f')}>
                      Alta</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_baja_f')}>
                      Baja</Button>
                  </th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.fetchingregistros && 'Cargando...'
                }
                {
                  registrosFiltrados.map((regis) => {
                    return (
                      <tr key={regis.clave}>
                        <td>{regis.empr_id}</td>
                        <td>{regis.empr_deno}</td>
                        <td style={{ backgroundColor: regis.ests_color }}>{regis.ests_deno}</td>
                        <td>{regis.referente}</td>
                        <td>{regis.eref_puesto}</td>
                        <td>{regis.erub_deno}</td>
                        <td>{regis.empr_cant_emple}</td>
                        <td>{regis.empr_cuit}</td>
                        <td>{regis.empr_tele}</td>
                        <td>{regis.empr_mail}</td>                     
                        <td>{regis.empr_domi}</td>
                        <td>{regis.empr_loca}</td>
                        <td>{regis.empr_pais_id}</td>
                        <td>{`${regis.baem_deno} ${regis.empr_obser}`}</td>
                        <td>{regis.empr_alta_f}</td>
                        <td>{regis.empr_baja_f}</td>
                        <td>
    {regis.perm_modif==='S' &&
                          <Button variant="primary" size="sm" onClick={() => this.handleModalEditEdit(regis)}>
                            <i className="fas fa-pen"></i></Button>
    }
    {regis.perm_borrar==='S' &&
                          <Button variant="danger" size="sm" onClick={() => this.handleModalBorrEdit(regis)}>
                            <i className="fas fa-trash-alt"></i></Button>
    }
    {regis.perm_reopen==='S' &&
                          <Button variant="success" size="sm" onClick={() => this.handleModalOpenEdit(regis)}>
                            <i class="fa fa-folder-open"></i> Reabrir</Button>
    }
                        </td>
                      </tr>  
                    ) 
                  }) 
                }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>


        <Modal show={this.state.showModalEdit} onHide={this.handleModalEditExit} dialogClassName="modal-90">
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.empr_id===0 ? 'Agregar una empresa' : `Editar empresa ${this.state.empr_razon}  (#${this.state.empr_id})`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form onSubmit={this.handleEditRegis}>
          <Row>
              <Col xs={1}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <select className="form-control" name="empr_ests_id" 
                          onChange={this.handleChange} value={this.state.empr_ests_id} >
                  { this.state.estados.map((sts) => { 
                      return (
                          <option key={sts.ests_id} 
                                  value={sts.ests_id}> {sts.ests_deno}
                          </option>
                              ) 
                    }) }  
                  </select>
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group>
                  <Form.Label>Razon social</Form.Label>
                  <Form.Control type="text" name="empr_razon" value={this.state.empr_razon} onChange={this.handleChange} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Form.Group>
                  <Form.Label>Reducido/Fantasia</Form.Label>
                  <Form.Control type="text" name="empr_fanta" value={this.state.empr_fanta} onChange={this.handleChange} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Form.Group>
                  <Form.Label>CUIT</Form.Label>
                  <Form.Control type="text" name="empr_cuit" value={this.state.empr_cuit} onChange={this.handleChange} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
              <Col xs={1}>
                <Form.Group>
                  <Form.Label>Rubro</Form.Label>
                  <select className="form-control" name="empr_erub_id" onChange={this.handleChange} value={this.state.empr_erub_id} >
                  { this.state.rubros.map((rub) => { 
                      return (
                          <option key={rub.erub_id} 
                                  value={rub.erub_id}> {rub.erub_deno}
                          </option>
                              ) 
                    }) }  
                  </select>
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group>
                  <Form.Label>Mail</Form.Label>
                  <Form.Control type="text" name="empr_mail" value={this.state.empr_mail} onChange={this.handleChange} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
            </Row>
            
            <Row>
              <Col xs={2}>
                <Form.Group>
                  <Form.Label>Domicilio</Form.Label>
                  <Form.Control type="text" name="empr_domi" value={this.state.empr_domi} onChange={this.handleChange} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Form.Group>
                  <Form.Label>Localidad</Form.Label>
                  <Form.Control type="text" name="empr_loca" value={this.state.empr_loca} onChange={this.handleChange} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
              <Col xs={1}>
                <Form.Group>
                  <Form.Label>Pais</Form.Label>
                  <select className="form-control" name="empr_pais_id" onChange={this.handleChange} value={this.state.empr_pais_id} >
                  { this.state.paises.map((pais) => { 
                      return (
                          <option key={pais.pais_id} 
                                  value={pais.pais_id}> {pais.pais_deno} </option>
                              ) 
                    }) }
                  </select>
                </Form.Group>
              </Col>    
              <Col xs={2}>
                <Form.Group>
                  <Form.Label>Telefonos</Form.Label>
                  <Form.Control type="text" name="empr_tele" value={this.state.empr_tele} onChange={this.handleChange} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
              <Col xs={1}>
                <Form.Group>
                  <Form.Label># Emplead</Form.Label>
                  <Form.Control type="number" name="empr_cant_emple" value={this.state.empr_cant_emple} onChange={this.handleChange} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group>
                <Form.Label>Observaciones</Form.Label>
                  <Form.Control type="text" name="empr_obser" value={this.state.empr_obser} onChange={this.handleChange} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
            </Row>
            
            <Row> 
            <Col xs={6} style={{textAlign: "left"}}>
                    <b>Referentes y contactos</b> 
                </Col>
                <Col xs={6} style={{textAlign: "right"}}>
                    <b>{`Alta:${this.state.empr_alta_f}`}</b>
                </Col>  
            </Row>

            <Row>
                <div>
                  <Table>
                  <thead className="Grilla-header">
                    <tr>
                      <th>Apellido</th>
                      <th>Nombre</th>
                      <th>Puesto</th>
                      <th>Telefono</th>
                      <th>Mail</th>
                      <th>Observ</th>
                      <th>Baja</th>
                    </tr>
                  </thead>  
                  <tbody>
                    { 
                      this.state.referenLista.map((refe) => {
                        return (
                        <tr  key={refe.row_num}>    
                          <td style={{width: "15%" }}>
                              <Form.Control type="text" name="eref_apellido" 
                                            value={refe.eref_apellido} onChange={(e) => this.handleChangeRefe(e,refe.row_num)} />
                          </td>
                          <td style={{width: "15%" }}>
                              <Form.Control type="text" name="eref_nombre" 
                                            value={refe.eref_nombre} onChange={(e) => this.handleChangeRefe(e,refe.row_num)} />
                          </td>      
                          <td style={{width: "10%" }}>
                              <Form.Control type="text" name="eref_puesto" 
                                            value={refe.eref_puesto} onChange={(e) => this.handleChangeRefe(e,refe.row_num)} />
                          </td>
                          <td style={{width: "10%" }}>
                              <Form.Control type="text" name="eref_tele" 
                                            value={refe.eref_tele} onChange={(e) => this.handleChangeRefe(e,refe.row_num)} />
                          </td>
                          <td style={{width: "20%" }}>
                              <Form.Control type="text" name="eref_mail" 
                                            value={refe.eref_mail} onChange={(e) => this.handleChangeRefe(e,refe.row_num)} />
                          </td>
                          <td style={{width: "25%" }}>
                              <Form.Control type="text" name="eref_obser" 
                                            value={refe.eref_obser} onChange={(e) => this.handleChangeRefe(e,refe.row_num)} />
                          </td>
                          <td style={{width: "5%" }}>
                              <Button variant="danger" size="sm" 
                                      onClick={(e) => this.borraRefente(refe.eref_id,`${refe.eref_nombre} ${refe.eref_apellido}`)}>
                              <i className="fas fa-trash-alt"></i></Button>
                          </td>
                        </tr>        
                        ) 
                      }) 
                    }
                  </tbody>      
                  </Table> 
                </div>
            </Row>

            <Row>
                <Col xs={3}>
                    <Button variant="primary" size="sm" 
                            onClick={this.agregaReferente}><i className="fas fa-plus"></i> Referente a esta lista
                    </Button>
                </Col>
                <Col xs={3} style={{textAlign: "right"}}>
                  <Button variant="success" size="sm" type="submit">
                    Grabar
                  </Button>
                  <Button variant="secondary" size="sm" onClick={this.handleModalEditExit}>
                    Cancelar
                  </Button>
                </Col>
                <Col xs={6}>
                  <Alert key="1" variant="danger" show={respError.length >3 ? true : false} >
                        {respError}
                  </Alert>
                </Col>
            </Row>
          </Form>
          </Modal.Body>
        </Modal>
  
        <Modal show={this.state.showModalBorr} onHide={this.handleModalBorrExit} dialogClassName="modal-90">
          <Modal.Header closeButton style={{ backgroundColor: "#ff6666" }}>
            <Modal.Title>
              {`Eliminar empresa ${this.state.empr_razon}  (#${this.state.empr_id})`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form onSubmit={this.handleBorrRegis}>
          <Row> 
              <Col xs={2}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control type="text" value={this.state.ests_deno} 
                                style={{ backgroundColor: this.state.ests_color }} readOnly />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group>
                  <Form.Label>Razon social</Form.Label>
                  <Form.Control type="text" name="empr_razon" value={this.state.empr_razon} readOnly />
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Form.Group>
                  <Form.Label>Reducido/Fantasia</Form.Label>
                  <Form.Control type="text" value={this.state.empr_fanta} readOnly />
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Form.Group>
                  <Form.Label>Rubro</Form.Label>
                  <Form.Control type="text" value={this.state.erub_deno} readOnly />
                </Form.Group>
              </Col>
              <Col xs={1}>
                <Form.Group>
                  <Form.Label># Emplead</Form.Label>
                  <Form.Control type="number"  value={this.state.empr_cant_emple} readOnly />
                </Form.Group>
              </Col>
              <Col xs={1}>
                <Form.Group>
                  <Form.Label>Alta</Form.Label>
                    <Form.Control type="text"  value={this.state.empr_alta_f} readOnly />
                  </Form.Group>
              </Col>
              <Col xs={1}>
                <Form.Group>
                  <Form.Label>Baja</Form.Label>
                    <Form.Control type="text" value={this.state.empr_baja_f} readOnly />
                  </Form.Group>
              </Col>
            </Row>
            
            <Row>
              <Col xs={3}>
                <Form.Group>
                  <Form.Label>Domicilio</Form.Label>
                  <Form.Control type="text" value={this.state.empr_domi} readOnly />
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Form.Group>
                  <Form.Label>Localidad</Form.Label>
                  <Form.Control type="text" value={this.state.empr_loca} readOnly />
                </Form.Group>
              </Col>
              <Col xs={1}>
                <Form.Group>
                  <Form.Label>Pais</Form.Label>
                  <Form.Control type="text" value={this.state.empr_pais_id} readOnly />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group>
                  <Form.Label>Telefonos</Form.Label>
                  <Form.Control type="text" value={this.state.empr_tele} readOnly />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group>
                  <Form.Label>Mail</Form.Label>
                  <Form.Control type="text" value={this.state.empr_mail} readOnly />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={9}>
                <Form.Group>
                <Form.Label>Observaciones</Form.Label>
                  <Form.Control type="text" value={this.state.empr_obser} readOnly />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group>
                  <Form.Label>Motivo de baja</Form.Label>
                  <select className="form-control" name="empr_baem_id" onChange={this.handleChange} value={this.state.empr_baem_id} >
                  <option key={0} value={0} disabled >Indique el motivo</option>
                  { this.state.motivosbaja.map((mot) => { 
                      return (
                          <option key={mot.baem_id} 
                                  value={mot.baem_id}> {mot.baem_deno}</option>
                              ) 
                    }) }
                  </select>
                </Form.Group>
              </Col>          
            </Row>
            <br />

            <Row>
                <Col xs={3}>
                </Col>
                <Col xs={3} style={{textAlign: "right"}}>
                  <Button variant="danger" size="sm" type="submit">
                    Eliminar empresa
                  </Button>
                  <Button variant="secondary" size="sm" onClick={this.handleModalBorrExit}>
                    Cancelar
                  </Button>
                </Col>
                <Col xs={6}>
                  <Alert key="1" variant="danger" show={respError.length >3 ? true : false} >
                        {respError}
                  </Alert>
                </Col>
            </Row>
          </Form>
          </Modal.Body>
        </Modal>

        < Modal show={this.state.showhelp} onHide={() => { this.setState({ showhelp : false}) } } dialogClassName="modal-90">
            <AyudaAdmi clave={'admin'}/>
        </Modal>

      </div>
    );
  }
}

export default PanelEmpresasPage;
