import React from 'react';
import { URL_DB } from './../../constants';
import axios from 'axios';
import { Table, Row, Col, Container, Button, Form, Alert, FormControl } from 'react-bootstrap';
import Notifications from '../../components/Notifications';
import swal from 'sweetalert';
import { Redirect } from "react-router-dom";
import '../stylePages.css';

class PanelAyudaPersPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      registros: [],
      login_admi_id: sessionStorage.getItem('USU_ADMI_ID'),
      salir : 0,

      fetchRegistros: true,
      respuestaSp: [],
      respError: '',
      mensajeAlerta: '',
    };

    this.poblarGrilla     = this.poblarGrilla.bind(this); 
    this.handleChange     = this.handleChange.bind(this);
  }

  componentDidMount() {

    this.poblarGrilla();       
 
  }
  
  /*==========================================================================
   Completo datos de grilla principal 
  *==========================================================================*/
  poblarGrilla = () => {

    const sql = `${URL_DB}SEL_AYUDA('*')`
    axios.get(sql)
      .then((response) => {
        this.setState({
          registros: response.data[0],
        })
      })  
      .catch((error) => console.log(error))
      .finally(() => {
        this.setState({ fetchRegistros: false })
      })
  }

  
  handleChange(event, a_row) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

 
    var registros = this.state.registros
    
    if (name === 'ayud_titulo') { registros[a_row].ayud_titulo = value }
    if (name === 'ayud_texto') { registros[a_row].ayud_texto = value }
    
    this.setState({ registros });

  };
  
  /*==========================================================================
   Actualizacion : Modificacion de mensajes
  *==========================================================================*/
  handleEditRegis(regis) {
 
    if (regis.ayud_texto.length < 10 ) {
      swal({ 
        title : `Error al grabar ayuda`,
        text  : 'Debe completar con mas de 10 caracteres',})
    } else {
      const sql =  `${URL_DB}AM_AYUDA(${this.state.login_admi_id},'${regis.ayud_clave}','${encodeURIComponent(regis.ayud_titulo)}','${encodeURIComponent(regis.ayud_texto)}')`;  
      axios.get(sql)
      .then((response) => {
            this.setState({
              respuestaSp: response.data[0]
            })
            var obj = this.state.respuestaSp[0];
            this.setState({
              respError : obj.respuesta
            })

            if (this.state.respError==='OK') {
                this.setState({
                    mensajeAlerta : 'Registrado correctamente',
                    mensajeColor  : 'green',
                })          
            }  
        })
        .catch((error) => {
          alert('ERROR interno API al actualizar BD:'+error)
        })
    }

  }

  
  /*==========================================================================
   RENDER
  *==========================================================================*/

  render() {  

    const respError = this.state.respError;
    const mensajeAlerta = this.state.mensajeAlerta;
    const mensajeColor = this.state.mensajeColor;
    const registros = this.state.registros;

    return(
      <div>        
        {mensajeAlerta.length >1 ? <Notifications mensaje={mensajeAlerta}
                                                  mensajeColor={mensajeColor}
                                    /> : '' }

    { this.state.salir===1 &&
          <Redirect to={'/'} />
      }  


        <Container  fluid="true">
          <Row>
            <Col style={{textAlign: "left" }}>
                <h2>Fijar mensajeria de Ayuda para usuarios personas</h2>
            </Col>        
          </Row>
          
          <Row>
            <Col>
              <Table hover size="sm" id="data_table">
                {
                  this.state.fetchRegistros && 'Cargando...'
                }
                {
                  registros.map((regis) => {
                    return (
                      <>
                      <Row>
                        <b>{`Para Form  ${regis.ayud_clave}`}</b>
                        <Col xs={10} style={{textAlign: "left"}}>
                          <Row>
                            <Col xs={1} style={{textAlign: "right"}}>
                              Titulo
                            </Col>
                            <Col xs={11}>
                                <Form.Control type="text" name="ayud_titulo" value={regis.ayud_titulo} 
                                        onChange={(e) => this.handleChange(e,regis.row_num)} />
                            </Col>

                            <Col xs={1} style={{textAlign: "right"}}>
                              Texto
                            </Col>
                            <Col xs={11}>
                                <FormControl as="textarea"  name="ayud_texto"
                                        defaultValue={regis.ayud_texto} 
                                        placeholder="Este texto saldra en la ayuda"
                                        rows = {5}
                                        style={{ backgroundColor: '#ffff99' }}
                                        onChange={(e) => this.handleChange(e,regis.row_num)} />
                            </Col>
                          </Row>
                        </Col>
                          <Col xs={2}>
                          {`Modif: ${regis.ayud_alta_f}`}
                          <br />
                          <br />
                          <Button variant="success" size="sm" onClick={() => this.handleEditRegis(regis)}>
                            Registrar
                          </Button>

                          </Col>
                      </Row>
                      </>
                    ) 
                  }) 
                }
              </Table>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
            {`Complete texto normalmente, y utilice <br> para marcar un salto de linea`}
            </Col>
            <Col xs={6} style={{textAlign: "left"}}>
              <Button variant="secondary" size="sm" onClick={() => this.setState({ salir : 1 })} >
                            Salir
              </Button>
              
              <Alert key="1" variant="danger" show={respError.length >3 ? true : false} >
                    {respError}
              </Alert>
            </Col>
          </Row>
        </Container>

      </div>
    );
  }
}

export default PanelAyudaPersPage;
