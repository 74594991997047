import React  from 'react';
import { URL_DB } from './../../constants';
import axios from 'axios';
import { Table, Row, Col, Container, Button, Modal, Form, Alert, FormControl } from 'react-bootstrap';
//import ExpGrillaExcelPDF from '../../components/ExpGrillaExcelPDF';
import Notifications from '../../components/Notifications';
import ordenarGrilla from './../../utils/functions/ordenar-grilla';
import AyudaAdmi from '../../components/Ayuda-admi';
import '../stylePages.css';


class PanelTrabajoPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opciones_id : 'P',
      opc_tper_id: 0,
      trabajoPerfil: [],
      registros : [],
      ejerciciosFull :[],
      ejercicios : [],
      rutinas : [],
      login_admi_id: sessionStorage.getItem('USU_ADMI_ID'),
      tper_id: '',
      raeu_id: '',
      sexo : '',
      tall_id : '',
      rang_id : '',
      tiac_id : '',
      ruti_id: '',
      tituloModal:'',
      ruti_deno: '',
      buscarGrillaValue: '',
      primerModal: true,
      showModalEdit: false,
    
      showhelp : false,
      filterGrilla: '',
      fetchingregistros: false,
      fetchEjercicios : false,
      respuestaSp: [],
      respError: '',
      mensajeAlerta: '',

      selectedOption: '',
    };

    this.ordenarGrilla      = this.ordenarGrilla.bind(this);
    this.poblarGrilla       = this.poblarGrilla.bind(this);
    this.handleChangeOpc    = this.handleChangeOpc.bind(this);
    this.getInicioModal     = this.getInicioModal.bind(this);
    this.handleModalEdit    = this.handleModalEdit.bind(this);
    this.handleModalExit    = this.handleModalExit.bind(this);
    this.handleGrabarRuti   = this.handleGrabarRuti.bind(this);
    this.handleChange       = this.handleChange.bind(this);
    
  }

  
  async getPerfilTrabajo() {
   
    const sql =  `${URL_DB}SEL_TRABAJO_PERFIL('S')`
    axios.get(sql)
      .then((response) => {
        this.setState({
          trabajoPerfil: response.data[0]
        })
        if (this.state.pers_id===null) {
          this.setState({
            pers_tper_id : this.state.trabajoPerfil[0].tper_id
          })
        }  
      })
      .catch((error) => console.log(error))

  }

 
  async getInicio() {
    try{
      await Promise.all([this.getPerfilTrabajo() ])
    } catch(e) {
      console.log(e);
    }
  }

  componentDidMount() {
    this.getInicio();
    this.poblarGrilla()
  }

  /*==========================================================================
   Utilidades de filtros y sort para la grilla ppal 
  *==========================================================================*/
  filtrarDatos() {
    const escapedValue = this.state.filterGrilla.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedValue, 'i');
    return this.state.registros.filter(filtro => 
      regex.test(filtro.tper_deno) || 
      regex.test(filtro.sexo_deno) || 
      regex.test(filtro.tall_deno) || 
      regex.test(filtro.rang_deno) || 
      regex.test(filtro.tiac_deno) || 
      regex.test(filtro.ruti_deno) )
  }
  
  ordenarGrilla = (key) => {
    const registros = ordenarGrilla(key, this.state.registros);

    this.setState({ registros });
  }

  


  /*==========================================================================
   Completo datos de grilla principal 
  *==========================================================================*/
   poblarGrilla = () => {

    const sql = `${URL_DB}SEL_PANEL_PERFIL_PERS_RUTINAS ('D','${this.state.opciones_id}',${this.state.opc_tper_id})`
    axios.get(sql)
      .then((response) => {
        this.setState({
          registros: response.data[0],
        })
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.setState({ fetchingregistros: false })
      })      
      
  }

  handleChangeOpc(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ fetchingregistros: true,
      [name]: value,
    }, () => { this.poblarGrilla() });
   
  };

  
  /*==========================================================================
   Lecturas del MODAL
  *==========================================================================*/
   getInicioModal(p_ruti_id) {

    this.setState({ fetchEjercicios: true });
    const sql = `${URL_DB}SEL_PANEL_RUTINAS('D',0)`

    axios.get(sql)
      .then((response) => {
        this.setState({
          ejerciciosFull : response.data[0],
          })
        
        var flags = [], output = [], l = this.state.ejerciciosFull.length, i;
        for( i=0; i<l; i++) {
            if( flags[this.state.ejerciciosFull[i].ruti_id]) continue;
            flags[this.state.ejerciciosFull[i].ruti_id] = true;
            output.push(JSON.parse(`{"ruti_id": ${this.state.ejerciciosFull[i].ruti_id},
                                      "ruti_deno": "${this.state.ejerciciosFull[i].ruti_deno}" }`));
        this.setState({
          rutinas : output,
          primerModal : false,
          ejercicios: this.state.ejerciciosFull.filter( fil => fil.ruti_id === parseInt(p_ruti_id) ) 
          })
        }
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.setState({ fetchEjercicios: false })
      })
  }

  /*==========================================================================
   Abro modal para modificar : Cargo valores y combos
  *==========================================================================*/
  handleModalEdit(regis) {

    this.setState({
      showModalEdit: true,
      ruti_id      : regis.trut_ruti_id===null?Number(0):regis.trut_ruti_id,
      tituloModal  : `${regis.tper_deno}, ${regis.sexo_deno}, ${regis.tall_deno}, edad ${regis.rang_deno} ${regis.tiac_deno}`,
      ruti_deno    : regis.ruti_deno,
      tper_id      : regis.tper_id,
      sexo         : regis.sexo,
      tall_id      : regis.tall_id,
      rang_id      : regis.rang_id,
      tiac_id      : regis.tiac_id,

      respError: '',
      mensajeAlerta: '',
      mensajeColor: 'N' 
    });
    // lo cargo solo la primera vez, es decir si el usuario solo quiere modificar
    if (this.state.primerModal) {
        this.getInicioModal(regis.trut_ruti_id);
    } else {
        this.setState({
            ejercicios: this.state.ejerciciosFull.filter( fil => fil.ruti_id === parseInt(regis.trut_ruti_id) ) 
        });
    }
   
  }
 
  handleChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name==='ruti_id') {
      this.setState({
        ejercicios: this.state.ejerciciosFull.filter( fil => fil.ruti_id === parseInt(value) ),
        ruti_id     : parseInt(value)
      });
    }
  };
  
  
  handleModalExit() {
    this.setState({ showModalEdit: false })
    this.setState({
      mensajeAlerta : 'No se aplicaron cambios',
      mensajeColor  : 'red',
    })
  }

  
  /*==========================================================================
   Actualizacion : Modificacion examen
  *==========================================================================*/
  handleGrabarRuti(event) {
    event.preventDefault();

  if(this.state.ruti_id===0) {
    this.setState({ respError:'Seleccione una rutina y reintente' });
  } else {

    const sql =  `${URL_DB}AM_PANEL_PERFIL_PERS_RUTINAS(${this.state.login_admi_id},${this.state.tper_id},
'${this.state.sexo}',${this.state.tall_id},${this.state.rang_id},
'${this.state.tiac_id}',${this.state.ruti_id})`;
      axios.get(sql)
      .then((response) => {
            this.setState({
              respuestaSp: response.data[0]
            })
            var obj = this.state.respuestaSp[0];
            this.setState({
              respError : obj.respuesta
            })

            if (this.state.respError==='OK') {

                this.setState({
                    mensajeAlerta : 'Registrado correctamente',
                    mensajeColor  : 'green',
                })

                this.setState({ showModalEdit: false })
                this.poblarGrilla()
            }    
        })
        .catch((error) => {
          alert('ERROR interno API al actualizar BD:'+error)
        })
    }

  }

  /*==========================================================================
   RENDER
  *==========================================================================*/

  render() {  

    const respError = this.state.respError;
    const mensajeAlerta = this.state.mensajeAlerta;
    const mensajeColor = this.state.mensajeColor;
    const registrosFiltrados = this.filtrarDatos();

    return(
      <div>        
        {mensajeAlerta.length >1 ? <Notifications mensaje={mensajeAlerta}
                                                  mensajeColor={mensajeColor}
                                    /> : '' }

        <Container  fluid="true">
          <Row>
            <Col xs={4}>
                <h2>Asignar Rutinas x Perfil Trab</h2>
            </Col>

            <Col md={2}>
                    <Form.Group>
                      <select className="form-control" name="opc_tper_id" value={this.state.opc_tper_id }
                                    style={{fontWeight: "bold" }}   onChange={this.handleChangeOpc} >
                      { this.state.trabajoPerfil.map((tip) => { 
                          return (
                                  <option key={tip.tper_id} 
                                          value={tip.tper_id}
                                  > {tip.tper_deno}
                                  </option>
                                  ) 
                        }) }
                      </select>
                    </Form.Group>
            </Col>
            <Col xs={2}>
                <Form.Group>
                  <select className="form-control" name="opciones_id" style={{fontWeight: "bold" }}
                      onChange={this.handleChangeOpc} >
                      <option value={'P'}>Pend de asignar</option>
                      <option value={'T'}>Todas</option>
                  </select>
                </Form.Group>
            </Col>
            <Col xs={2}>
            <Form.Group>
                <FormControl type="text" name="buscar" placeholder="Texto a filtrar" className="mr-sm-2" 
                        onChange={(e) => { this.setState({ filterGrilla: e.target.value })} } /> 
              </Form.Group>
            </Col>
            <Col xs={2}>
              <Button  variant='outline-warning' size='sm' onClick={() => {this.setState({ showhelp : true}) } } > 
              Ayuda
              </Button>
           </Col>
          </Row>

          <Row>
            <Col>
              <Table striped bordered hover size="sm" id="data_table">
                <thead className="Grilla-header">
                <tr>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('tper_deno')}>
                      Perfil Trabajo</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('sexo_deno')}>
                      Sexo</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('tall_deno')}>
                      Contextura</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('rang_deno')}>
                      Edad</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('tiac_deno')}>
                      Activ personal</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('cant_pers')}>
                      #Pers</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ruti_deno')}>
                      Rutina</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('trut_ruti_id')}>
                      #Rut</Button>
                  </th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.fetchingregistros && 'Cargando...'
                }
                {
                  registrosFiltrados.map((regis) => {
                    return (
                      <tr key={regis.row_num}>
                        <td style={{textAlign: "left"}}>{regis.tper_deno}</td>
                        <td style={{textAlign: "left", backgroundColor: regis.sexo_color}}>{regis.sexo_deno}</td>
                        <td style={{textAlign: "left", backgroundColor: regis.tall_color}}>{regis.tall_deno}</td>
                        <td style={{textAlign: "left", backgroundColor: regis.rang_color}}>{regis.rang_deno}</td>
                        <td style={{textAlign: "left"}}>{regis.tiac_deno}</td>
                        <td style={{textAlign: "center"}}>{regis.cant_pers}</td>
                        <td style={{textAlign: "left", backgroundColor: regis.ruti_color }}>{regis.ruti_deno}</td>
                        <td style={{textAlign: "center"}}>{regis.trut_ruti_id}</td>
                        <td>
                          <Button variant="primary" size="sm" 
                              onClick={() => this.handleModalEdit(regis)}><i className="fas fa-pen"></i></Button>
                        </td>
                      </tr>  
                    ) 
                  }) 
                }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>



        <Modal show={this.state.showModalEdit} onHide={this.handleModalExit} dialogClassName="modal-90" >
          <Modal.Header closeButton>
            <Modal.Title>
            { `Asignar Rutina a ${this.state.tituloModal}` }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form>
                <Row>
                <Col xs={4}>
                    <Form.Group>
                        <Form.Label><b>Seleccione rutina</b></Form.Label>
                        <select className="form-control" name="ruti_id" value={this.state.ruti_id }
                                                                        onChange={this.handleChange} >

            { this.state.ruti_id ===0 &&  // Cuando nuevo ponemos un reg de 'Seleccion' para que haga el change
                                    <option key={0} value={0} disabled >( Seleccione rutina )</option>
            }

                        { this.state.rutinas.map((rut) => { 
                            return (
                                    <option key={rut.ruti_id} 
                                            value={rut.ruti_id}
                                    > {rut.ruti_deno}
                                    </option>
                                    ) 
                          }) }
                        </select>
                      </Form.Group>
                  </Col>

                  <Col xs={8}>
                      <Table bordered hover size="sm" id="data_table">
                        <thead className="Grilla-header">
                        <tr>
                          <th>{this.state.ruti_deno}</th>
                          <th>Ejecicio</th>
                          <th>Sem</th>
                          <th>Implement</th>
                          <th>Video</th>
                          <th>#Usuarios</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          this.state.fetchEjercicios && 'Cargando...'
                        }
                        {
                          this.state.ejercicios.map((ruti) => {
                            return (
                              <tr key={ruti.clave} style={{ backgroundColor: ruti.color }}>
                                <td>{`${ruti.ruci_nro_ciclo}:${ruti.ruci_deno}`}</td>   {/* aca es el nombre del ciclo */}
                                <td>{ruti.ejer_deno}</td>
                                <td>{ruti.ruci_semanas}</td>
                                <td>{ruti.impleme}</td>
                                <td>{ruti.ejer_video}</td>
                                <td>{ruti.cant_usuarios}</td>
                              </tr>  
                            ) 
                          }) 
                        }
                        </tbody>
                      </Table>
                  </Col>
                </Row>
  
                <Row>
                  <Col xs={4}>
                    <Button variant="success" size="sm" onClick={this.handleGrabarRuti}>
                        Grabar
                    </Button>
                    <Button variant="secondary" size="sm" onClick={this.handleModalExit}>
                        Cancelar
                    </Button>
                  </Col>
                  <Col xs={8}>
                    <Alert key="1" variant="danger" show={respError.length >3 ? true : false} >
                      {respError}
                    </Alert>
                  </Col>
                </Row>

               
            </Form>

          </Modal.Body>
        </Modal>

        < Modal show={this.state.showhelp} onHide={() => { this.setState({ showhelp : false}) } } dialogClassName="modal-90">
            <AyudaAdmi clave={'config-rutina'}/>
        </Modal>

      </div>
    );
  }
}

export default PanelTrabajoPage;
