//import logo from './logo.svg';
import './App.css';

import React from 'react';
import './assets/styles/index.scss'; // CSS IMPORTS
import { Redirect, Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import Header from './components/Header';

//import Home from './pages/home'
import PersonaRutinaPage from './pages/persona-rutina';
import DefPersonaPage from './pages/persona-datos';
import MensajeriaPage from './pages/persona-mensajes';
import EmpresaPersonasPage from './pages/empresa-personas';
import PanelEjerciciosPage from './pages/panel-ejercicios';
import PanelRutinasPage from './pages/panel-rutinas';
import PanelTrabajoPage from './pages/panel-trabajo';
import PanelEmpresasPage from './pages/panel-empresas';
import PanelMensajeriaPage from './pages/panel-mensa-pen';
import PanelMensajeHisPage from './pages/panel-mensa-his';
import PanelRangosDefPage from './pages/panel-rangos-def';
import PanelTipoTrabPage from './pages/panel-tipotrabajo';
import PanelPerfilTrabPage from './pages/panel-perfiltrabajo';
import SelectPersona from './components/Emula-persona';
import PanelSistemaUsoPage from './pages/sistema-uso';
import PanelAyudaPersPage from './pages/panel-ayuda-pers';
import DatosUsuario from './components/UsuarioLogueado';
import Login from './components/Login';


class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
        login_case: sessionStorage.getItem('LOGIN_CASE'),
      };  
  }

  componentDidMount() {

    const login_empr_id = sessionStorage.getItem('USU_EMPR_ID');
    const login_pers_id = sessionStorage.getItem('USU_PERS_ID');
    const login_admi_id = sessionStorage.getItem('USU_ADMI_ID');
  

    if(login_pers_id===null) {
        sessionStorage.setItem('USU_PERS_ID','1')
    }
    if(login_empr_id===null) {
        sessionStorage.setItem('USU_EMPR_ID','2')
    }
    if(login_admi_id===null) {
        sessionStorage.setItem('USU_ADMI_ID','555')
    }

    sessionStorage.setItem('NOMBRE_USUARIO','Let Lux')

  }

  render() {

    return (

      <div>
      <Router>
        {/* HEADER */}
        <Header a_usu_login={'cocota'} />
        {/* BODY DINAMIC CONTENT */}
        <Switch>
          <Route exact path="/persona-rutina" component={PersonaRutinaPage} />
          <Route exact path="/persona-datos" component={DefPersonaPage} />
          <Route exact path="/persona-mensajes" component={MensajeriaPage} />
          <Route exact path="/empresa-personas" component={EmpresaPersonasPage} />
          <Route exact path="/panel-ejercicios" component={PanelEjerciciosPage} />
          <Route exact path="/panel-rutinas" component={PanelRutinasPage} />
          <Route exact path="/panel-trabajo" component={PanelTrabajoPage} />
          <Route exact path="/panel-empresas" component={PanelEmpresasPage} />
          <Route exact path="/panel-mensa-pen" component={PanelMensajeriaPage} />
          <Route exact path="/panel-mensa-his" component={PanelMensajeHisPage} />
          <Route exact path="/panel-tipotrabajo" component={PanelTipoTrabPage} />
          <Route exact path="/panel-perfiltrabajo" component={PanelPerfilTrabPage} />
          <Route exact path="/panel-rangos-def" component={PanelRangosDefPage} />
          <Route exact path="/Emula-persona" component={SelectPersona} />
          <Route exact path="/sistema-uso" component={PanelSistemaUsoPage} />
          <Route exact path="/panel-ayuda-pers" component={PanelAyudaPersPage} />
          <Route exact path="/UsuarioLogueado" component={DatosUsuario} /> 
          <Route path="/login" component={Login} />
{/*          <Route path="/datos-usuario" component={DatosUsuario} />*/}
{/*          <Route path="*" component={Home} /> */}
        </Switch>

        { this.state.login_case==='NO' &&
                         <Redirect to={'/login'} />   
        }

        {/* FOOTER */}
      </Router>

      </div>

    );
  }
}

export default App;



