import React from 'react';
import { NOMBRE_SIST } from './../../constants';
import { Navbar, Nav, NavDropdown, Dropdown } from 'react-bootstrap';

const Header = () => (
  <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
    <Navbar.Brand href="#home">{ NOMBRE_SIST }</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link href="persona-rutina?aler=0">Rutina asignada</Nav.Link>
        <Nav.Link href="persona-mensajes">Mensajes</Nav.Link>
        <Nav.Link href="persona-datos">Datos usuario</Nav.Link>
        <Nav.Link href="empresa-personas">Reg Empleados</Nav.Link>
      </Nav>
      <NavDropdown title="Panel" id="basic-nav-dropdown">
        <NavDropdown.Item href="panel-tipotrabajo">Definir Tipo de Trabajo</NavDropdown.Item>
        <NavDropdown.Item href="panel-perfiltrabajo">Definir Perfiles de Trabajo</NavDropdown.Item>
        <NavDropdown.Item href="panel-rangos-def">Asignar Perfiles a Tipos de trabajo</NavDropdown.Item>
        <NavDropdown.Item href="panel-ejercicios">Definir Ejercicios</NavDropdown.Item>
        <NavDropdown.Item href="panel-rutinas">Definir Rutinas y ciclos</NavDropdown.Item>
        <NavDropdown.Item href="panel-trabajo">Asignar Rutinas a Perfiles de Trabajo</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="panel-empresas">Administrar Empresas cliente</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="panel-mensa-pen">Responder mensajeria recibida</NavDropdown.Item>
        <NavDropdown.Item href="panel-mensa-his">Consulta de Todos los Mensajes</NavDropdown.Item>
        <NavDropdown.Item href="sistema-uso">Uso del sistema ( y envio mensajes )</NavDropdown.Item>
        <NavDropdown.Divider />
        
        
        <NavDropdown.Item href="panel-ayuda-pers">Definir ayuda para personas</NavDropdown.Item>
        <NavDropdown.Item href="emula-persona">Emular usuario de { NOMBRE_SIST }</NavDropdown.Item>
      </NavDropdown>

      <Dropdown /*className="btn-group dropleft"*/>
        <Dropdown.Toggle variant="success" id="dropdown-basic" >
        <i className="fas fa-user"></i> {sessionStorage.getItem('NOMBRE_USUARIO')}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="/UsuarioLogueado">Datos Usuario</Dropdown.Item> 
          <Dropdown.Divider /> 
          <Dropdown.Item onClick={() => { window.location = '/'; 
                                          sessionStorage.setItem('USU_PERS_ID',0);
                                          sessionStorage.setItem('NOMBRE_USUARIO','')
                                          sessionStorage.setItem('LOGIN_CASE','NO')
                                  }}><i className="fas fa-power-off"></i> Cerrar Sesión</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

    </Navbar.Collapse>
  </Navbar>
);

export default Header;

