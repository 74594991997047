import React  from "react";
import { NOMBRE_SIST, URL_DB, URL_DBQUERY } from './../../constants';     // URL_DBQUERY="http://localhost:3001/";
import axios from 'axios';
import { Button, FormGroup, FormControl, Card, Row, Col, Modal, Alert, Form } from "react-bootstrap";
import "./DatosUsuario.css";
// import emailjs from 'emailjs-com';

export default class DatosUsuario extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      fetchingregistros: true, 
      login_prof_id: sessionStorage.getItem('USUARIO'),
      prof_nom : '',
      prof_ape : '',
      prof_mail : '',
      prof_nro_docum : '',
      password_ant : '',
      password_1 : '',
      password_2 : '',
      codigoVerificaDado : 0,
      codigoVerificaInput : 0,
      mensajeDatos : '',
      mensajePwd : '',
      mensajeEmail : '',
      mensajeModal : '',
      respError: '',
      mensajeAlerta: '',
      email: '',
      asunto: '',
      mensaje: '',
    };

    this.validateFormDatos        = this.validateFormDatos.bind(this);
    this.handleDatos              = this.handleDatos.bind(this);
    this.validateFormPwd          = this.validateFormPwd.bind(this);
    this.handlePwd                = this.handlePwd.bind(this);
    this.handleEmailVeriCodigo    = this.handleEmailVeriCodigo.bind(this);
    this.handleEmailGrabo         = this.handleEmailGrabo.bind(this);
    this.handleModalConfirmaExit  = this.handleModalConfirmaExit.bind(this);
 
  }
  
  async getUsuarioProfe() {
    const sql = `${URL_DB}SEL_USUARIO(${this.state.login_prof_id},'P')`
    const response = await axios.get(sql)
    try {
      this.setState({ prof_nom: response.data[0][0].prof_nom,
                      prof_ape: response.data[0][0].prof_ape,
                      prof_mail:response.data[0][0].prof_mail,
                      prof_nro_docum: response.data[0][0].prof_nro_docum
       });

    } catch(error) {
      console.log(error)
    }
  } 

  async getInicio() {
    try{
      await Promise.all([this.getUsuarioProfe()])
    } catch(e) {
      console.log(e);
    }
  }

  componentDidMount() {

    this.getInicio()
    .then(()=>{
      this.setState({ fetchingregistros: false })
    })

  }

  validateFormDatos = () => {
    return this.state.prof_ape.length > 0 && this.state.prof_nom.length > 0 && this.state.prof_nro_docum.length > 0;
  }

  validateFormPwd = () => {
    return this.state.password_ant.length > 0 && this.state.password_1.length > 0 && this.state.password_2.length > 0;
  }

  validateFormEmail = () => {
    return this.state.email.length > 0;
  }

  /*==========================================================================
   Actualizacion : Modificacion datos
  *==========================================================================*/
  handleDatos(event) {
  //event.preventDefault();

  const sql =  `${URL_DB}M_USUARIO_DATOS(${this.state.login_prof_id},'P',
  '${this.state.prof_nom}','${this.state.prof_ape}','${this.state.prof_nro_docum}')`  
  axios.get(sql)
  .then((response) => {
        this.setState({
          respuestaSp: response.data[0]
        })
        var obj = this.state.respuestaSp[0];
        this.setState({
          respError : obj.respuesta
        })

        if (this.state.respError==='OK') {
            this.setState({ mensajeDatos : 'Registrado correctamente', })     
        } else {
            this.setState({ mensajeDatos : this.state.respError, });
        } 
    })
    .catch((error) => {
        alert('ERROR interno API al actualizar BD:'+error)
        this.setState({ mensajeDatos : 'Error API', })
    })
}

/*==========================================================================
   Actualizacion : Modificacion password
*==========================================================================*/
handlePwd(event) {
  //event.preventDefault();

  const sql =  `${URL_DB}M_USUARIO_PASSWORD(${this.state.login_prof_id},'P',
  '${this.state.password_ant}','${this.state.password_1}','${this.state.password_2}')`  
  axios.get(sql)
  .then((response) => {
        this.setState({
          respuestaSp: response.data[0]
        })
        var obj = this.state.respuestaSp[0];
        this.setState({
          respError : obj.respuesta
        })

        if (this.state.respError==='OK') {
          this.setState({ mensajePwd : 'Registrado correctamente', })     
        } else {
          this.setState({ mensajePwd : this.state.respError, });
        } 
  })
  .catch((error) => {
      alert('ERROR interno API al actualizar BD:'+error)
      this.setState({ mensajePwd : 'Error API', })
  })

  }
 
/*==========================================================================
   Atualizacion de Email: 
   1- grabo y genero un codigo de seguridad
   2- envio un mail con ese codigo
   3- El usuario lee ese mail, captura el codigo y lo escribe en el modal
   4- Si esta OK grabamos el nuevo mail, estando seguros que es del usuario y tiene acceso 
*==========================================================================*/
handleEmailVeriCodigo(event) {
  //event.preventDefault();

  const sql =  `${URL_DB}M_USUARIO_EMAIL_VERIFICA(${this.state.login_prof_id},'P','V','${this.state.email}',0)`  
  axios.get(sql)
  .then((response) => {
        this.setState({
          respuestaSp: response.data[0]
        })
        var obj = this.state.respuestaSp[0];
        this.setState({
          codigoVerificaDado : Number(obj.respuesta)
        })

        if (this.state.codigoVerificaDado > 10000 ) {
            this.setState({ mensajeEmail : 'Enviando Email...', })
            this.enviarEmail(this.state.codigoVerificaDado) 
            this.setState({ mensajeEmail : 'Verifique el correo y capture el codigo',
                            showModalConfirma : true })     
        } else {

            this.setState({ mensajeEmail : `Error, reintente ${this.state.respError}`, });
        } 
    })
    .catch((error) => {
        alert('ERROR interno API al actualizar BD:'+error)
        this.setState({ mensajePwd : 'Error API', })
    })

  }
 
  handleModalConfirmaExit() {
    this.setState({ showModalConfirma: false,
                    mensajeEmail : 'No se aplico cambio', })
    
  }

  /*==========================================================================
   Envio el mail de verificacion 
  *==========================================================================*/
  enviarEmail(a_codigo){
  //e.preventDefault();
  
 
  axios.post(`${URL_DBQUERY}sendMail/form`,{
    email  : this.state.email,
    asunto : 'Email enviado por el sistema',
    mensaje: `Debe tomar este codigo que le enviamos, e ingresarlo en el form que te lo está solicitando. 
    Codigo de verif: ${a_codigo}`,
    sistema: NOMBRE_SIST
    });

  }

  handleEmailGrabo(event) {
    //event.preventDefault();
  
    const sql =  `${URL_DB}M_USUARIO_EMAIL_VERIFICA(${this.state.login_prof_id},'P','G','${this.state.email}',${this.state.codigoVerificaInput})`  
    axios.get(sql)
    .then((response) => {
          this.setState({
            respuestaSp: response.data[0]
          })
          var obj = this.state.respuestaSp[0];
          this.setState({
            respError : obj.respuesta
          })

          if (this.state.respError==='OK') {
            this.setState({ mensajeEmail : 'Registrado correctamente',  
                            showModalConfirma : false,
                            email : '' })   
          } else {
            this.setState({ mensajeModal : this.state.respError, });
          } 
          
      })
      .catch((error) => {
          alert('ERROR interno API al actualizar BD:'+error)
          this.setState({ mensajeEmail : 'Error API', })
      })
  
    }
  
  render(){

    /*const respError = this.state.respError;
    const mensajeAlerta = this.state.mensajeAlerta;
    const mensajeColor = this.state.mensajeColor; */

    return (
      <div className="DatosUsuario">
        
          <form>
              <h3>{`Datos del usuario registrados en ${NOMBRE_SIST}`}</h3>
              <Row>
                <Col xs={4}>
                    <Card style={{ width: '18rem' }}>
                    <Card.Body>
                      <Card.Title><b>Datos personales</b></Card.Title>
                          <FormGroup controlId="nombre" >
                            Nombre
                            <FormControl  type="text" value={this.state.prof_nom}
                              onChange={e => this.setState({ prof_nom : (e.target.value)})}
                            />
                          </FormGroup>
                          <FormGroup controlId="apellido" >
                            Apellido
                            <FormControl  type="text" value={this.state.prof_ape}
                              onChange={e => this.setState({ prof_ape : (e.target.value)})}
                            />
                          </FormGroup>
                          <FormGroup controlId="prof_nro_docum" >
                            Nro docum
                            <FormControl  type="text" value={this.state.prof_nro_docum}
                              onChange={e => this.setState({ prof_nro_docum : (e.target.value)})}
                            />
                          </FormGroup>
                          <FormGroup controlId="prof_nro_docum" >
                            Celular
                            <FormControl  type="text" value={this.state.prof_nro_docum}
                              onChange={e => this.setState({ prof_nro_docum : (e.target.value)})}
                            />
                          </FormGroup>
                          <Button block  disabled={!this.validateFormDatos()} onClick={() => {this.handleDatos()}}>
                          Actualizar datos
                          </Button>
                    </Card.Body>
                    </Card>
                    <b style={{ color:'#ff0000' }}>{this.state.mensajeDatos}</b>
                </Col>
                <Col xs={4}>
                    <Card style={{ width: '18rem' }}>
                    <Card.Body>
                      <Card.Title><b>Contraseña</b></Card.Title>
                          <FormGroup controlId="password_ant" >
                            Password Actual
                            <FormControl  type="password" value={this.state.password_ant}
                              onChange={e => this.setState({ password_ant : (e.target.value)})}
                            />
                          </FormGroup>
                          <FormGroup controlId="password_1" >
                            Password Nueva
                            <FormControl  type="password" value={this.state.password_1}
                              onChange={e => this.setState({ password_1 : (e.target.value)})}
                            />
                          </FormGroup>
                          <FormGroup controlId="password_2" >
                            Reescriba Pwd Nueva
                            <FormControl  type="password" value={this.state.password_2}
                              onChange={e => this.setState({ password_2 : (e.target.value)})}
                            />
                          </FormGroup>
                          <Button block  disabled={!this.validateFormPwd()} onClick={() => {this.handlePwd()}}>
                          Cambiar Contraseña
                          </Button>
                    </Card.Body>
                    </Card>
                    <b style={{ color:'#ff0000' }}>{this.state.mensajePwd}</b>
                </Col>
                <Col xs={4}>
                    <Card style={{ width: '25rem' }}>
                    <Card.Body>
                      <Card.Title><b>Cambiar Mail</b></Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">Cambio c/verificación</Card.Subtitle>
                      <Card.Text>
                        Si cambia el mail, dado que es la entrada a esta app, verificaremos que sea suyo.
                        Se enviará un codigo al mail nuevo, el cual debe reingresar aqui para confirmar
                      </Card.Text>
                          <FormGroup controlId="email" >
                          Email
                          <FormControl autoFocus type="email" 
                            onChange={e => this.setState({ email: e.target.value})}
                          />
                          </FormGroup>
                          <Button block  disabled={!this.validateFormEmail()} onClick={() => {this.handleEmailVeriCodigo()}}>
                          Cambiar Mail Usuario
                          </Button>
                      </Card.Body>
                      </Card>
                      <b style={{ color:'#ff0000' }}>{this.state.mensajeEmail}</b>
                </Col>
              </Row>
              <Button variant="secondary" size="sm" onClick={() => { window.location = '/'; }} >
                  Salir de Datos de Usuarios
              </Button>
              
            
          </form>
    

          <Modal show={this.state.showModalConfirma} onHide={this.handleModalConfirmaExit}>
          <Modal.Header closeButton>
            <Modal.Title>
               Revisa en {this.state.email} el mail que te enviamos
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Ingresa el codigo que te mandamos</Form.Label>
                  <Form.Control type="number" name="codigoVerificaInput" 
                                onChange= {e => this.setState({ codigoVerificaInput : (e.target.value)})} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
                <Button variant="success" size="sm" onClick={this.handleEmailGrabo}>
                  Grabar
                </Button>
                <Button variant="secondary" size="sm" onClick={this.handleModalConfirmaExit}>
                  Cancelar
                </Button>
                <Alert key="1" variant="danger" show={this.state.mensajeModal.length >3 ? true : false} >
                      {this.state.mensajeModal}
                </Alert>
            </Row>
          </Form>
          </Modal.Body>
        </Modal>
  



      </div>
    );
  }
}
