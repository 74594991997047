import React from 'react';
import { URL_DB } from './../../constants';
import axios from 'axios';
import { Table, Row, Col, Container, Button, Modal, Form, FormControl, Tabs, Tab } from 'react-bootstrap';
//import ExpGrillaExcelPDF from '../../components/ExpGrillaExcelPDF';
import swal from 'sweetalert';
import FichaPersona from '../../components/Ficha-persona';
import MensajesPersona from '../../components/Mensa-persona';
import Notifications from '../../components/Notifications';
import ordenarGrilla from './../../utils/functions/ordenar-grilla'
import '../stylePages.css';
import AyudaAdmi from '../../components/Ayuda-admi';

class PanelMensajeHisPage extends React.Component {
  constructor(props) {
    super(props);

    var currentdate = new Date();

    this.state = {
      desde : currentdate.toISOString().split('T')[0],
      hasta : currentdate.toISOString().split('T')[0],
      txt_en_msg : '',
      registros: [],
      preguntas: [],
      buscarGrillaValue: '',
      showModalEdit: false,
      showModalBorr: false,
      login_admi_id: sessionStorage.getItem('USU_ADMI_ID'),
      pers_id    : '',
      pers_apenom: '',
      pers_edad  : '',
      empr_regis: '',
      empr_deno  : '',
      ests_deno  : '',
      erub_deno  : '',
      ult_preg_f : '',
      dias_preg_t: '',
      ests_color : '',

      showhelp : false,
      filterGrilla: '',
      fetchRegistros: false,
      respuestaSp: [],
      respError: '',
      mensajeAlerta: '',
    };



    this.ordenarGrilla        = this.ordenarGrilla.bind(this);
    this.poblarGrilla         = this.poblarGrilla.bind(this);
    this.handleModalEditEdit  = this.handleModalEditEdit.bind(this);
    this.handleModalExit      = this.handleModalExit.bind(this);
    this.handleChangeOpc      = this.handleChangeOpc.bind(this);
  }

  componentDidMount() {

    this.poblarGrilla();
  
  }

  /*==========================================================================
   Utilidades de filtros y sort para la grilla ppal 
  *==========================================================================*/
  filtrarDatos() {
    const escapedValue = this.state.filterGrilla.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedValue, 'i');
    return this.state.registros.filter(filtro => 
      regex.test(filtro.apenom) || 
      regex.test(filtro.pers_docum) ||
      regex.test(filtro.tall_deno) || 
      regex.test(filtro.ttip_deno) || 
      regex.test(filtro.ests_deno) ||
      regex.test(filtro.empr_deno) )
  }

  ordenarGrilla = (key) => {
    const registros = ordenarGrilla(key, this.state.registros);

    this.setState({ registros });
  }
  
  /*==========================================================================
   Completo datos de grilla principal 
  *==========================================================================*/
  poblarGrilla = () => {

    if(this.state.desde.length<8) {
      swal({ 
        title : `Consulta entre fechas `,
        text  : ' complete fecha <desde>',});
    }
    if(this.state.hasta.length<8) {
      swal({ 
        title : `Consulta entre fechas `,
        text  : ' complete fecha <hasta>',});
    }
    if(this.state.hasta < this.state.desde) {
      swal({ 
        title : `Consulta entre fechas `,
        text  : ' <hasta> no puede ser menor que <desde>',});
    }
    if(this.state.desde.length>8 && this.state.desde.length>8){
  
      this.setState({ fetchRegistros: true,
      });
      const sql = `${URL_DB}SEL_PERSONA_MENSAJES_HISTO('D','${this.state.desde}','${this.state.hasta}','${this.state.txt_en_msg}')`
      axios.get(sql)
        .then((response) => {
          this.setState({
            registros: response.data[0],
          })
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.setState({ fetchRegistros: false })
        })
    }
  }

  /*==========================================================================
   Abro MODAL para mostrar persona y responder 
  *==========================================================================*/
  handleModalEditEdit(regis) {
  
      this.setState({
        showModalEdit: true,

        pers_id    : regis.pers_id,
        pers_apenom: regis.apenom,
        pers_edad  : regis.edad,
        empr_regis : regis.empr_regis,
        empr_deno  : regis.empr_deno,
        ests_deno  : regis.ests_deno,
        erub_deno  : regis.erub_deno,
        ult_preg_f : regis.ult_preg_f,
        dias_preg  : regis.dias_preg,
        dias_preg_t: regis.dias_preg===0?'HOY':(regis.dias_preg===1?'1 dia':`${regis.dias_preg} dias`),
        ests_color : regis.ests_color,

        peme_texto : '',
  
        respError: '',
        mensajeAlerta: '',
        mensajeColor: 'N'
      })
      //this.cargarPreguntas(regis.pers_id);

  }

  /* Opciones del menu, trato hombres, mujeres o todos */
  handleChangeOpc(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({[name]: value}, () => { 
      this.poblarGrilla() 
    });


  };
  
  
  handleModalExit() {
    this.setState({ showModalEdit: false })
    this.setState({
      mensajeAlerta : 'No se aplicaron cambios',
      mensajeColor  : 'red',
    })
  }



  /*==========================================================================
   RENDER
  *==========================================================================*/

  render() {  

    //const respError = this.state.respError;
    const mensajeAlerta = this.state.mensajeAlerta;
    const mensajeColor = this.state.mensajeColor;
    const registrosFiltrados = this.filtrarDatos();

    return(
      <div>        
        {mensajeAlerta.length >1 ? <Notifications mensaje={mensajeAlerta}
                                                  mensajeColor={mensajeColor}
                                    /> : '' }

        <Container  fluid="true">
          <Row>
            <Col xs={3} style={{textAlign: "right" }}>
                <h2><b>Todos los Mensajes</b></h2>
            </Col>
            <Col xs={2}>
              <Form.Group>
                <Form.Label><b>Desde</b></Form.Label>
                <Form.Control type="date" name="desde" value={this.state.desde} onChange={this.handleChangeOpc}
                                        style={{ fontWeight: "bold" }} />
              </Form.Group>
            </Col>
            <Col xs={2}>
              <Form.Group>
                <Form.Label><b>Hasta</b></Form.Label>
                <Form.Control type="date" name="hasta" value={this.state.hasta} onChange={this.handleChangeOpc}
                                        style={{ fontWeight: "bold" }} />
              </Form.Group>
            </Col>
            <Col xs={2}>
              <Form.Group>
                <Form.Label><b>Que contenga en msg</b></Form.Label>
                <FormControl type="text" name="txt_en_msg" placeholder="Buscar Texto en Msgs" 
                                        onChange={this.handleChangeOpc} /> 
              </Form.Group>
            </Col>
            <Col xs={2}>
              <Form.Group>
              <Form.Label><b>Buscar en esta grilla</b></Form.Label>
                <FormControl type="text" name="buscar" placeholder="Texto a filtrar" className="mr-sm-2" 
                        onChange={(e) => { this.setState({ filterGrilla: e.target.value })} } /> 
              </Form.Group>
            </Col>
            
            <Col xs={1}>
                <Button  variant='outline-warning' size='sm' onClick={() => {this.setState({ showhelp : true}) } } > 
                Ayuda
                </Button>
     {/**        <ExpGrillaExcelPDF showExportar =  {true}
                      tituloExporta = {'Listado de temas'}
                      sqlExporta= {`${URL_DB}SEL_MATERIA_TEMAS('D',null,null,${this.state.login_prof_id})`}
                
            />
            */}
      
           </Col>
          </Row>

          <Row>
            <Col>
              <Table striped bordered hover size="sm" id="data_table">
                <thead className="Grilla-header">
                <tr>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('pers_id')}>
                      #ID</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('apenom')}>
                      Apellido y nombre</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('edad')}>
                      Edad</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('pers_docum')}>
                      #Docum</Button>
                  </th> 
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('pers_sexo')}>
                      Sexo</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('tall_deno')}>
                      Talla</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ttip_deno')}>
                      Tipo Trabajo</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_regis')}>
                      Registrado</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_deno')}>
                      Empresa</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ests_deno')}>
                      Sts</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ult_preg_f')}>
                      F.Pregunta</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('dias_preg')}>
                      #Dias</Button>
                  </th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.fetchRegistros && 'Cargando...'
                }
                {
                  registrosFiltrados.map((regis) => {
                    return (
                      <tr key={regis.pers_id}>
                        <td>{regis.pers_id}</td>
                        <td style={{ textAlign: "left" }}>{regis.apenom}</td>
                        <td>{regis.edad}</td>
                        <td>{regis.pers_docum}</td>
                        <td>{regis.pers_sexo}</td>
                        <td style={{ textAlign: "left" }}>{regis.tall_deno}</td>
                        <td style={{ textAlign: "left" }}>{regis.ttip_deno}</td>
                        <td>{regis.empr_regis}</td>
                        <td style={{ textAlign: "left" }}>{regis.empr_deno}</td>
                        <td style={{ backgroundColor: regis.ests_color }}>{regis.ests_deno}</td>                        
                        <td>{regis.ult_preg_f}</td>      
                        <td>{regis.dias_preg}</td>        
                        <td>
   
                          <Button variant="success" size="sm" onClick={() => this.handleModalEditEdit(regis)}>
                            <i className="fas fa-comment-dots"></i></Button>
  
                        </td>
                      </tr>  
                    ) 
                  }) 
                }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>


        <Modal show={this.state.showModalEdit} onHide={this.handleModalExit} dialogClassName="modal-90">
          <Modal.Header closeButton>
            <Modal.Title>
              {`Responder a ${this.state.pers_apenom}  de ${this.state.pers_edad} años (#${this.state.pers_id})`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form>
          <Row>
              <Col xs={5}>
                  <div>
                    <b>{`Pregunta: ${this.state.dias_preg_t}`}</b>
                      {` (${this.state.ult_preg_f})`} 
                  </div>
              </Col>
              <Col xs={7}>
                  <div style={{ textAlign: "center", backgroundColor: this.state.ests_color }}>
                      <b>{this.state.empr_deno}</b>
                      {`  (${this.state.ests_deno}) `}
                      <b>{this.state.erub_deno}</b>
                      {`  desde ${this.state.empr_regis}`} 
                  </div>
              </Col>
          </Row>
          <br />
          <Row>
              <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                  <Tab eventKey="home" title="Ficha persona">
                    
                      <FichaPersona 
                          pers_id = {this.state.pers_id}/>
                    
                  </Tab>
                  <Tab eventKey="tab3" title="Historico de mensajes">
                    
                      <MensajesPersona
                        pers_id = {this.state.pers_id}
                        tiempo = 'H' />
                    
                  </Tab>
              </Tabs>
            </Row>
          </Form>
          </Modal.Body>
        </Modal>



        < Modal show={this.state.showhelp} onHide={() => { this.setState({ showhelp : false}) } } dialogClassName="modal-90">
            <AyudaAdmi clave={'admin'}/>
        </Modal>


      </div>
    );
  }
}

export default PanelMensajeHisPage;
