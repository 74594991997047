import React from 'react';
import { NOMBRE_SIST, URL_DB } from './../../constants';
import axios from 'axios';
import { Table, Row, Col, Container, Button, Form, FormControl, Tab, Nav, Card } from 'react-bootstrap';
//import ExpGrillaExcelPDF from '../../components/ExpGrillaExcelPDF';
import swal from 'sweetalert';
import Notifications from '../../components/Notifications';
import ordenarGrilla from './../../utils/functions/ordenar-grilla'
import '../stylePages.css';
//import Ayuda from '../../components/Ayuda';
//import DialogComponent from '../../components/Dialog';
//import BootBox from 'react-bootbox';

class PanelSistemaUsoPage extends React.Component {
  constructor(props) {
    super(props);

    var currentdate = new Date();

    this.state = {
      desde : currentdate.toISOString().split('T')[0],
      hasta : currentdate.toISOString().split('T')[0],
      txt_en_msg : '',

      situEmpresa: [],
      rubrosEmpresa: [],
      empresas: [],
      tiptrabPersona: [],
      tallaPersona: [],
      sexoPersona: [],
      paises: [],
      empr_alta_f_des:'',
      empr_alta_f_has:'',
      edad_des:'',
      edad_has:'',
      alerta_activada:'N',
      alerta_desacti:'N',
      pers_docum:'',
      pers_apellido:'',
      color_fon_msg: '',

      registrosEmpresas: [],
      registrosPersonas: [],

      registros: [],
      preguntas: [],
      peme_texto: '',
      buscarGrillaValue: '',
      login_admi_id: sessionStorage.getItem('USU_ADMI_ID'),
   
      showhelp : false,
      filterGrilla: '',
      fetchRegistros: false,
      respuestaSp: [],
      respError: '',
      mensajeAlerta: '',
    };

    this.ordenarGrilla          = this.ordenarGrilla.bind(this);
    this.handleChange           = this.handleChange.bind(this);
    this.handleChangeLista      = this.handleChangeLista.bind(this);
    this.handleEnvioMasivo      = this.handleEnvioMasivo.bind(this);
  }


  async getSituEmpresa() {
    const sql = `${URL_DB}SEL_USO_SISTEMA_FILTROS('D',1)`
      axios.get(sql)
        .then((response) => {
          this.setState({
            situEmpresa: response.data[0],
          })
        })
        .catch((error) => console.log(error));
  }

  async getRubrosEmpresa() {
    const sql = `${URL_DB}SEL_USO_SISTEMA_FILTROS('D',2)`
      axios.get(sql)
        .then((response) => {
          this.setState({
            rubrosEmpresa: response.data[0],
          })
        })
        .catch((error) => console.log(error));
  }

  async getEmpresa() {
    const sql = `${URL_DB}SEL_USO_SISTEMA_FILTROS('D',3)`
      axios.get(sql)
        .then((response) => {
          this.setState({
            empresas: response.data[0],
          })
        })
        .catch((error) => console.log(error)); 
  }

  async getTipTrabPersona() {
    const sql = `${URL_DB}SEL_USO_SISTEMA_FILTROS('D',4)`
      axios.get(sql)
        .then((response) => {
          this.setState({
            tiptrabPersona: response.data[0],
          })
        })
        .catch((error) => console.log(error));
  }

  async getSexoPersona() {      
    const sql = `${URL_DB}SEL_PERSONA_SEXO()`
      axios.get(sql)
        .then((response) => {
          this.setState({
            sexoPersona: response.data[0],
          })
        })
        .catch((error) => console.log(error));
  }

  async getTallas() {  
      const sql = `${URL_DB}SEL_PERSONA_CONTEXTURA()`
        axios.get(sql)
          .then((response) => {
            this.setState({
              tallaPersona: response.data[0],
            })
          })
          .catch((error) => console.log(error));
  }

  async getPaises() {  
      const sql = `${URL_DB} SEL_LOCGEO_PAISES()`
        axios.get(sql)
          .then((response) => {
            this.setState({
              paises: response.data[0],
            })
          })
          .catch((error) => console.log(error));
  }

  async getColor() {
      const sql = `${URL_DB}SEL_PARAMETROS('COLOR_FONDO_MSG')`
        axios.get(sql)
          .then((response) => {
            this.setState({
              color_fon_msg: response.data[0][0].para_char,
            })
          })
          .catch((error) => console.log(error))
  }

  async getInicio() {
    try{
      await Promise.all([ this.getSituEmpresa(), this.getRubrosEmpresa(), this.getEmpresa(), 
                          this.getTipTrabPersona(), this.getSexoPersona(), this.getTallas(), 
                          this.getPaises(), this.getColor() ])
    } catch(e) {
      console.log(e);
    } finally {
      this.setState({ fetchRegistros: false });
    }
  }
  
  
  componentDidMount() {
    this.getInicio();
  }
        
  /*==========================================================================
   Utilidades de filtros y sort para la grilla ppal 
  *==========================================================================*/
  filtrarDatos() {
    const escapedValue = this.state.filterGrilla.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedValue, 'i');
    return this.state.registros.filter(filtro => 
      regex.test(filtro.apenom) || 
      regex.test(filtro.pers_docum) ||
      regex.test(filtro.tall_deno) || 
      regex.test(filtro.ttip_deno) || 
      regex.test(filtro.ests_deno) ||
      regex.test(filtro.empr_deno) )
  }

  ordenarGrilla = (key) => {
    const registros = ordenarGrilla(key, this.state.registros);

    this.setState({ registros });
  }
  
  handleChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value })
  }

  handleChangeLista(event, a_codigo, a_lista) {
    
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    // lista de empresas
    if (a_lista==='sit') {
        const situEmpresa = this.state.situEmpresa  
        const ind = this.state.situEmpresa.findIndex(elem => elem.row_num === a_codigo);
        situEmpresa[ind].si_no = value===true?'S':'N';
        this.setState({ situEmpresa });
    }
    if (a_lista==='loc') {
      const paises = this.state.paises
      const ind = this.state.paises.findIndex( elem => elem.pais_id === a_codigo);
      paises[ind].si_no = value===true?'S':'N';
      this.setState({ paises });
    }
    if (a_lista==='rub') {
      const rubrosEmpresa = this.state.rubrosEmpresa
      const ind = this.state.rubrosEmpresa.findIndex( elem => elem.erub_id === a_codigo);
      rubrosEmpresa[ind].si_no = value===true?'S':'N';
      this.setState({ rubrosEmpresa });
    }
    if (a_lista==='emp') {
      const empresas = this.state.empresas
      const ind = this.state.empresas.findIndex( elem => elem.empr_id === a_codigo);
      empresas[ind].si_no = value===true?'S':'N';
      this.setState({ empresas });
    }

    // lista de personas
    if (a_lista==='sex') {
      const sexoPersona = this.state.sexoPersona
      const ind = this.state.sexoPersona.findIndex( elem => elem.row_num === a_codigo);
      sexoPersona[ind].si_no = value===true?'S':'N';
      this.setState({ sexoPersona });
    }
    if (a_lista==='tal') {
      const tallaPersona = this.state.tallaPersona
      const ind = this.state.tallaPersona.findIndex( elem => elem.tall_id === a_codigo);
      tallaPersona[ind].si_no = value===true?'S':'N';
      this.setState({ tallaPersona });
    }
    if (a_lista==='tip') {
      const tiptrabPersona = this.state.tiptrabPersona
      const ind = this.state.tiptrabPersona.findIndex( elem => elem.row_num === a_codigo);
      tiptrabPersona[ind].si_no = value===true?'S':'N';
      this.setState({ tiptrabPersona });
    }
   
  }
  
  handleSelectTab = (key) => {

    var sql = '';

    // En base a datos sel tab1 y tab2 se arma un informe 
    if(key === 'tercero' || key === 'cuarto'){

      this.setState({ fetchRegistros: true })
   
      // Empresas
      const sin_empre   = this.state.situEmpresa.filter(elem => elem.si_no==='S' &&
                                                                elem.codigo===0).length===0?'N':'S';
      const lista_ests  = this.state.situEmpresa.filter(elem => elem.si_no==='S' &&
                                                                elem.tabla==='ests')
                                                .map(elem => elem.codigo).toString();
      const lista_baem  = this.state.situEmpresa.filter(elem => elem.si_no==='S' &&
                                                                elem.tabla==='baem')
                                                .map(elem => elem.codigo).toString();
      const lista_pais  = this.state.paises.filter(elem => elem.si_no==='S')
                                                .map(elem => elem.pais_id).toString();
      const lista_erub  = this.state.rubrosEmpresa.filter(elem => elem.si_no==='S')
                                                .map(elem => elem.erub_id).toString();
      const lista_empr  = this.state.empresas.filter(elem => elem.si_no==='S')
                                                .map(elem => elem.empr_id).toString();

      // personas
      const lista_sexo  = this.state.sexoPersona.filter(elem => elem.si_no==='S')
                                                .map(elem => elem.sexo).toString();
      const lista_tall  = this.state.tallaPersona.filter(elem => elem.si_no==='S')
                                                .map(elem => elem.tall_id).toString();
      const lista_ttip  = this.state.tiptrabPersona.filter(elem => elem.si_no==='S')
                                                .map(elem => String(elem.ttip_id)+'#'+String(elem.raeu_id)).toString();
      
      var salidaPerEmp = 'E';
      if(key === 'cuarto'){ salidaPerEmp = 'P' }

    sql = `${URL_DB}SEL_USO_SISTEMA_TRAER('D','${salidaPerEmp}','${sin_empre}','${lista_ests}','${lista_baem}',
'${lista_pais}','${lista_erub}','${lista_empr}','${this.state.empr_alta_f_des}','${this.state.empr_alta_f_has}',
'${lista_sexo}','${lista_tall}','${lista_ttip}','${this.state.edad_des}','${this.state.edad_has}',
'${this.state.alerta_activada}','${this.state.alerta_desacti}','${this.state.pers_docum}','${this.state.pers_apellido}')`;
    
    }
    // Si selecciona el 3 tab son los resultados por empresa
    if(key === 'tercero') {
      // ============Solapa de Empresas============
      axios.get(sql)
      .then((response) => {
        this.setState({
          registrosEmpresas: response.data[0],
        })
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.setState({ fetchRegistros: false })
      });

    } else if(key === 'cuarto') {
      // ============Solapa de Personas============
      axios.get(sql)
      .then((response) => {
        this.setState({
          registrosPersonas: response.data[0],
        })
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.setState({ fetchRegistros: false })
      });
 
    }

  }
  
  handleEnvioMasivo() {
  
    if(this.state.registrosPersonas.length===0) {
      swal({ 
        title : `Envio masivo de mensaje `,
        text  : 'No hay personas seleccionadas...',
        icon  : "error",  
        button: "Aceptar",
        timer : "3000"});
        return
    }
    if(this.state.peme_texto.length<20) {
      swal({ 
        title : `Envio masivo de mensaje `,
        text  : 'Error: Mensaje menor a 20 caracteres',
        icon  : "error",  
        button: "Aceptar",
        timer : "3000"});
        return
    }

    swal(`Para confirmar envio de ${this.state.registrosPersonas.length} mensajes digite palabra 'CONFIRMA' :`, {
      content: "input",
    })
    .then((value) => {
      if (value.toUpperCase()==='CONFIRMA') {

        alert('FALTA DESARROLLAR aca va la rutima de envio')

        swal({ 
          title : `Envio masivo de mensaje `,
          text  : `${this.state.registrosPersonas.length} mensajes enviados:`,
          icon  : "success",  
          button: "Aceptar",
          timer : "3000"});

      } else {

        swal({ 
          title : `Envio masivo de mensaje `,
          text  : 'Error: No se confirmo palabra de seguridad',
          icon  : "error",  
          button: "Aceptar",
          timer : "3000"});  
      }

    }); 

  }


  /*==========================================================================
   RENDER
  *==========================================================================*/

  render() {  

    //const respError = this.state.respError;
    const mensajeAlerta = this.state.mensajeAlerta;
    const mensajeColor = this.state.mensajeColor;
    //const registrosFiltrados = this.filtrarDatos();

    return(
      <div>        
        {mensajeAlerta.length >1 ? <Notifications mensaje={mensajeAlerta}
                                                  mensajeColor={mensajeColor}
                                    /> : '' }

        <Container  fluid="true">
          <Row>
            <Col xs={3} style={{textAlign: "right" }}>
                <h2><b>Uso del sistema</b></h2>
            </Col>
            <Col xs={4}>
            </Col>
            <Col xs={4} style={{textAlign: "left", color: "#0066ff", fontSize: 10}}>
                <li>Utilice los botones azules del margen izquierdo</li>
                <li>Complete datos a filtrar en las dos primeras solapas, el resultado sale autom en la 3ra y 4ta</li>
                <li>Puede enviar mensaje masivamente en base a resultados de la 4ta.solapa</li>
            </Col>
          </Row>

          <Row>
            
            <Col>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first" onSelect={this.handleSelectTab}>
              <Row>
                <Col sm={1}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Filtro Empresas</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="segundo">Filtro Persona</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="tercero" >Result p/empresa</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="cuarto">Result Personas</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="quinto">Enviar Mensaje</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={11}>
                  <Tab.Content className="borde_solido">
                    <Tab.Pane eventKey="first">
                      <Row> 
                        <Col style={{ color: "#0066ff",textAlign: "left" }}>
                          (*) En todos los casos, si no selecciona algun item, incluye todos
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={3}>
                            <Row>
                               <Col>
                                  <Card>
                                    <Card.Header>Situación empresas</Card.Header>
                                    <Card.Body>
                                    {  this.state.situEmpresa.map((sit) => {
                                            return (
                                            <React.Fragment>
                                              <Row>
                                                <Col>
                                                <Form.Group >
                                                  <Form.Check 
                                                          defaultChecked={false} 
                                                          type={'checkbox'}
                                                          id={`${sit.row_num}`}
                                                          key={`${sit.row_num}`}
                                                          label={ sit.deno } 
                                                          style={{ backgroundColor: sit.b_color, textAlign: "left"}}
                                                          onChange={(e) => this.handleChangeLista(e, sit.row_num,'sit')} /> 
                                                </Form.Group>
                                                </Col>                                
                                            </Row>
                                            </React.Fragment>
                                            ) 
                                          }) 
                                    }  
                                    </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                         
                            <Row>
                               <Col>
                                  <br />
                                  <Card>
                                    <Card.Header>Paises</Card.Header>
                                    <Card.Body>
                                    {  this.state.paises.map((loc) => {
                                            return (
                                            <React.Fragment>
                                              <Row>
                                                <Col>
                                                <Form.Group >
                                                  <Form.Check 
                                                          defaultChecked={false} 
                                                          type={'checkbox'}
                                                          id={`${loc.pais_id}`}
                                                          key={`${loc.pais_id}`}
                                                          label={ loc.pais_deno } 
                                                          style={{ textAlign: "left"}}
                                                          onChange={(e) => this.handleChangeLista(e, loc.pais_id,'loc')} /> 
                                                </Form.Group>
                                                </Col>                                
                                            </Row>
                                            </React.Fragment>
                                            ) 
                                          }) 
                                    }  
                                    </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={3}>
                            <Row>
                                <Col>
                                  <Card>
                                    <Card.Header>Rubro empresas</Card.Header>
                                    <Card.Body>
                                    {  this.state.rubrosEmpresa.map((rub) => {
                                            return (
                                            <React.Fragment>
                                              <Row>
                                                <Col>
                                                <Form.Group >
                                                  <Form.Check 
                                                          defaultChecked={false} 
                                                          type={'checkbox'}
                                                          id={`${rub.erub_id}`}
                                                          key={`${rub.erub_id}`}
                                                          label={ rub.erub_deno } 
                                                          style={{ textAlign: "left"}}
                                                          onChange={(e) => this.handleChangeLista(e,rub.erub_id,'rub')} /> 
                                                </Form.Group>
                                                </Col>                                
                                            </Row>
                                            </React.Fragment>
                                            ) 
                                          }) 
                                  }  
                                  </Card.Body>
                                  </Card>
                                </Col>
                            </Row>
                            <Row>
                               <Col>
                                  <br />
                                  <Card>
                                    <Card.Header>Con Fecha de Alta</Card.Header>
                                    <Card.Body>
                                      <Row>
                                        <Col xs={2}>
                                            <Form.Label>Desde</Form.Label>
                                        </Col>
                                        <Col xs={10}>
                                            <Form.Control type="date" name="empr_alta_f_des" 
                                                          value={this.state.empr_alta_f_des} 
                                                          onChange={this.handleChange} />
                                        </Col>
                                      </Row>
                                      <Row>  
                                        <Col xs={2}>
                                            <Form.Label>Hasta</Form.Label>
                                        </Col>
                                        <Col xs={10}>
                                            <Form.Control type="date" name="empr_alta_f_has" 
                                                          value={this.state.empr_alta_f_has} 
                                                          onChange={this.handleChange} />
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={5}>
                            <Card>
                              <Card.Header>Empresas</Card.Header>
                              <Card.Body>
                              {  this.state.empresas.map((emp) => {
                                      return (
                                      <React.Fragment>
                                        <Row>
                                          <Col>
                                          <Form.Group >
                                            <Form.Check 
                                                    defaultChecked={false} 
                                                    type={'checkbox'}
                                                    id={`${emp.empr_id}`}
                                                    key={`${emp.empr_id}`}
                                                    label={ emp.empr_razon } 
                                                    style={{ textAlign: "left"}}
                                                    onChange={(e) => this.handleChangeLista(e, emp.empr_id,'emp')} /> 
                                          </Form.Group>
                                          </Col>                                
                                      </Row>
                                      </React.Fragment>
                                      ) 
                                    }) 
                            }  
                            </Card.Body>
                            </Card>
                        </Col>
                      </Row>
                    </Tab.Pane>

                    <Tab.Pane eventKey="segundo" >
                        <Row> 
                            <Col style={{ color: "#0066ff",textAlign: "left" }}>
                              (*) En todos los casos, si no selecciona algun item, incluye todos
                            </Col>
                          </Row>
                        <Row>
                        <Col xs={3}>
                            <Row>
                               <Col>
                                  <Card>
                                    <Card.Header>Edad Personas</Card.Header>
                                    <Card.Body>
                                        <Row>
                                          <Col xs={1}>
                                          <Form.Label>De</Form.Label>
                                          </Col>
                                          <Col xs={4}>
                                          <Form.Control type="number" name="edad_des" 
                                                        value={this.state.edad_des} 
                                                        onChange={this.handleChange} />
                                          </Col>
                                          <Col xs={1}>
                                          <Form.Label>a</Form.Label>
                                          </Col>
                                          <Col xs={4}>
                                          <Form.Control type="number" name="edad_has" 
                                                        value={this.state.edad_has} 
                                                        onChange={this.handleChange} />
                                          </Col>
                                        </Row>
                                    </Card.Body>
                                  </Card>
                                </Col>
                            </Row>
                         
                            <Row>
                               <Col>
                                  <Card>
                                    <Card.Header>Sexo Personas</Card.Header>
                                    <Card.Body>
                                    {  this.state.sexoPersona.map((sex) => {
                                            return (
                                            <React.Fragment>
                                              <Row>
                                                <Col>
                                                <Form.Group >
                                                  <Form.Check 
                                                          defaultChecked={false} 
                                                          type={'checkbox'}
                                                          id={`${sex.row_num}`}
                                                          key={`${sex.row_num}`}
                                                          label={ sex.sexo_deno }
                                                          style={{ textAlign: "left"}}
                                                          onChange={(e) => this.handleChangeLista(e, sex.row_num,'sex')} /> 
                                                </Form.Group>
                                                </Col>                                
                                            </Row>
                                            </React.Fragment>
                                            ) 
                                          }) 
                                    }  
                                    </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                               <Col>
                                  <Card>
                                    <Card.Header>Contextura Personas</Card.Header>
                                    <Card.Body>
                                    {  this.state.tallaPersona.map((tal) => {
                                            return (
                                            <React.Fragment>
                                              <Row>
                                                <Col>
                                                <Form.Group >
                                                  <Form.Check 
                                                          defaultChecked={false} 
                                                          type={'checkbox'}
                                                          id={`${tal.tall_id}`}
                                                          key={`${tal.tall_id}`}
                                                          label={ tal.tall_deno } 
                                                          style={{ textAlign: "left"}}
                                                          onChange={(e) => this.handleChangeLista(e, tal.tall_id,'tal')} /> 
                                                </Form.Group>
                                                </Col>                                
                                            </Row>
                                            </React.Fragment>
                                            ) 
                                          }) 
                                    }  
                                    </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                               <Col>
                                  <Card>
                                    <Card.Header>Uso de {NOMBRE_SIST}</Card.Header>
                                    <Card.Body>
                                    <Row>
                                      <Col>
                                      <Form.Group >
                                        <Form.Check 
                                                defaultChecked={false} 
                                                type={'checkbox'}
                                                name='alerta_activada'
                                                key={1}
                                                label={ 'Alertas ACTIVADA' } 
                                                style={{ backgroundColor: '#66ff66', textAlign: "left"}}
                                                onChange={(e) => this.setState({ alerta_activada: e.target.value?'S':'N' }) } />   
                                      </Form.Group>
                                      </Col>                                
                                    </Row>
                                    <Row>
                                      <Col>
                                      <Form.Group >
                                        <Form.Check 
                                                defaultChecked={false} 
                                                type={'checkbox'}
                                                name='alerta_desacti'
                                                key={1}
                                                label={ 'Alertas SIN activar' } 
                                                style={{ backgroundColor: '#ff8080', textAlign: "left"}}
                                                onChange={(e) => this.setState({ alerta_desacti: e.target.value?'S':'N' }) } /> 
                                      </Form.Group>
                                      </Col>                                
                                    </Row>
                                    </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={5}>
                            <Card>
                              <Card.Header>Tipo de trabajo Personas</Card.Header>
                              <Card.Body>
                              {  this.state.tiptrabPersona.map((ttip) => {
                                      return (
                                      <React.Fragment>
                                        <Row>
                                          <Col>
                                          <Form.Group >
                                            <Form.Check 
                                                    defaultChecked={false} 
                                                    type={'checkbox'}
                                                    id={`${ttip.row_num}`}
                                                    key={`${ttip.row_num}`}
                                                    label={ ttip.deno } 
                                                    style={{ textAlign: "left"}}
                                                    onChange={(e) => this.handleChangeLista(e, ttip.row_num, 'tip')} /> 
                                          </Form.Group>
                                          </Col>                                
                                      </Row>
                                      </React.Fragment>
                                      ) 
                                    }) 
                            }  
                            </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={3}>
                            <Row>
                               <Col>
                                  <Card>
                                    <Card.Header>Búsquedas puntuales</Card.Header>
                                    <Card.Body>
                                  <Row>
                                    <Col>
                                      <Form.Group>
                                      <Form.Label>Con #Docum</Form.Label>
                                      <Form.Control type="text" name="pers_docum" value={this.state.pers_docum} 
                                                onChange={this.handleChange} />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Form.Group>
                                      <Form.Label>Con Apellido</Form.Label>
                                      <Form.Control type="text" name="pers_apellido" value={this.state.pers_apellido} 
                                                  onChange={this.handleChange} />
                                      </Form.Group>
                                    </Col>
                                  </Row>

                                    </Card.Body>
                                  </Card>
                                </Col>
                            </Row>
                          </Col>
                      </Row>

                    </Tab.Pane>

                    <Tab.Pane eventKey="tercero">
{/**======================== Empresa ================================ */}
          <Row>
            <Col>
              <Table striped bordered hover size="sm" id="data_table">
                <thead >
                <tr>
                  <th colSpan={5} > Empresa </th>
                  <th colSpan={3} > Cant vistas App </th>
                  <th colSpan={2} > Mensajeria </th>
                </tr>
                <tr className="Grilla-header">
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_id')}>
                      #ID</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_razon')}>
                      Razon Social</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_fanta')}>
                      Fantasia</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ests_deno')}>
                      Status</Button>
                  </th> 
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('erub_deno')}>
                      Rubro</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('vconsu_pevi_ult_sema')}>
                      Ult Sem</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('vconsu_pevi_ult_mes')}>
                      Ult mes</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('vconsu_pevi_total')}>
                      Total</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('vconsu_canti_msg_mes')}>
                      Ult mes</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('vconsu_canti_msg_total')}>
                      Total</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_cuit')}>
                      cuit</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_cant_emple')}>
                      #Empl</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_alta_f')}>
                      Alta</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_baja_f')}>
                      Baja</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('baem_deno')}>
                      Mot Baja</Button>
                  </th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.fetchRegistros && 'Cargando...'
                }
                {
                  this.state.registrosEmpresas.map((regemp) => {
                    return (
                      <tr key={regemp.empr_id}>
                        <td style={{ textAlign: "left" }}>{regemp.empr_id}</td>
                        <td style={{ textAlign: "left" }}>{regemp.empr_razon}</td>
                        <td style={{ textAlign: "left" }}>{regemp.empr_fanta}</td>
                        <td style={{ textAlign: "left" }}>{regemp.ests_deno}</td>
                        <td style={{ textAlign: "left" }}>{regemp.erub_deno}</td>
                        <td style={{ textAlign: "center" }}>{regemp.vconsu_pevi_ult_sema}</td>
                        <td style={{ textAlign: "center" }}>{regemp.vconsu_pevi_ult_mes}</td>
                        <td style={{ textAlign: "center" }}>{regemp.vconsu_pevi_total}</td>
                        <td style={{ textAlign: "center" }}>{regemp.vconsu_canti_msg_mes}</td>
                        <td style={{ textAlign: "center" }}>{regemp.vconsu_canti_msg_total}</td>
                        <td style={{ textAlign: "left" }}>{regemp.empr_cuit}</td>
                        <td style={{ textAlign: "left" }}>{regemp.empr_cant_emple}</td>
                        <td style={{ textAlign: "left" }}>{regemp.empr_alta_f}</td>
                        <td style={{ textAlign: "left" }}>{regemp.empr_baja_f}</td>
                        <td style={{ textAlign: "left" }}>{regemp.baem_deno}</td>
                      </tr>  
                    ) 
                  }) 
                }
                </tbody>
              </Table>
            </Col>
          </Row>
{/* =============================================================================0 */}
                    </Tab.Pane>

                    <Tab.Pane eventKey="cuarto">
{/**======================== Persona ================================ */}
          <Row>
            <Col>
              <Table striped bordered hover size="sm" id="data_table">
                <thead >
                <tr>
                  <th colSpan={6} > Datos Personales </th>
                  <th colSpan={4} > Cant vistas App </th>
                  <th colSpan={2} > Mensajeria </th>
                  <th> Persona </th>
                  <th> Empresa </th>
                </tr>
                <tr className="Grilla-header">
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('pers_id')}>
                      #ID</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('apenom')}>
                      Apellido y nombre</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('edad')}>
                      Edad</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('pers_sexo')}>
                      Sexo</Button>
                  </th> 
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('tall_deno')}>
                      Talle</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ttip_deno')}>
                      Tipo Trabajo</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('pers_ini_ciclo_f')}>
                      Ini Ciclo</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('vconsu_pevi_ult_sema')}>
                      Ult Sem</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('vconsu_pevi_ult_mes')}>
                      Ult mes</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('vconsu_pevi_total')}>
                      Total</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('vconsu_canti_msg_mes')}>
                      Ult mes</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('vconsu_canti_msg_total')}>
                      Total</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('pers_docum')}>
                      #Docum</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_razon')}>
                      Empresa</Button>
                  </th>     
                </tr>
                </thead>
                <tbody>
                {
                  this.state.fetchRegistros && 'Cargando...'
                }
                {
                   this.state.registrosPersonas.map((regper) => {
                    return (                 
                      <tr key={regper.pers_id}>
                        <td style={{ textAlign: "left" }}>{regper.pers_id}</td>
                        <td style={{ textAlign: "left" }}>{regper.apenom}</td>
                        <td style={{ textAlign: "center" }}>{regper.edad}</td>
                        <td style={{ textAlign: "center" }}>{regper.pers_sexo}</td>
                        <td style={{ textAlign: "left" }}>{regper.tall_deno}</td>
                        <td style={{ textAlign: "left" }}>{regper.ttip_deno}</td>
                        <td style={{ textAlign: "center" }}>{regper.pers_ini_ciclo_f}</td>
                        <td style={{ textAlign: "center" }}>{regper.vconsu_pevi_ult_sema}</td>
                        <td style={{ textAlign: "center" }}>{regper.vconsu_pevi_ult_mes}</td>
                        <td style={{ textAlign: "center" }}>{regper.vconsu_pevi_total}</td>
                        <td style={{ textAlign: "center" }}>{regper.vconsu_canti_msg_mes}</td>
                        <td style={{ textAlign: "center" }}>{regper.vconsu_canti_msg_total}</td>
                        <td style={{ textAlign: "left" }}>{regper.pers_docum}</td>
                        <td style={{ textAlign: "left" }}>{regper.empr_razon}</td>
                      </tr>  
                    ) 
                  }) 
                }
                </tbody>
              </Table>
            </Col>
          </Row>
{/* =============================================================================0 */}
                    </Tab.Pane>

                    <Tab.Pane eventKey="quinto">
                      <Row>
                        <br />
                      </Row>
                      <Row>
                        <Col xs={1}>
                          <i style={{ color: this.state.color_fon_msg }} className="fas fa-user-graduate"></i>
                          <b>Envio masivo:</b>
                        </Col>
                        <Col xs={10}>
                          <Form.Group>
                            <FormControl as="textarea"  name="peme_texto"
                                                  defaultValue={this.state.peme_texto} 
                                                  style={{ backgroundColor: this.state.color_fon_msg }}
                                                  placeholder={`Escribe tu respuesta aqui`}
                                                  onChange={this.handleChange} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={2}>
                          <Button variant="success" size="sm" onClick={this.handleEnvioMasivo}>
                          <i className="fas fa-comment-dots"></i>{` Envio masivo`}</Button>
                        </Col>
                        <Col xs={7} style={{ textAlign: "left" }}>
                          {this.state.registrosPersonas.length===0?
                            'No hay personas a donde enviar el mensaje': 'Se enviará este mensaje a las personas de la solapa anterior' }
                        </Col>
                      </Row>
                      <br />
                    </Tab.Pane>

                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
            </Col>
          </Row>
        </Container>


      </div>
    );
  }
}

export default PanelSistemaUsoPage;
