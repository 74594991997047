import React from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import hlp_ergoapp_uml from './../../assets/images/hlp_ergoapp_uml.jpg';
import hlp_ergoapp_rutina from './../../assets/images/hlp_ergoapp_rutina.jpg';
import hlp_ergoapp_empresa from './../../assets/images/hlp_ergoapp_empresa.jpg';
import hlp_ergoapp_empre_cnf from './../../assets/images/hlp_ergoapp_empre_cnf.jpg';
import ergoApp_logo from './../../assets/images/ErgoApp_Logo.jpg';
import { NOMBRE_SIST } from './../../constants';


class AyudaAdmi extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        clave : props.clave, 
        imagen : '',
        imagen_sec : '',
        clave_ant : '',
        clave_prx : '',
        html_help : '',
      }; 
      this.select_tema = this.select_tema.bind(this)
    }


    select_tema(a_clave) {

        this.setState({ clave : a_clave }) ;

        if (a_clave==='*') {
            this.setState({ titulo : `Funcionamiento general de ${NOMBRE_SIST}`,
                            imagen : hlp_ergoapp_uml,
                            imagen_sec : '',
                            clave_ant : '',
                            clave_prx : 'config-rutina',
                            html_help : `
            Nuestra App esta orientada a la Pausa Activa, para eso tenemos tres entidades :
            <ul>
            <li><b>Persona:</b> Usuario físico que recibe una rutina personalizada dependiendo los datos que ingresa</li>
            <li><b>Empresa:</b> La empresa cliente mantiene una nómina de personas las cuales recibe un reporte en forma automática</li>
            <li><b>Profesionales ${NOMBRE_SIST}</b> : Configuran las rutinas y responden consultas de las personas</li>
            </ul>
            
            <b>Lista de eventos</b><br>
            Esta herramienta se ajusta automáticamente a lo que la persona defina como su actividad y descripción personal, que ellos mismos configuran.
            El objetivo es suministrar rutinas dinámicas por semanas, para que el físico se relaja adecuadamente.
            <ul>
            <li><b>La persona se registra en la App:</b>
            Al completar el form <b>Datos del Usuario</b> el sistema los absorbe para auto-configurarse:<br>
            - Nombre/Apellido/Docum: Sirve para identificar al usuario y poder responderle.<br>
            - Datos de tipo de trabajo, edad, contextura, sexo y actividades extracurriculares: Sirve para determinar la rutina.<br>
            - Días y horario de trabajo: Sirve para el funcionamiento de las alertas, si eso se desea.<br>
            Todos los datos son importantes para que el profesional puede responer consultas
            </li>
            
            <li><b>El sistema responde de inmediato sugiriendo la rutina:</b>
            Con los datos completos ya puede realizar la rutina sugerida. Puede definir cuántas alertas desea recibir por día laboral. 
            </li>
        
            <li><b>La empresa completa la nómina:</b>
            Si la empresa-cliente completa la nómina de su personal, registrando el #Documento, la persona que completó ese dato, se considerará subscripta.
            Al estar asociado, puede enviar mensajes a nuestros profesionales y recibir respuestas personalizadas.
            </li>
     
            `});
        }

        if (a_clave==='config-rutina') {
            this.setState({ titulo : `Conceptos para configurar ${NOMBRE_SIST}`,
            imagen : hlp_ergoapp_rutina,
            imagen_sec : '',
            clave_ant : '*',
            clave_prx : 'empresa-cliente',
            html_help : `
El sistema plantea rutinas personalizadas por estas caracteristas :
<ul>
<li><b>Tipo de trabajo</b> : Define la actividad a desarrollar </li>
<li><b>Peso movido/ Tiempo de esfuerzo</b> : Acentúa o mitiga la actividad</li>
<li><b>Rango etario</b> : Las rutinas dependen de la edad</li>
<li><b>Sexo y Talla</b> : Se tiene en cuenta para los tiempos de ejercitacion</li>
<li><b>Actividad extracurricular</b> : No es lo mismo un deportita, de un sedentario</li>
</ul>

Esta herramienta se ajusta automáticamente a lo que la persona defina como su actividad y descripción personal, que ellos mismos configuran.
El objetivo es suministrar rutinas dinámicas por semanas, para que el físico se relaje adecuadamente.

<b>Definicion de cada rutina</b>
<ul>
<li><b>Ejercicio:</b>
Se define un ejercicio unitario, con detalles de como se ejecuta correctamente y se le adjunta un video 
</li>

<li><b>Ciclo:</b>
Es una secuencia de ejercicios, recomendada por cantidad de repeticiones y descansos.
Este grupo de ejercicios tiene un orden y se coloca en un ciclo.
Lo podemos colocar en un solo ciclo, con lo cual, la persona siempre hará la misma secuencia de ejercicios. 
Si queremos que cada N semanas vayan variando los mismos, definiremos otro grupo en otro ciclo, en la misma rutina.
</li>

<li><b>Rutina:</b>
Es la unidad de ciclos que definiremos para cada Tipo de trabajo/ Unidad de esfuerzo/ Edad/ Sexo/ Talla/Activ extra.
Puede contener un solo ciclo, y la cantidad de semanas en eset caso no seria relevante.
</li>

`});

        }

        if (a_clave==='empresa-cliente') {
            this.setState({ titulo : `Configurar empresa y su personal ${NOMBRE_SIST}`,
            imagen : hlp_ergoapp_empresa,
            imagen_sec : hlp_ergoapp_empre_cnf,
            clave_ant : 'config-rutina',
            clave_prx : '*',
            html_help : `
Del lado de la App, podemos señalar el estado de la empresa, la cual determina si su nómina goza de la atencion personalizada
si el estado es 'Activo':

Del lado de la empresa, debe completar la nómina, que dara acceso a los beneficios de la mensajeria.
Puede completarla manualmente o importar una lista excel.
`});

        }


        if (a_clave==='admin') {
            this.setState({ titulo : `Funcionamiento de Forms para Administrar ${NOMBRE_SIST}`,
            imagen : ergoApp_logo,
            imagen_sec : '',
            clave_ant : '*',
            clave_prx : '*',
            html_help : `
El sistema cuenta de tres componentes en su universo:<br>
- <b>El usuario</b> : Se auto-administra, completando sus datos<br>
- <b>Empresa cliente</b> : Sube y mantiene su nómina de beneficiarios<br>
- <b>El administrador</b>: Nosotros, debemos hacer funcionar todo con estas opciones:<br>
<ul>
    <li> <b>Parte de Ejercicios</b>
        <ul>
            <li><u>Definir Ejercicios</u>: Se definen ejercicios unitarios con explicación y video.</li>
            <li><u>Definir Rutinas y ciclos</u>: Se crean rutinas agrupando ejercicios dependiendo el alcance por cada grupo de personas.</li>
            <li><u>Asignar rutinas a Tipo Trabajo</u>: Las rutinas se asignan por cada tipo de persona, que es el producto cartesiano entre Tipo_trabajo/ Rangos_peso_tiempo/ Activ_personal/ Sexo/ Talla </li>
        </ul>    
    </li>

    <li> <b>Parte de clientes (empresas)</b>
        <ul>
            <li><u>Administrar Empresas cliente</u>: En este form podemos cargar las empresas clientes con todos sus contactos.</li>
            -<b>Vigentes</b>: Tiene estados <b>Activo/ En demo / Deudor</b> que determinan nuestra acción comercial, pero todos se consideran vigentes para responder mensajería<br>
            -Vigente <b>Prospecto</b>: Es una lista de posibles clientes que no tienen acceso a la mensajería.<br>
            -<b>Dadas de Baja</b>: Registramos el motivo para saber si la podemos recuperar en un futuro<br>
        </ul>    
    </li>
  
    <li> <b>Parte de Mensajeria</b>
        <ul>
            <li><u>Responder mensajería recibida</u>: Es un panel donde podemos responder los mensajes de los abonados, con acceso a la ficha de la persona, para responder adecuadamente con la info que tenemos registrada.</li>
            <li><u>Consulta de Todos los Mensajes</u>: Solo es una consulta y la podemos hacer entre fechas y con alguna pista del tema o palabra escrita</li>
            <li><u>Uso del sistema ( y envío mensajes )</u>: Estadística de uso del sistema. Podemos agrupar personas por su definición y de paso mandar un mensaje global para todos ellos.<br>
            Tenemos un <b>filtro</b> por <b>empresas</b> y otro por tipo de <b>personas</b>, los resultados los tenemos por empresa y la lista de personas que cumplen esas condiciones.<br>
            Por ejemplo: Para todas las empresas que fabrican alimentos, que sean mujeres, que utilizan las alertas, y que estan de pie más de 4 horas</li>
        </ul>    
    </li>

    <li> <b>Uso interno</b>
    <ul>
        <li><u>Relación interna TipoTrab-Rangos Rutina</u>: Sólo es una vista del producto cartesiano de los tipos de trabajo, como impacta en las rutinas</li>
        <li><u>Definir Tipo de Trabajo</u>: Se pueden agregar o cambiar tipos de trabajo, tener en cuenta que se dispara la cantidad de rutinas a asignar luego, mas de 50 rutinas por cada reg agregado.</li>
        <li><u>Definir ayuda para personas</u>: Es el texto que verán las personas al solicitar ayuda</li>
        <li><u>Emular usuario</u>: Podemos ver cómo el usuario ve el sistema desde su dispositivo.</li>
    </ul>    
</li>

</ul>
`});

        }

    }
        

    

    componentDidMount() {
        this.select_tema(this.state.clave)
    }

    render() {

        return (
           
            <div>
            <Modal.Header closeButton>
            <Modal.Title>Ayuda : {this.state.titulo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={3}>
                        <img  style={{ width: '100%' }} 
                                src={this.state.imagen} 
                                alt={'imagen_hlp'} />
                    </Col>
                    <Col xs={9}>
                        <Row>
                            <Col>
                                <div className="note-body"
                                    dangerouslySetInnerHTML={{__html: this.state.html_help }}>
                                </div>
                            </Col>
                        </Row>
                    {this.state.imagen_sec!=='' &&
                        <Row>
                            <Col>
                                <img  style={{ width: '80%' }} 
                                    src={this.state.imagen_sec} 
                                    alt={'imagen_sec_hlp'} />
                            </Col>
                        </Row>
                    }
                    </Col>
                </Row>

                <Button variant={'secondary'} size="sm" 
                        onClick={(e)=>{ this.select_tema('*') }}> 
                   <i className="fas fa-info"></i> Gral
                </Button>
                <Button variant={'warning'} size="sm" 
                        onClick={(e)=>{ this.select_tema(this.state.clave_ant) }}> 
                     <i className="fas fa-hand-point-left"></i> Anterior
                </Button>
                <Button variant={'warning'} size="sm" 
                        onClick={(e)=>{ this.select_tema(this.state.clave_prx) }}> 
                    Próxima <i className="fas fa-hand-point-right"></i>
                </Button>
            </Modal.Body>    


            </div>
  

        )
    }
}

export default AyudaAdmi;

