import React from 'react';
import { URL_DB } from './../../constants';
import axios from 'axios';
import { Table, Row, Col, Container, Button, Modal, Form, Alert, FormControl } from 'react-bootstrap';
//import ExpGrillaExcelPDF from '../../components/ExpGrillaExcelPDF';
import swal from 'sweetalert';
import Notifications from '../../components/Notifications';
import ordenarGrilla from './../../utils/functions/ordenar-grilla'
import '../stylePages.css';
import ShowAlerta from '../../components/Show-alerta';
import Ayuda from '../../components/Ayuda-pers';

class MensajeriaPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opciones_id : 'U',
      empresa: [],
      registros: [],
      buscarGrillaValue: '',
      showModalEdit: false,
      showModalBorr: false,
      login_pers_id: sessionStorage.getItem('USU_PERS_ID'),
      peme_texto : '', 
      peme_id : '',
      showToast: true,

      empr_razon  : '',
      empr_estado : '',
      empr_estado_color:'',

      filterGrilla: '',
      fetchRegistros: true,
      respuestaSp: [],
      respError: '',
      mensajeAlerta: '',
    };

    this.ordenarGrilla    = this.ordenarGrilla.bind(this);
    this.poblarGrilla     = this.poblarGrilla.bind(this); 

    this.handleModalEdit  = this.handleModalEdit.bind(this);
    this.handleModalExit  = this.handleModalExit.bind(this);
    this.handleEditRegis  = this.handleEditRegis.bind(this);
    this.handleBorrRegis  = this.handleBorrRegis.bind(this);
    this.handleModalAlta  = this.handleModalAlta.bind(this);

    this.handleChange     = this.handleChange.bind(this);
    this.handleChangeOpc  = this.handleChangeOpc.bind(this);
  }

  componentDidMount() {

    const sql = `${URL_DB}SEL_PERSONA_EMPRESA(${this.state.login_pers_id})`
    axios.get(sql)
      .then((response) => {
         if (response.data[0].length>0) {
          this.setState({
            empr_razon  : response.data[0][0].empr_razon,
            empr_estado : response.data[0][0].empr_estado,
            empr_estado_color : response.data[0][0].estado_color,
          })       
        }
        this.poblarGrilla(this.state.opciones_id);       
     })
      .catch((error) => console.log(error));

  }

  /*==========================================================================
   Utilidades de filtros y sort para la grilla ppal 
  *==========================================================================*/
  filtrarDatos() {
    const escapedValue = this.state.filterGrilla.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedValue, 'i');
    return this.state.registros.filter(filtro => 
      regex.test(filtro.peme_texto) )
  }

  ordenarGrilla = (key) => {
    const registros = ordenarGrilla(key, this.state.registros);

    this.setState({ registros });
  }

  handleShowToast = () => {
    this.setState({ showToast : false})
  }
  /*==========================================================================
   Completo datos de grilla principal 
  *==========================================================================*/
  poblarGrilla = (paramOpc) => {

    const sql = `${URL_DB}SEL_PERSONA_MENSAJES ('D',${this.state.login_pers_id},'P','${paramOpc}')`
    axios.get(sql)
      .then((response) => {
        this.setState({
          registros: response.data[0],
        })
      })  
      .catch((error) => console.log(error))
      .finally(() => {
        this.setState({ fetchRegistros: false })
      })
  }

  
  handleChangeOpc(event) {
    let paramOpc = event.target.value;

    this.setState({ fetchRegistros: true,
      opciones_id: paramOpc,
    },() => { this.poblarGrilla(paramOpc); }); 
  };
    
  handleChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    
  };

  /*==========================================================================
   Abro MODAL para Alta y Modificar : Cargo valores y combos
  *==========================================================================*/
   handleModalEdit(regis) {
  
    this.setState({ 
        showModalEdit: true,
        peme_id    : regis.peme_id, 
        peme_texto : regis.peme_texto,

        respError: '',
        mensajeAlerta: '',
        mensajeColor: 'N'
        })  
  }

  handleModalAlta() {

    this.setState({
        showModalEdit: true,
        peme_id    : 0, 
        peme_texto : '',

        respError: '',
        mensajeAlerta: ''
        })
  }
  
  handleModalExit() {
    this.setState({ showModalEdit: false })
    this.setState({
      mensajeAlerta : 'No se aplicaron cambios',
      mensajeColor  : 'red',
    })
  }

  /*==========================================================================
   Actualizacion : Modificacion de mensajes
  *==========================================================================*/
  handleEditRegis(event) {
    event.preventDefault();

    if (this.state.peme_texto.length < 10 ) {
      swal({ 
        title : `Error al grabar mensaje`,
        text  : 'Debe completar con mas de 10 caracteres',})
    } else {
      const sql =  `${URL_DB}AM_PERSONA_MENSAJE(${this.state.login_pers_id},${this.state.peme_id},${this.state.login_pers_id},'P','${encodeURIComponent(this.state.peme_texto)}')`;  
      axios.get(sql)
      .then((response) => {
            this.setState({
              respuestaSp: response.data[0]
            })
            var obj = this.state.respuestaSp[0];
            this.setState({
              respError : obj.respuesta
            })

            if (this.state.respError==='OK') {
                this.setState({
                    mensajeAlerta : 'Registrado correctamente',
                    mensajeColor  : 'green',
                })          
            }  
            this.setState({ showModalEdit : false });
        })
        .catch((error) => {
          alert('ERROR interno API al actualizar BD:'+error)
        })
        .finally(() => {
          this.poblarGrilla('R');
          this.poblarGrilla(this.state.opciones_id);
          swal({ title : 'Pregunta a profesional',
          text  : 'Enviada',
          icon : "success",  
          button : "Aceptar",
          timer : "1000" });
        });
    }
  }

  /*==========================================================================
   Actualizacion : Baja de empresas
  *==========================================================================*/
  handleBorrRegis(regis) {

    swal({ 
      title : `Eliminar este mensaje sin responer`,
      text  : ' confirma esta acción ?',
      icon : "warning",  
      buttons : ['No','Si'], 
      }).then( respuesta => {
        if ( respuesta===true ) {
            
          const sql =  `${URL_DB}B_PERSONA_MENSAJE(${this.state.login_pers_id},${regis.peme_id})`;
              axios.get(sql)
              .then((response) => {
                    this.setState({
                      respuestaSp: response.data[0]
                    })
                    var obj = this.state.respuestaSp[0];
                    this.setState({
                      respError : obj.respuesta
                    })
          
                    if (this.state.respError==='OK') {
                        this.setState({
                            mensajeAlerta : 'Registrado correctamente',
                            mensajeColor  : 'green',
                        })          
                    }  
                    this.poblarGrilla(this.state.opciones_id);
                })
                .catch((error) => {
                  alert('ERROR interno API al actualizar BD:'+error)
                });
        
        } else {
                this.setState({
                  mensajeAlerta : 'No se aplico baja',
                  mensajeColor  : 'red',
                })
        }
      });

  }

  /*==========================================================================
   RENDER
  *==========================================================================*/

  render() {  

    const respError = this.state.respError;
    const mensajeAlerta = this.state.mensajeAlerta;
    const mensajeColor = this.state.mensajeColor;
    const registrosFiltrados = this.filtrarDatos();

    return(
      <div>        
        {mensajeAlerta.length >1 ? <Notifications mensaje={mensajeAlerta}
                                                  mensajeColor={mensajeColor}
                                    /> : '' }

        <Container  fluid="true">
          <Row>
            <Col xs={4} style={{textAlign: "left" }}>
                <h2>Consultas a profesionales</h2>
            </Col>
            <Col xs={2}>
                <Form.Group>
                  <select className="form-control" name="opciones_id" style={{fontWeight: "bold" }}
                      onChange={this.handleChangeOpc} >
                      <option key={1} value={'U'}>Mensajes Recientes</option>
                      <option key={2} value={'H'}>Mensajes Historicos</option>
                  </select>
                </Form.Group>
            </Col>
            <Col xs={1}>
            <Form.Group>
                <FormControl type="text" name="buscar" placeholder="Texto a filtrar" className="mr-sm-2" 
                        onChange={(e) => { this.setState({ filterGrilla: e.target.value })} } /> 
              </Form.Group>
            </Col>
            <Col xs={2} style={{textAlign: "left"}}>
                <b>
                {this.state.empr_razon!==''?`${this.state.empr_razon} - `:''}
                <span style={{color: this.state.empr_estado_color}}>{this.state.empr_estado }</span>
                </b>
            </Col>
            <Col xs={2}>
                <Button variant="primary" size="sm" type="submit" onClick={this.handleModalAlta} >
                    <i className="fas fa-comment-dots"></i> Nuevo
                </Button>
                <Button  variant='outline-warning' size='sm' onClick={() => {this.setState({ showToast : true}) } } > 
                    Ayuda
                </Button>
            </Col>
            <Col xs={1}>
                <ShowAlerta pers_id = {this.state.login_pers_id} />
            </Col>
          </Row>
          
          <Ayuda  clave='persona-mensajes' 
                  showtoast={this.state.showToast} 
                  handleShowToast={this.handleShowToast}/> 

          <Row>
            <Col>
              <Table hover size="sm" id="data_table">
                <tbody>
                {
                  this.state.fetchRegistros && 'Cargando...'
                }
                {
                  registrosFiltrados.map((regis) => {
                    return (
                      <tr key={regis.peme_id}>
                        <td style={{ width: "3%" }}>
                            {regis.peme_preg_resp==='R' &&
                               <i style={{ color: regis.color_regis }} className="fas fa-user-graduate"></i> 
                            }
                            {regis.peme_preg_resp==='P' &&
                                <i className="far fa-question-circle"></i>
                            }
                        </td>
                        <td style={{ width: "79%" , paddingLeft: regis.margen }}>
                            <FormControl as="textarea"  name="peme_texto"
                                        defaultValue={regis.peme_texto} 
                                        placeholder="escribe tu pregunta aqui"
                                        style={{ backgroundColor: regis.color_regis }}
                                        readOnly
                                        onChange={this.handleChange} />
  {regis.peme_preg_resp==='R' &&
                              <br />  // Despues de cada respuesta dejo espacio ppara separar visualmente los temas
  }
                        </td>
                        <td style={{ width: "8%", textAlign: "left", fontSize: 10 }}>
                            {regis.peme_alta_f}
                        </td> 
                        <td style={{ width: "10%" }}>
  { regis.permi_modi==='S' &&
                          <Button variant="primary" size="sm" onClick={() => this.handleModalEdit(regis)}>
                            <i className="fas fa-pen"></i></Button>
  }
  { regis.permi_modi==='S' &&
                          <Button variant="danger" size="sm" onClick={() => this.handleBorrRegis(regis)}>
                            <i className="fas fa-trash-alt"></i></Button>
  }
                        </td>
                      </tr>  
                    ) 
                  }) 
                }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>

  
  
        <Modal show={this.state.showModalEdit} onHide={this.handleModalExit} >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.peme_id===0 ? 'Agregar pregunta' : `Editar pregunta (#${this.state.peme_id})`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form onSubmit={this.handleEditRegis}>
          <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Pregunta</Form.Label>
                  <FormControl as="textarea"  name="peme_texto"
                                        defaultValue={this.state.peme_texto} 
                                        placeholder="escribe tu pregunta aqui"
                                        onChange={this.handleChange} />
                </Form.Group>
              </Col>
                  
            </Row>
            <br />
            <Row>
                <Col xs={6} style={{textAlign: "right"}}>
                  <Button variant="success" size="sm" type="submit">
                    Registrar
                  </Button>
                  <Button variant="secondary" size="sm" onClick={this.handleModalExit}>
                    Cancelar
                  </Button>
                </Col>
                <Col xs={6}>
                  <Alert key="1" variant="danger" show={respError.length >3 ? true : false} >
                        {respError}
                  </Alert>
                </Col>
            </Row>
          </Form>
          </Modal.Body>
        </Modal>

       

      </div>
    );
  }
}

export default MensajeriaPage;
