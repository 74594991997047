import React  from 'react';
//import React, { ListItem } from 'react';
import { URL_DB } from './../../constants';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { Table, Row, Col, Container, Button, Form, Card } from 'react-bootstrap';
import ShowAlerta from '../../components/Show-alerta';
import Notifications from '../../components/Notifications';
import alerta_suave from './../../assets/sounds/alerta_suave.mp3';
import relojAlerta from './../../assets/images/relojito_alerta.gif';
import '../stylePages.css';

class PersonaRutinaPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      registros: [],
      rutina: [],
      ciclos : [],
      dia : 1,
      rutinaCiclo: [],
      rutinaNombre: '',
      inicio_rutina_f : '',
      apenom : '',
      peru_ruti_id : '',
      ruti_deno : '',
      ruci_ciclo : '',
      ruci_id_actual : '',
      dia_ciclo_actual: '',
      login_pers_id: sessionStorage.getItem('USU_PERS_ID'),

      routeFromAlerta: this.props.location.search==='?aler=1'?true:false,
      finMusicaAlerta: false,

      fetchingregistros: false,
      paramVisible: true,
      respuestaSp: [],
      respError: '',
      mensajeAlerta: '',
    };
    
    this.getInicio        = this.getInicio.bind(this);

  }

  async getRutinaPersona() {
    this.setState({ fetchingregistros: true });

    const sql =  `${URL_DB}SEL_PERSONA_RUTINA('D',${this.state.login_pers_id})`
    axios.get(sql)
      .then((response) => {
        this.setState({
          rutina: response.data[0].filter( elem => elem.deta_ciclos === 'D'),
          ciclos: response.data[0].filter( elem => elem.deta_ciclos === 'C')
        }) 
        if (this.state.rutina.length > 0) {
              this.setState({ 
                            rutinaNombre : `${this.state.rutina[0].ruti_deno} (#${this.state.rutina[0].ruti_id})`,
                            inicio_rutina_f : this.state.rutina[0].inicio_rutina_f,
                            apenom : this.state.rutina[0].apenom,
                            ruci_id_actual : this.state.rutina[0].ruci_id_actual,
                            dia_ciclo_actual : this.state.rutina[0].dia_ciclo_actual,
                            rutinaCiclo : this.state.rutina.filter(elem => 
                                                    elem.ruci_id === Number( this.state.rutina[0].ruci_id_actual)
                                                && elem.ciej_dia === Number( this.state.rutina[0].dia_ciclo_actual) ),
                            })
        } else {
          this.setState({ rutinaNombre : '(Sin rutina asignada)',
                          inicio_rutina_f :'' })
        }

      })
      .catch((error) => console.log(error))
  }

  async getRegistroVisita() {
    const sql =  `${URL_DB}AM_REGISTRAR_PERSONA_VISITAS(${this.state.login_pers_id})`
    axios.get(sql)
      .then((response) => {
        this.setState({
          regisVisita: response.data[0]
        })
      })
      .catch((error) => console.log(error))
  }

  

  async getInicio() {
    try{
      await Promise.all([ this.getRutinaPersona(),this.getRegistroVisita() ])
    } catch(e) {
      console.log(e);
    }
  }

  componentDidMount() {
    this.getInicio() 
  }

  finPlayMusicaAlerta = () => {

    this.setState({ finMusicaAlerta : true,
                    routeFromAlerta : false,
           })

  }
  

  /*==========================================================================
   RENDER
  *==========================================================================*/

  render() {  

    //const respError = this.state.respError;
    const mensajeAlerta = this.state.mensajeAlerta;
    const mensajeColor = this.state.mensajeColor;

    return(
      <div style={this.state.paramVisible ? {} : { display: 'none' }}>        
        {mensajeAlerta.length >1 ? <Notifications mensaje={mensajeAlerta}
                                                  mensajeColor={mensajeColor}
                                    /> : '' }

        <Container >
        <Form>
          <Row>
            <Col xs={2}>
  
  { this.state.routeFromAlerta  &&
                    <img  style={{ width: '100%' }} 
                            src={relojAlerta} 
                            alt={'imagen_hlp'} />
  }
            </Col>
            <Col xs={8}>
                <h2><b>{`Rutina para ${this.state.apenom}`}</b></h2>
                <b>{`${this.state.rutinaNombre} iniciada el ${this.state.inicio_rutina_f}`}</b>
            </Col>
            <Col xs={2}>
                <ShowAlerta pers_id = {this.state.login_pers_id} />
            </Col>
          </Row>
      
          <Row>
          {  this.state.rutinaCiclo.map((rut, ind) => {
             
                  return (
                <React.Fragment key={ind}>

                  <Col md={6} sm={12} >
                  <Card>
                  <Card.Header>
                    <div style={{fontWeight: "bold", textAlign: "left" }} >
                        <b>{`   ${rut.ejer_deno}   #${rut.ejer_id}`}</b>
                    </div>
                  </Card.Header>
                  <Card.Title>{rut.implementa}</Card.Title>
                    <Card.Body>
        
                    <div className='player-wrapper'>
                      <ReactPlayer 
                        width ='480px'
                        height ='240px'
                        controls url={rut.ejer_video}
                        onReady = {() => console.log('Onready')}
                        onStart = {() => console.log('onStart')}
                        onPause = {() => console.log('onPause')}
                        onEnded = {() => console.log('onEnded')} />
                    </div>
                      <Card.Text>
                        <div style={{textAlign: "left" }}>{rut.ciej_obser}</div>
                      </Card.Text>
                      </Card.Body>
                  </Card>  
                  </Col>    
                  
                </React.Fragment>   
                
                  ) 
                }) 
            } 
    </Row>

          <Row>
              <Card>
              <Card.Header>Ciclos de esta rutina</Card.Header>
                <Card.Body>


                  <Table>
                  <thead className="Grilla-header">
                    <tr>
                      <th>Actual</th>
                      <th>Ciclo</th>
                      <th>Lapso</th>
                      <th>Desde</th>
                      <th>Hasta</th>
                      <th colSpan={4}>Pulsar botón para cargar rutina</th>
                    </tr>
                  </thead>  
                  <tbody>
                    { 
                      this.state.ciclos.map((cic) => {
                        return (
                        <tr  key={cic.tmp_ruci_id}>    
                          <td><b>{cic.tmp_ciclo_actual==='S' && 'X'}</b></td>
                          <td>{cic.tmp_ruci_deno}</td>      
                          <td>{cic.tmp_sema}</td>
                          <td>{cic.tmp_ini_f}</td>
                          <td>{cic.tmp_fin_f}</td>
                          <td>
                      { cic.tmp_dia1==='S' &&   
                              <Button variant={cic.tmp_dia1_color} size="sm"  
                                  onClick={(e) => {
                                    this.setState({ 
                                      preview_file : '',
                                      preview_deno : '',
                                      rutinaCiclo  : this.state.rutina.filter(elem => elem.ruci_id === Number(cic.tmp_ruci_id)),
                                    });
                                  }}>
                                Dia 1</Button>
                      }
                          </td>
                          <td>
                      { cic.tmp_dia2==='S' &&  
                              <Button variant={cic.tmp_dia2_color} size="sm"  
                                  onClick={(e) => {
                                    this.setState({ 
                                      preview_file : '',
                                      preview_deno : '',
                                      rutinaCiclo  : this.state.rutina.filter(elem => elem.ruci_id === Number(cic.tmp_ruci_id)),
                                    });
                                  }}>
                                Dia 2</Button>
                      }
                          </td>
                          <td>
                      { cic.tmp_dia3==='S' &&  
                              <Button variant={cic.tmp_dia3_color} size="sm"  
                                  onClick={(e) => {
                                    this.setState({ 
                                      preview_file : '',
                                      preview_deno : '',
                                      rutinaCiclo  : this.state.rutina.filter(elem => elem.ruci_id === Number(cic.tmp_ruci_id)),
                                    });
                                  }}>
                                Dia 3</Button>
                      }
                          </td>
                          <td>
                      { cic.tmp_dia4==='S' &&  
                              <Button variant={cic.tmp_dia4_color} size="sm"  
                                  onClick={(e) => {
                                    this.setState({ 
                                      preview_file : '',
                                      preview_deno : '',
                                      rutinaCiclo  : this.state.rutina.filter(elem => elem.ruci_id === Number(cic.tmp_ruci_id)),
                                    });
                                  }}>
                                Dia 4</Button>
                      }
                          </td>
             
                        </tr>        
                        ) 
                      }) 
                    }
                  </tbody>
                  </Table>
   
                </Card.Body>
              </Card>            
          </Row>

  { this.state.routeFromAlerta  &&
          <ReactPlayer  url={alerta_suave}
                        playing={true}
                        width={100}
                        height={5} 
                        onEnded = {this.finPlayMusicaAlerta}/>
  }
        </Form>
         
        </Container>


      </div>
    );
  }
}

export default PersonaRutinaPage;
