import React from 'react';
import { URL_DB } from './../../constants';
import axios from 'axios';
import { Table, Row, Col, Container, Button, Modal, Form, Alert, FormControl  } from 'react-bootstrap';
//import ExpGrillaExcelPDF from '../../components/ExpGrillaExcelPDF';
import Notifications from '../../components/Notifications';
import ordenarGrilla from './../../utils/functions/ordenar-grilla'
import '../stylePages.css';
import AyudaAdmi from '../../components/Ayuda-admi';

class PanelTipoTrabPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      registros: [],
      unidades: [],
      buscarGrillaValue: '',
      showModalEdit: false,
      showModalBorr: false,
      login_admi_id: sessionStorage.getItem('USU_ADMI_ID'),
      showhelp : false,
      filterGrilla: '',
      fetchingregistros: false,
      respuestaSp: [],
      respError: '',
      mensajeAlerta: '',
    };

    this.ordenarGrilla      = this.ordenarGrilla.bind(this);
    this.poblarGrilla       = this.poblarGrilla.bind(this);
    this.handleModalEditEdit = this.handleModalEditEdit.bind(this);
    this.handleModalEditExit = this.handleModalEditExit.bind(this);
    this.handleModalBorrEdit = this.handleModalBorrEdit.bind(this);
    this.handleModalBorrExit = this.handleModalBorrExit.bind(this);
    this.handleEditRegis = this.handleEditRegis.bind(this);
    this.handleBorrRegis = this.handleBorrRegis.bind(this);
    this.handleModalAlta = this.handleModalAlta.bind(this);
    this.handleChange    = this.handleChange.bind(this);

  }

  componentDidMount() {

    const sql = `${URL_DB}SEL_PANEL_TTIP_UNIDAD()`
    axios.get(sql)
      .then((response) => {
        this.setState({
          unidades: response.data[0],
        })
      })
      .catch((error) => console.log(error))

    this.poblarGrilla()
  }

  /*==========================================================================
   Utilidades de filtros y sort para la grilla ppal 
  *==========================================================================*/
  filtrarDatos() {
    const escapedValue = this.state.filterGrilla.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedValue, 'i');
    return this.state.registros.filter(filtro => 
      regex.test(filtro.ttip_deno) || 
      regex.test(filtro.ttip_especifica) || 
      regex.test(filtro.ttun_deno) )
  }

  ordenarGrilla = (key) => {
    const registros = ordenarGrilla(key, this.state.registros);

    this.setState({ registros });
  }
 
  /*==========================================================================
   Completo datos de grilla principal 
  *==========================================================================*/
  poblarGrilla = () => {
    this.setState({ fetchingregistros: true });
    const sql = `${URL_DB}SEL_TRABAJO_TIPO()`

    axios.get(sql)
      .then((response) => {
        this.setState({
          registros: response.data[0],
        })

      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.setState({ fetchingregistros: false })
      })
  }

  
  /*==========================================================================
   Abro modal para modificar : Cargo valores y combos
  *==========================================================================*/
  handleModalEditEdit(regis) {

    this.setState({
      showModalEdit: true,
      ttip_id: regis.ttip_id, 
      ttip_deno: regis.ttip_deno, 
      ttip_especifica: regis.ttip_especifica,  
      ttip_especifica_unidad: regis.ttip_especifica_unidad,
    
      respError: '',
      mensajeAlerta: '',
      mensajeColor: 'N'
    })
    
  }

  handleModalEditExit() {
    this.setState({ showModalEdit: false })
    this.setState({
      mensajeAlerta : 'No se aplicaron cambios',
      mensajeColor  : 'red',
    })
  }

 
  /*==========================================================================
   Abro modal para Borrar : Cargo solo valores 
  *==========================================================================*/
  handleModalBorrEdit(regis) {

    this.setState({
      showModalBorr: true,
      ttip_id: regis.ttip_id, 
      ttip_deno: regis.ttip_deno, 
      ttip_especifica: regis.ttip_especifica,  
      ttun_deno: regis.ttun_deno,
      
      respError: '',
      mensajeAlerta: ''
    })
  }

  handleModalBorrExit() {
    this.setState({ showModalBorr: false })
    this.setState({
      mensajeAlerta : 'No se aplico baja',
      mensajeColor  : 'red',
    })
  }

  /*==========================================================================
   Abro modal para alta : Cargo valores y combos
  *==========================================================================*/
  handleModalAlta() {
  
    this.setState({
      showModalEdit: true,
      ttip_id: 0, 
      ttip_deno: '', 
      ttip_especifica: '',  
      ttip_especifica_unidad: 'N',

      respError: '',
      mensajeAlerta: ''
    })

  }

  handleChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    
  };
  

  /*==========================================================================
   Actualizacion : Modificacion cursos
  *==========================================================================*/
  handleEditRegis(event) {
    event.preventDefault();
    
    const sql =  `${URL_DB}AM_PANEL_TRABAJO_TIPO(${this.state.login_admi_id},${this.state.ttip_id},
'${encodeURIComponent(this.state.ttip_deno)}','${encodeURIComponent(this.state.ttip_especifica)}',
'${this.state.ttip_especifica_unidad}')`  
    axios.get(sql)
    .then((response) => {
          this.setState({
            respuestaSp: response.data[0]
          })
          var obj = this.state.respuestaSp[0];
          this.setState({
            respError : obj.respuesta
          })

          if (this.state.respError==='OK') {

              this.setState({
                  mensajeAlerta : 'Registrado correctamente',
                  mensajeColor  : 'green',
              })
              this.setState({ showModalEdit: false })
              this.poblarGrilla()
          }    
      })
      .catch((error) => {
        alert('ERROR interno API al actualizar BD:'+error)
      })

      
  }

  /*==========================================================================
   Actualizacion : Baja de cursos
  *==========================================================================*/
  handleBorrRegis(event) {
    event.preventDefault(); 

    const sql = `${URL_DB}B_PANEL_TRABAJO_TIPO (${this.state.login_admi_id},${this.state.ttip_id})`
    axios.get(sql)
    .then((response) => {
        this.setState({
          respuestaSp: response.data[0]
        })
        var obj = this.state.respuestaSp[0];
        this.setState({
          respError : obj.respuesta
        })

        if (this.state.respError==='OK') {

            this.setState({
                mensajeAlerta : 'Registrado correctamente',
                mensajeColor  : 'green',
            })
            this.setState({ showModalBorr: false })
            this.poblarGrilla()
        }    
    })
    .catch((error) => {
      alert('ERROR interno API al actualizar BD:'+error)
    })

        
  }

  /*==========================================================================
   RENDER
  *==========================================================================*/

  render() {  
    const respError = this.state.respError;
    const mensajeAlerta = this.state.mensajeAlerta;
    const mensajeColor = this.state.mensajeColor;
    const registrosFiltrados = this.filtrarDatos();

    return(
      <div>        
        {mensajeAlerta.length >1 ? <Notifications mensaje={mensajeAlerta}
                                                  mensajeColor={mensajeColor}
                                    /> : '' }

        <Container  fluid="true">
          <Row>
            <Col xs={6}>
                <h2><b>Definir Tipo de Trabajo</b></h2>
            </Col>
            <Col xs={2}>
            <Form.Group>
                <FormControl type="text" name="buscar" placeholder="Texto a filtrar" className="mr-sm-2" 
                        onChange={(e) => { this.setState({ filterGrilla: e.target.value })} } /> 
              </Form.Group>
            </Col>
            <Col xs={2}>
                <Button variant="primary" size="sm" type="submit" 
                        onClick={this.handleModalAlta} ><i className="fas fa-plus"></i> Agregar
                </Button>
            </Col>
            <Col xs={2}>
                <Button  variant='outline-warning' size='sm' onClick={() => {this.setState({ showhelp : true}) } } > 
                Ayuda
                </Button>
     {/*       <ExpGrillaExcelPDF showExportar =  {true}
                      tituloExporta = {'Listado de cursos'}
                      sqlExporta= {`${URL_DB}SEL_INSTITUCION_CURSOS('D',null,${this.state.login_prof_id})`}
            />

             <Button  variant='outline-warning' size='sm' onClick={() => {this.setState({ showhelp : true}) } } > 
             Ayuda
            </Button>
    */}
           </Col>
          </Row>

          <Row>
            <Col>
              <Table striped bordered hover size="sm" id="data_table">
                <thead className="Grilla-header">
                <tr>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ttip_id')}>
                      #ID</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ttip_deno')}>
                        Denominación</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ttip_especifica')}>
                        Especifica</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ttun_deno')}>
                        Unidad especif</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ttip_alta_f')}>
                        Alta</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ttip_alta_usua')}>
                        Usua Alta</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ttip_modi_f')}>
                        Modifica</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ttip_modi_usua')}>
                        Usua Modif</Button>
                  </th>
                  <th>Acciones</th>
                </tr>
                </thead>
                
                {
                  this.state.fetchingregistros && 'Cargando...'
                }
                <tbody>
                {
                  registrosFiltrados.map((regis) => {
                    return (
                      <tr key={regis.ttip_id}>
                        <td>{regis.ttip_id}</td>
                        <td>{regis.ttip_deno}</td>
                        <td>{regis.ttip_especifica}</td>
                        <td>{regis.ttun_deno}</td> 
                        <td>{regis.ttip_alta_f}</td>
                        <td>{regis.ttip_alta_usua}</td>
                        <td>{regis.ttip_modi_f}</td>
                        <td>{regis.ttip_modi_usua}</td>
                        <td>  
                          <Button variant="primary" size="sm" 
                                  onClick={() => this.handleModalEditEdit(regis)}><i className="fas fa-pen"></i></Button>
                          <Button variant="danger"  size="sm" 
                                  onClick={() => this.handleModalBorrEdit(regis)}><i className="fas fa-trash-alt"></i></Button>
                        </td>
                      </tr>  
                    ) 
                  }) 
                }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>


        <Modal show={this.state.showModalEdit} onHide={this.handleModalEditExit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.ttip_id===0 ? 'Agregar un Tipo-Trabajo nuevo' : `Editar Tipo-Trabajo ID# ${this.state.ttip_id}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form onSubmit={this.handleEditRegis}>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Denominacion</Form.Label>
                  <Form.Control type="text" name="ttip_deno" value={this.state.ttip_deno} onChange={this.handleChange} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Utiliza unidad</Form.Label>
                  <select className="form-control" name="ttip_especifica_unidad" 
                                                  value={this.state.ttip_especifica_unidad} onChange={this.handleChange} >
                  { this.state.unidades.map((uni) => { 
                      return (
                          <option key={uni.ttun_ttip_especifica_unidad} 
                                  value={uni.ttun_ttip_especifica_unidad}
                              > {uni.ttun_deno}
                          </option>
                              ) 
                    }) }
                  </select>
                </Form.Group>
              </Col>
            </Row>
            <Row>   
              <Col>   
                <Form.Group>
                  <Form.Label>Etiq de especificar unidad</Form.Label>    
                  <Form.Control type="text" name="ttip_especifica" value={this.state.ttip_especifica} onChange={this.handleChange} />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Button variant="success" size="sm" type="submit">
                  Grabar
                </Button>
                <Button variant="secondary" size="sm" onClick={this.handleModalEditExit}>
                  Cancelar
                </Button>
                <Alert key="1" variant="danger" show={respError.length >3 ? true : false} >
                      {respError}
                </Alert>
              </Col>
            </Row>
          </Form>
          </Modal.Body>
        </Modal>
  

        
        < Modal show={this.state.showModalBorr} onHide={this.handleModalBorrExit}>
          <Modal.Header closeButton>
            <Modal.Title>Borrar Tipo Trabajo ID#{this.state.ttip_id}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleBorrRegis}>

              <Form.Group>
                <Form.Label>Denominacion</Form.Label>
                <Form.Control type="text" name="ttip_deno" value={this.state.ttip_deno} readOnly />
              </Form.Group>

              <Form.Group>
                <Form.Label>Utiliza unidad</Form.Label>
                <Form.Control type="text" name="ttip_especifica" value={this.state.ttun_deno} readOnly />
              </Form.Group>

              <Form.Group>
                <Form.Label>Etiq de especificar unidad</Form.Label>
                <Form.Control type="text" name="ttip_especifica" value={this.state.ttip_especifica} readOnly />
              </Form.Group>
              <br />
              <Button variant="danger" size="sm" type="submit">
                Borrar
              </Button>
              <Button variant="secondary" size="sm" onClick={this.handleModalBorrExit}>
                Cancelar
              </Button>
              <Alert key="1" variant="danger" show={respError.length >3 ? true : false} >
                      {respError}
                </Alert>
            </Form>
            </Modal.Body>          
        </Modal>

        < Modal show={this.state.showhelp} onHide={() => { this.setState({ showhelp : false}) } } dialogClassName="modal-90">
            <AyudaAdmi clave={'admin'}/>
        </Modal>

      </div>
    );
  }
}

export default PanelTipoTrabPage;
