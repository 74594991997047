import React  from 'react';
import { Row, Col,} from 'react-bootstrap';
import { URL_DB } from './../../constants';
import axios from 'axios';
import { Redirect } from "react-router-dom";

class ShowAlerta extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      p_pers_id : props.pers_id,

      call_param: true,
      playAlerta: false,
      reloj_si_no: '',
      reloj_hora: '',
      ergo_show: '',
      ergo_canti: '',
      asterisco: '-',
      minutos: 0,
    };

    //this.preparoShowAlerta          = this.preparoShowAlerta.bind(this);
  }

  componentDidMount() {


    var intervaliId
    intervaliId = setInterval(()=>{

        if (this.state.call_param) {
            this.getShowAlerta();
        }
        
        // Cada 30 seg comparo las 'hh:mm'
        var currentdate = new Date(); 
        var gettime = (currentdate.getHours() < 10?"0":"") + currentdate.getHours() +":"+ ((currentdate.getMinutes() < 10)?"0":"") + currentdate.getMinutes()      
        if (this.state.reloj_hora === gettime) {
          console.log('alerta', intervaliId)
          this.setState({ playAlerta : true });
   
          //clearInterval(intervaliId ) // salgo del loop
        }

        // cada 5 min vuelvo a traer los param , por si hay cambios o para la 2da alerta 
        this.setState({ minutos : this.state.minutos + .5,
                      asterisco : this.state.asterisco==='-'?'*':'-'   // hay un asterico que aparece y desap para saber que esta func esta corriendo
                     })
        if (this.state.minutos > 5 ) {
          this.setState({ call_param : true })
        }

    }, 30000)  // cada 30seg reviso la alerta
  
 
  }
  
  getShowAlerta() {

    const sql = `${URL_DB}SEL_ALERTA_SHOW('${this.state.p_pers_id}')`
    axios.get(sql)
      .then((response) => {
        this.setState({
          reloj_si_no: response.data[0][0].reloj_si_no,
          reloj_hora:  response.data[0][0].reloj_hora,
          ergo_show:   response.data[0][0].ergo_show,
          ergo_canti:  response.data[0][0].ergo_canti,
          ergo_color:  response.data[0][0].ergo_color,
          call_param:  false,
          minutos: 0,
        })
      })
  
      .catch((error) => console.log(error))
      .finally(() => {
        this.setState({ fetchingregistros: false })
      })
  }

  /*==========================================================================
   RENDER
  *==========================================================================*/

  render() {  

    if (this.state.playAlerta) {
      return <Redirect to={'/persona-rutina?aler=1'} />      //window.location = '/'
    }


    return(
      <div>        
        <Row style={{textAlign: "center", fontSize: 8}} >
          <Col>
            Alerta
          </Col>
        </Row>
        <Row  style={{fontWeight: "bold", 
                       backgroundColor: this.state.ergo_color,
                       color: '#FFFFFF' }} >
          <Col>
{ this.state.ergo_canti===0 &&
            <b>No activada</b>
}
{ this.state.ergo_canti>0 &&
            <b>{`${this.state.ergo_show} ${this.state.reloj_hora} ${this.state.asterisco}`}</b>
}    
          </Col>
       </Row>
      </div>
    );
  }
}

export default ShowAlerta;
