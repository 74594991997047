import React from 'react';
import { URL_DB } from './../../constants';
import axios from 'axios';
import { Table, Row, Col, Container, Button, Modal, Form, Alert, FormControl, Tabs, Tab } from 'react-bootstrap';
//import ExpGrillaExcelPDF from '../../components/ExpGrillaExcelPDF';
import swal from 'sweetalert';
import FichaPersona from '../../components/Ficha-persona';
import MensajesPersona from '../../components/Mensa-persona';
import Notifications from '../../components/Notifications';
import ordenarGrilla from './../../utils/functions/ordenar-grilla'
import '../stylePages.css';
import AyudaAdmi from '../../components/Ayuda-admi';

class PanelMensajeriaPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opciones_id : 'T',
      registros: [],
      preguntas: [],
      buscarGrillaValue: '',
      showModalEdit: false,
      showModalBorr: false,
      login_admi_id: sessionStorage.getItem('USU_ADMI_ID'),
      pers_id    : '',
      pers_apenom: '',
      pers_edad  : '',
      empr_regis: '',
      empr_deno  : '',
      ests_deno  : '',
      erub_deno  : '',
      ult_preg_f : '',
      dias_preg_t: '',
      ests_color : '',
      color_fon_msg:'',

      showhelp : false,
      filterGrilla: '',
      fetchingregistros: false,
      fetchPreguntas: true,
      respuestaSp: [],
      respError: '',
      mensajeAlerta: '',
    };

    this.ordenarGrilla        = this.ordenarGrilla.bind(this);
    this.poblarGrilla         = this.poblarGrilla.bind(this);
    this.handleModalEditEdit  = this.handleModalEditEdit.bind(this);
    this.handleModalExit      = this.handleModalExit.bind(this);
    this.handleEditRegis      = this.handleEditRegis.bind(this);
    this.handleChange         = this.handleChange.bind(this);
    this.handleChangeOpc      = this.handleChangeOpc.bind(this);
  }

  componentDidMount() {

    const sql = `${URL_DB}SEL_PARAMETROS('COLOR_FONDO_MSG')`
    axios.get(sql)
      .then((response) => {
        this.setState({
          color_fon_msg: response.data[0][0].para_char,
        })
      })
      .catch((error) => console.log(error))

    this.poblarGrilla(this.state.opciones_id);
  
  }

  /*==========================================================================
   Utilidades de filtros y sort para la grilla ppal 
  *==========================================================================*/
  filtrarDatos() {
    const escapedValue = this.state.filterGrilla.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedValue, 'i');
    return this.state.registros.filter(filtro => 
      regex.test(filtro.apenom) || 
      regex.test(filtro.pers_docum) ||
      regex.test(filtro.tall_deno) || 
      regex.test(filtro.ttip_deno) || 
      regex.test(filtro.ests_deno) ||
      regex.test(filtro.empr_deno) )
  }

  ordenarGrilla = (key) => {
    const registros = ordenarGrilla(key, this.state.registros);

    this.setState({ registros });
  }
  
  /*==========================================================================
   Completo datos de grilla principal 
  *==========================================================================*/
  poblarGrilla = (paramOpc) => {

    /*  <option value={'T'}>Todos los pendientes</option>  
        <option value={'M'}>Pend de resp Hombres</option>
        <option value={'F'}>Pend de resp Mujeres</option>
        <option value={'R'}>Respondidos la ult semana</option>  */
    this.setState({ fetchingregistros: true,
                    opciones_id: paramOpc,
     });
    const sql = `${URL_DB}SEL_PERSONA_MENSAJES_PEND('D','${paramOpc}')`
    axios.get(sql)
      .then((response) => {
        this.setState({
          registros: response.data[0],
        })
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.setState({ fetchingregistros: false })
      })
  }

  /*==========================================================================
   Abro MODAL para mostrar persona y responder 
  *==========================================================================*/
  handleModalEditEdit(regis) {
  
      this.setState({
        showModalEdit: true,
        fetchPreguntas: false,

        pers_id    : regis.pers_id,
        pers_apenom: regis.apenom,
        pers_edad  : regis.edad,
        empr_regis : regis.empr_regis,
        empr_deno  : regis.empr_deno,
        ests_deno  : regis.ests_deno,
        erub_deno  : regis.erub_deno,
        ult_preg_f : regis.ult_preg_f,
        dias_preg  : regis.dias_preg,
        dias_preg_t: regis.dias_preg===0?'HOY':(regis.dias_preg===1?'1 dia':`${regis.dias_preg} dias`),
        ests_color : regis.ests_color,

        peme_texto : '',
  
        respError: '',
        mensajeAlerta: '',
        mensajeColor: 'N'
      })
      this.cargarPreguntas(regis.pers_id);

  }

  /* Opciones del menu, trato hombres, mujeres o todos */
  handleChangeOpc(event) {
    let paramVal = event.target.value;
    this.poblarGrilla(paramVal)    
  };
  
    
  handleChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    
  };
  
  handleModalExit() {
    this.setState({ showModalEdit: false })
    this.setState({
      mensajeAlerta : 'No se aplicaron cambios',
      mensajeColor  : 'red',
    })
  }


  /*==========================================================================
   Actualizacion : envio de respuesta
  *==========================================================================*/

  cargarPreguntas(a_pers_id) {

    const sql = `${URL_DB}SEL_PERSONA_MENSAJES('D',${a_pers_id},'R','U')`
    axios.get(sql)
    .then((response) => {
        this.setState({
          preguntas: response.data[0],
        })
    })  
    .catch((error) => console.log(error))
    .finally(() => {
        this.setState({ fetchPreguntas: false })
    })
  }

  /*==========================================================================
   Actualizacion : envio de respuesta
  *==========================================================================*/
  handleEditRegis(event) {
    //event.preventDefault();
  
    if (this.state.peme_texto.length < 10 ) {
        swal({ 
          title : `Error al grabar mensaje`,
          text  : 'Debe completar con mas de 10 caracteres',})
    } else {

      const sql =  `${URL_DB}AM_PERSONA_MENSAJE(${this.state.login_admi_id},0,${this.state.pers_id},'R','${encodeURIComponent(this.state.peme_texto)}')`;
      axios.get(sql)
      .then((response) => {
          this.setState({
            respuestaSp: response.data[0]
          })
          var obj = this.state.respuestaSp[0];
          this.setState({
            respError : obj.respuesta
          })

          if (this.state.respError==='OK') {
              this.setState({
                  mensajeAlerta : 'Registrado correctamente',
                  mensajeColor  : 'green',
              })          
          }  
          this.poblarGrilla(this.state.opciones_id);
          this.setState({ showModalEdit : false });
      })
      .catch((error) => {
        alert('ERROR interno API al actualizar BD:'+error)
      });
    }
  }

  /*==========================================================================
   Actualizacion : Baja de empresas
  *==========================================================================*/
   handleBorrRegis(regis) {

    swal({ 
      title : `Eliminar esta respuesta en este instante `,
      text  : ' confirma esta acción ?',
      icon : "warning",  
      buttons : ['No','Si'], 
      }).then( respuesta => {
        if ( respuesta===true ) {
            
          const sql =  `${URL_DB}B_PERSONA_MENSAJE(${this.state.login_admi_id},${regis.peme_id})`;
              axios.get(sql)
              .then((response) => {
                    this.setState({
                      respuestaSp: response.data[0]
                    })
                    var obj = this.state.respuestaSp[0];
                    this.setState({
                      respError : obj.respuesta
                    })
          
                    if (this.state.respError==='OK') {
                        this.setState({
                            mensajeAlerta : 'Registrado correctamente',
                            mensajeColor  : 'green',
                        })          
                    }  
                    this.poblarGrilla(this.state.opciones_id);
                    this.cargarPreguntas(this.state.pers_id)

                })
                .catch((error) => {
                  alert('ERROR interno API al actualizar BD:'+error)
                });
        
        } else {
                this.setState({
                  mensajeAlerta : 'No se aplico baja',
                  mensajeColor  : 'red',
                })
        }
      });

  }


  /*==========================================================================
   RENDER
  *==========================================================================*/

  render() {  

    const respError = this.state.respError;
    const mensajeAlerta = this.state.mensajeAlerta;
    const mensajeColor = this.state.mensajeColor;
    const registrosFiltrados = this.filtrarDatos();

    return(
      <div>        
        {mensajeAlerta.length >1 ? <Notifications mensaje={mensajeAlerta}
                                                  mensajeColor={mensajeColor}
                                    /> : '' }

        <Container  fluid="true">
          <Row>
            <Col xs={5} style={{textAlign: "left" }}>
                <h2><b>Responder Mensajes del personal</b></h2>
            </Col>
            <Col xs={2}>
                <Form.Group>
                  <select className="form-control" name="opciones_id" style={{fontWeight: "bold" }}
                      onChange={this.handleChangeOpc} >
                      <option value={'T'}>Todos los pendientes</option>  
                      <option value={'M'}>Pend de resp Hombres</option>
                      <option value={'F'}>Pend de resp Mujeres</option>
                      <option value={'R'}>Respondidos la ult semana</option>
                  </select>
                </Form.Group>
            </Col>
            <Col xs={2}>
            <Form.Group>
                <FormControl type="text" name="buscar" placeholder="Texto a filtrar" className="mr-sm-2" 
                        onChange={(e) => { this.setState({ filterGrilla: e.target.value })} } /> 
              </Form.Group>
            </Col>
            <Col xs={1}>
      {/*          <Button variant="primary" size="sm"  
                         ><i className="fas fa-plus"></i> Agregar
                </Button> */}
            </Col>
            <Col xs={2}>
                <Button  variant='outline-warning' size='sm' onClick={() => {this.setState({ showhelp : true}) } } > 
                Ayuda
                </Button>
     {/**        <ExpGrillaExcelPDF showExportar =  {true}
                      tituloExporta = {'Listado de temas'}
                      sqlExporta= {`${URL_DB}SEL_MATERIA_TEMAS('D',null,null,${this.state.login_prof_id})`}
                
            />
            */}
      
           </Col>
          </Row>

          <Row>
            <Col>
              <Table striped bordered hover size="sm" id="data_table">
                <thead className="Grilla-header">
                <tr>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('pers_id')}>
                      #ID</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('apenom')}>
                      Apellido y nombre</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('edad')}>
                      Edad</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('pers_docum')}>
                      #Docum</Button>
                  </th> 
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('pers_sexo')}>
                      Sexo</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('tall_deno')}>
                      Talla</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ttip_deno')}>
                      Tipo Trabajo</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_regis')}>
                      Registrado</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('empr_deno')}>
                      Empresa</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ests_deno')}>
                      Sts</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('ult_preg_f')}>
                      F.Pregunta</Button>
                  </th>
                  <th><Button variant="dark" size="sm" onClick={() => this.ordenarGrilla('dias_preg')}>
                      #Dias</Button>
                  </th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.fetchingregistros && 'Cargando...'
                }
                {
                  registrosFiltrados.map((regis) => {
                    return (
                      <tr key={regis.pers_id}>
                        <td>{regis.pers_id}</td>
                        <td style={{ textAlign: "left" }}>{regis.apenom}</td>
                        <td>{regis.edad}</td>
                        <td>{regis.pers_docum}</td>
                        <td>{regis.pers_sexo}</td>
                        <td style={{ textAlign: "left" }}>{regis.tall_deno}</td>
                        <td style={{ textAlign: "left" }}>{regis.ttip_deno}</td>
                        <td>{regis.empr_regis}</td>
                        <td style={{ textAlign: "left" }}>{regis.empr_deno}</td>
                        <td style={{ backgroundColor: regis.ests_color }}>{regis.ests_deno}</td>                        
                        <td>{regis.ult_preg_f}</td>      
                        <td>{regis.dias_preg}</td>        
                        <td>
   
                          <Button variant="success" size="sm" onClick={() => this.handleModalEditEdit(regis)}>
                            <i className="fas fa-reply"></i></Button>
  
                        </td>
                      </tr>  
                    ) 
                  }) 
                }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>


        <Modal show={this.state.showModalEdit} onHide={this.handleModalExit} dialogClassName="modal-90">
          <Modal.Header closeButton>
            <Modal.Title>
              {`Responder a ${this.state.pers_apenom}  de ${this.state.pers_edad} años (#${this.state.pers_id})`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form>
          <Row>
              <Col xs={5}>
                  <div>
                    <b>{`Pregunta: ${this.state.dias_preg_t}`}</b>
                      {` (${this.state.ult_preg_f})`} 
                  </div>
              </Col>
              <Col xs={7}>
                  <div style={{ textAlign: "center", backgroundColor: this.state.ests_color }}>
                      <b>{this.state.empr_deno}</b>
                      {`  (${this.state.ests_deno}) `}
                      <b>{this.state.erub_deno}</b>
                      {`  desde ${this.state.empr_regis}`} 
                  </div>
              </Col>
          </Row>
          <br />
          <Row>
              <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                <Tab eventKey="home" title="Mensajes a responder">
                  {/* ===================== respuesta de mensaje ========================== */}
                  <Row>
                    <Col>
                      <Table hover size="sm" id="data_table">
                      <tbody>
                      {
                        this.state.fetchPreguntas && 'Cargando...'
                      }
                    {
                this.state.preguntas.map((preg) => {
                  return (
                    <tr key={preg.peme_id}>
                      <td style={{ width: "3%" }}>                                 
              {preg.peme_preg_resp==='R' &&
                          <i style={{ color: preg.color_regis }} className="fas fa-user-graduate"></i> 
              }
              {preg.peme_preg_resp==='P' &&
                          <i className="far fa-question-circle"></i>
              }
                      </td>                       
                      <td style={{ width: "79%" , paddingLeft: preg.margen }}>
                                  <FormControl as="textarea"  name="peme_texto"
                                              defaultValue={preg.peme_texto} 
                                              style={{ backgroundColor: preg.color_regis }}
                                              disabled={ preg.permi_modi==='S'? false : true }
                                              onChange={this.handleChange} />
              {preg.peme_preg_resp==='R' &&
                      <br />  // Despues de cada respuesta dejo espacio ppara separar visualmente los temas
              }
                      </td>     
                      <td style={{ width: "8%", textAlign: "left", fontSize: 10 }}>
                                  {preg.peme_alta_f}
                      </td>

                      <td style={{ width: "10%" }}>
                        <div style={{ fontSize: 10 }}>
                                  {`#${preg.peme_id}`}
                        </div>
              { preg.peme_id > preg.ult_peme_id &&
                                <Button variant="danger" size="sm" onClick={() => this.handleBorrRegis(preg)}>
                                  <i className="fas fa-trash-alt"></i></Button>
              }
                      </td>
                    </tr>  
                  ) 
                }) 
              }
                      </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={1}>
                      <i style={{ color: this.state.color_fon_msg }} className="fas fa-user-graduate"></i>
                      <b>Responder:</b>
                    </Col>
                    <Col xs={10}>
                      <Form.Group>
                        <FormControl as="textarea"  name="peme_texto"
                                              defaultValue={this.state.peme_texto} 
                                              style={{ backgroundColor: this.state.color_fon_msg }}
                                              rows={5}
                                              placeholder={`Escribe tu respuesta aqui`}
                                              onChange={this.handleChange} />
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* ===================== respuesta de mensaje ========================== */}
                  <br />
                    <Row>
                        <Col xs={3}>
                        </Col>
                        <Col xs={3} style={{textAlign: "right"}}>
                          <Button variant="success" size="sm" onClick={this.handleEditRegis}>
                            Grabar
                          </Button>
                          <Button variant="secondary" size="sm" onClick={this.handleModalExit}>
                            Cancelar
                          </Button>
                        </Col>
                        <Col xs={6}>
                          <Alert key="1" variant="danger" show={respError.length >3 ? true : false} >
                                {respError}
                          </Alert>
                        </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="tab2" title="Ficha persona">
                    
                      <FichaPersona 
                          pers_id = {this.state.pers_id}/>
                    
                  </Tab>
                  <Tab eventKey="tab3" title="Historico de mensajes">
                    
                      <MensajesPersona
                        pers_id = {this.state.pers_id}
                        tiempo = 'H' />
                    
                  </Tab>
              </Tabs>
            </Row>
          </Form>
          </Modal.Body>
        </Modal>


        < Modal show={this.state.showhelp} onHide={() => { this.setState({ showhelp : false}) } } dialogClassName="modal-90">
            <AyudaAdmi clave={'admin'}/>
        </Modal>

        
      </div>
    );
  }
}

export default PanelMensajeriaPage;
